import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Breadcrumbs, Button, Card, Link, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useNavigate } from 'react-router-dom';
import { getMetaAction } from '../../redux/toolkit/config/getmeta';
import { editMetaAction } from '../../redux/toolkit/config/editmeta';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';








const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/mission'>Mission</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/mission/create-mission'>Create Mission</Link>
        </Breadcrumbs >
        <Typography sx={{ color: "#fff" }} variant='h5'>Mission</Typography>
    </Stack >
)

// schema
const qns_ans_schema = yup.object().shape({

}).required();



export default function Config() {
    // state
    const { register, setError, watch, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm({
        resolver: yupResolver(qns_ans_schema), defaultValues: {}
    });

    const mission_type = watch('mission_type')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const createMission = useSelector(state => state.createMission)
    const { status: createMissionStatus, message: createMissionMsg, loading: createMissionLoading } = createMission
    const getCat = useSelector(state => state.getCategory)
    const getQns = useSelector(state => state.getMissionQns)
   
    const getMeta = useSelector(state => state.getMeta)
    const { data } = getMeta

    const editMeta = useSelector(state => state.editMeta)
    const { data: editData, status: editStatus, message: editMessage } = editMeta

    const [configId, setConfigId] = useState()
    const [snack, setSnack] = useState(false)
    const [MaintinanceMode, setMaintinanceMode] = useState()
    const [theme, setTheme] = React.useState(1);







    // fn




    const handleConfigMeta = async (data) => {
        data.config_id = configId
        data.is_maintinance_mode = MaintinanceMode
        data.quizwall_io_theme = theme
        const editMetaData = await dispatch(editMetaAction(data))
        if (editMetaData?.payload?.status == 201) {
            // await dispatch(getMissionAction({ is_deleted: false }))
            setSnack(true)
            reset()
            navigate("/config-meta")
            dispatch(getMetaAction())
        }

        setSnack(true)
        // setState(false)

    }


    const handleClose = function () {
        // setState(false)
        reset()
        navigate('/mission')
    }




    useEffect(() => {
        dispatch(getMetaAction())
    }, [dispatch])

    useEffect(() => {
        let defaultValues = {};
        defaultValues.app_name = data.app_name
        defaultValues.support_email = data.support_email
        defaultValues.support_mobile = data.support_mobile
        defaultValues.is_maintinance_mode = data.is_maintinance_mode
        defaultValues.daily_user_attempt_limit = data.daily_user_attempt_limit
        defaultValues.daily_mission_attempt_limit = data.daily_mission_attempt_limit
        defaultValues.redirect_mission_id = data.redirect_mission_id


        setMaintinanceMode(data.is_maintinance_mode)
        setTheme(data.quizwall_io_theme)
        setConfigId(data?._id)
        reset({ ...defaultValues });
    }, [dispatch, data])


    const handleChange = () => {
        setMaintinanceMode(!MaintinanceMode)
    }


    const handleChange2 = (event) => {
        setTheme(event.target.value);
      };



    return (
        <>

            {/* <DrawerUI /> */}

            {/* <Card sx={{ width: 500, p: 5 }}> */}
            <Card sx={{ p: 5 }}>
                <Typography variant='h5' sx={{ mb: 5 }}>Config Meta</Typography>
                <form onSubmit={handleSubmit(handleConfigMeta)}>

                    <Stack spacing={3}>

                        <Stack direction="row" spacing={5}>
                            <TextField label="App Name" InputLabelProps={{ shrink: true }} defaultValue={data?.app_name} error={errors?.app_name?.message} helperText={errors?.app_name?.message}  {...register('app_name')} />
                            <TextField label="Support Mobile" InputLabelProps={{ shrink: true }} defaultValue={data?.support_mobile} error={errors?.support_mobile?.message} helperText={errors?.support_mobile?.message}  {...register('support_mobile')} />
                            <TextField label="Support Email" InputLabelProps={{ shrink: true }} defaultValue={data?.support_email} error={errors?.support_email?.message} helperText={errors?.support_email?.message}  {...register('support_email')} />
                            <TextField label="User Mission Limit" InputLabelProps={{ shrink: true }} defaultValue={data?.daily_user_attempt_limit} error={errors?.daily_user_attempt_limit?.message} helperText={errors?.daily_user_attempt_limit?.message}  {...register('daily_user_attempt_limit')} />

                        </Stack>


                        <Stack direction="row" spacing={5}>
                            <TextField label="User Attempt Limit" InputLabelProps={{ shrink: true }} defaultValue={data?.daily_mission_attempt_limit} error={errors?.daily_mission_attempt_limit?.message} helperText={errors?.daily_mission_attempt_limit?.message}  {...register('daily_mission_attempt_limit')} />
                            <TextField label="Redirect Mission Id" InputLabelProps={{ shrink: true }} defaultValue={data?.redirect_mission_id} error={errors?.redirect_mission_id?.message} helperText={errors?.redirect_mission_id?.message}  {...register('redirect_mission_id')} />

                        </Stack>



                        <Stack direction="row" spacing={10} sx={{ margin: "20px 0px 0px 20px" }}>
                            <FormGroup>
                                <FormControlLabel control={<Switch checked={MaintinanceMode} onChange={handleChange} />} label="Maintinance Mode" />

                            </FormGroup>
                        </Stack>


                        <Stack direction="row" spacing={10} sx={{ margin: "20px 0px 0px 20px" }}>
                            {/* <Typography variant='h5' sx={{ mb: 5 }}>Quizwall.io Theme</Typography> */}
                            <Box sx={{ minWidth: 120 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Theme</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={theme}
                                        label="Theme"
                                        onChange={handleChange2}
                                    >
                                        <MenuItem  value={1}>Theme 1</MenuItem>
                                        <MenuItem value={2}>Theme 2</MenuItem>
                                        <MenuItem value={3}>Theme 3</MenuItem>
                                        <MenuItem value={4}>Theme 4</MenuItem>
                                        <MenuItem value={5}>Theme 5</MenuItem>
                                        <MenuItem value={6}>Theme 6</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>







                        <Stack direction='row' spacing={2}>
                            <Button variant='contained' type='submit'>{'Update Config Meta'}</Button>
                            <Button variant='contained' color='error' onClick={handleClose}>Back</Button>
                        </Stack>
                    </Stack>
                </form>
            </Card>


            <SnackBarUI state={snack} setState={setSnack} status={editStatus} message={editMessage} />

        </>
    )
}

// CreateMission.propTypes = {
//     state: PropTypes.any,
// };