import { Box, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached';
import { quitAction } from '../../redux/toolkit/dashbaord/quit';
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';

export default function AttemptsQuit() {
    // state
    const dispatch = useDispatch()
    const totalUsersSelector = useSelector(state => state.totalQuit)
    const { data, loading } = totalUsersSelector

    // fn
    const handleRefeash = () => {
        dispatch(quitAction())
    }

    // use effect
    useEffect(() => {
        dispatch(quitAction())
    }, [dispatch])

    return (
        <Card sx={{ p: 2, color:'#0C6A96' }} >
            <Stack direction='row' justifyContent='space-between' >
                <LogoutSharpIcon/>
                <Typography sx={{ color: '#5E6C8A',fontFamily: 'Montserrat,sans-serif',fontStyle:'normal' }} variant='subtitle2'>Attempts Quits</Typography>
                <IconButton sx={{ color: '#5E6C8A' }} onClick={handleRefeash}><CachedIcon /></IconButton>
            </Stack>

            {
                loading
                    ? <CircularProgress />
                    : <Box>
                        <Typography variant='h3' color='primary' sx={{ fontWeight: 600, color: '#071D45' }}>{data?.no_of_mission_quit?data?.no_of_mission_quit:"---"}</Typography>
                    </Box>
            }
        </Card>
    )
}
