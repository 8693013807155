import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RestoreIcon from '@mui/icons-material/Restore'
import SearchIcon from '@mui/icons-material/Search'
import { Autocomplete, Box, Breadcrumbs, Button, Card, Chip, Grid, IconButton, Link, Menu, MenuItem, Paper, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import { Stack } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { createSearchParams, Link as LinkRRD, useNavigate } from 'react-router-dom'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
// import DrawerUI from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { getCategoryAction } from '../../redux/toolkit/category/getCategory'
import { getAllSubCategoryAction } from '../../redux/toolkit/sub-category/getSubAllCategory';
import { getMissionAction } from '../../redux/toolkit/mission/getMission'
import { useStyles } from '../../theme/theme'
import DeleteMission from './DeleteMission'
import LiveMission from './LiveMission'
import RestoreMission from './RestoreMission'
import UnliveMission from './UnliveMission'
import RedirectMission from './RedirectMission';

let cat_id = ''

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/mission'>Mission</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Mission</Typography>
    </Stack>
)


export default function Mission() {
    // state--->
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const mission = useSelector(state => state.getMission)
    const { data: missionData, loading: missionLoading } = mission
    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData } = permission

    let can_delete = JSON.parse(localStorage.getItem("ss_active_usr"))?.settings?.can_delete
    let super_admin = permissionData?.role

    const missionT = useSelector(state => state.missionTime)
    const { status: missionTimeStatus, message: missionTimeMsg } = missionT
    const getCat = useSelector(state => state.getAllSubCategory)
    const { data: getCatData } = getCat


    const [snack, setSnack] = useState(false)
    const [delMission, setDelMission] = useState(false)
    const [liveMission, setLiveMission] = useState(false)
    const [unLiveMission, setUnLiveMission] = useState(false)
    const [catMenu, setCatMenu] = useState(false)
    const [anchorCatMenu, setAnchorCatMenu] = useState()
    const [restoreCat, setRestoreCat] = useState(false)
    const [ativeFilter, setActiveFilter] = useState(false)
    const [pageSize, setPageSize] = useState(25);
    const [mission_id, setmission_id] = useState('')
    const classes = useStyles();
    const [date, setDate] = useState({
        start: null,
        end: null,
    })

    const [redirectpopup,setRedirectpopup]=useState(false)

console.log("missionData-->",missionData);

    const qnsDataGridColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            minWidth: 300,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <LinkRRD to={`/mission-detail?${params?.row?._id}`}>
                        <Typography color='primary'>{params?.row?._id}</Typography>
                    </LinkRRD>
                )
            },
        },
        {
            field: 'title',
            headerName: 'Title',
            flex: 1,
            minWidth: 170,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.title}</Typography>
                )
            },
        },
        {
            field: 'sub_cat_id',
            headerName: 'Sub Category Name',
            flex: 1,
            minWidth: 170,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.sub_cat_id?.sub_cat_name}</Typography>
                )
            },
        },
        {
            field: 'criteria_type',
            headerName: 'Criteria Type',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            
        },
        {
            field: 'time_criteria',
            headerName: 'Time Required',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            
        },
        {
            field: 'n_attempts',
            headerName: 'Users Attempt',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'link_qns',
            headerName: 'Link Question',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.link_qns?.length}</Typography>
                )
            },
        },
        {
            field: 'reward',
            headerName: 'Reward Amount',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.reward}</Typography>
                )
            },
        },
        // {
        //     field: 'reward_type',
        //     headerName: 'Reward Type',
        //     flex: 1,
        //     minWidth: 150,
        //     headerClassName: 'super-app-theme--header',
        //     renderCell: (params) => {
        //         return (
        //             <Typography>{params?.row?.reward_type}</Typography>
        //         )
        //     },
        // },
        // {
        //     field: 'reward.secondary_reward',
        //     headerName: 'Secondary Reward Amount',
        //     flex: 1,
        //     minWidth: 200,
        //     headerClassName: 'super-app-theme--header',
        //     renderCell: (params) => {
        //         return (
        //             <Typography>{params?.row?.secondary_reward}</Typography>
        //         )
        //     },

        // },
        // {
        //     field: 'card_bg_color',
        //     headerName: 'Card Background Color',
        //     renderCell: (params) => {
        //         return (
        //             <Stack direction={'row'} spacing={1} alignItems='center'>
        //                 <Box sx={{ width: 40, height: 20, bgcolor: params?.row?.card_bg_color?.[0] }}></Box>
        //                 <Box sx={{ width: 40, height: 20, bgcolor: params?.row?.card_bg_color?.[1] }}></Box>
        //             </Stack>
        //         )
        //     },
        //     flex: 1,
        //     minWidth: 200,
        //     headerClassName: 'super-app-theme--header',
        //     hide: ativeFilter
        // },
        {
            field: 'status',
            headerName: 'status',
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.status == 'live'
                            ? <Chip color={'primary'} label='Live' />
                            : <Chip color={'error'} label='Paused' />
                        }


                    </>
                )
            },
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            hide: ativeFilter
        },
        {
            field: 'live_date',
            headerName: 'Live Date',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.live_date?.split("T")?.[0]
                            ? params?.row?.live_date?.split("T")?.[0]
                            : null
                        }
                    </>
                )
            },
        },
        {
            field: 'live_time',
            headerName: 'Live Time',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.live_date?.split("T")?.[1]?.split(".")?.[0]
                            ? params?.row?.live_date?.split("T")?.[1]?.split(".")?.[0]
                            : null
                        }
                    </>
                )
            },
        },
        {
            field: 'end_date',
            headerName: 'End Date',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <>
                        {
                            params?.row?.end_date?.split("T")?.[0]
                                ? params?.row?.end_date?.split("T")?.[0]
                                : null
                        }
                    </>
                )
            },
        },
        {
            field: 'end_time',
            headerName: 'End Time',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <>
                        {
                            params?.row?.end_date?.split("T")?.[1]?.split(".")?.[0]
                                ? params?.row?.end_date?.split("T")?.[1]?.split(".")?.[0]
                                : null
                        }
                    </>
                )
            },
        },
        {
            field: 'edit', headerName: 'Edit Mission',
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            navigate({
                                pathname: `/mission/edit-mission`,
                                search: createSearchParams({
                                    mission_id: params?.row?._id
                                }).toString()
                            })
                        }
                        } >
                        <EditIcon color='primary' />
                    </IconButton >
                )
            },
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            hide: ativeFilter
        },
        {
            field: 'delete', headerName: 'Delete Mission', renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            setDelMission(true)
                            setmission_id(params?.row?._id)
                        }}>
                        <DeleteIcon color='error' /></IconButton>
                )
            },
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            // hide: ativeFilter || (!can_delete && super_admin != 'super_admin')

        },
        {
            field: 'revert',
            headerName: 'Revert Mission',
            renderCell: () => {
                return (
                    <IconButton
                        onClick={() => {
                            setRestoreCat(true);
                        }}><RestoreIcon color='success' /></IconButton>
                )
            },
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            hide: !ativeFilter

        },
    ]


    // fn
    const handleFilter = () => {
        dispatch(getMissionAction({ sub_cat_id: cat_id, is_deleted: ativeFilter }))
    }
    const handleclearFilter = () => {
        setActiveFilter(false)
        dispatch(getMissionAction({ is_deleted: false }))
    }
    const handleLiveBulkMission = () => {
        navigate("/mission/live-bulk-mission")
    }


    const handleFilterData = () => {
        dispatch(getMissionAction({ is_deleted: false, s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
    }




    // use effect
    useEffect(() => {
        dispatch(getMissionAction({ is_deleted: false }))
        dispatch(getAllSubCategoryAction({ is_deleted: false }))
    }, [dispatch])



    return (
        <>
            <Helmet><title>Quick mission | Mission</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            {/* <DrawerUI /> */}
            <Body>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Button variant="outlined" onClick={() => setRedirectpopup(true)}>
                        Redirect Mission
                    </Button>
                    <Button variant="contained" color="btn" startIcon={<AddIcon />} onClick={() => {
                                    // setCreateMission(true);
                                    dispatch(getCategoryAction());
                                    navigate("create-mission")
                                }}>
                        create Mission
                    </Button>
                </Stack>

                <Card sx={{ my: 2 }}>
                    <Grid container sx={{ ml: 2, py: 2 }}>
                        <Grid item xs={3}>
                            <Autocomplete fullWidth disablePortal id={getCatData?._id} options={getCatData} getOptionLabel={getCatData => getCatData?.sub_cat_name}
                                onChange={(event, value) => {
                                    cat_id = value?._id
                                }}
                                renderInput={(params) => <TextField {...params} label="Sub Category Name" />} />

                        </Grid>
                        <Grid item xs={6}>
                            <Stack direction='row' spacing={2}>
                                <IconButton type='submit' onClick={handleFilter} color='primary'><SearchIcon /></IconButton>
                                <IconButton color='#071D45' onClick={handleclearFilter}><ClearAllIcon /></IconButton>
                                {(can_delete || super_admin == 'super_admin') && <IconButton
                                    onClick={(event) => {
                                        setCatMenu(true);
                                        setAnchorCatMenu(event.currentTarget);
                                    }}>
                                    <FilterAltIcon color='#071D45' /></IconButton>}

                                <Menu
                                    open={catMenu}
                                    onClose={() => setCatMenu(false)}
                                    anchorEl={anchorCatMenu}
                                >
                                    <MenuItem selected={ativeFilter}
                                        onClick={() => {
                                            setCatMenu(false)
                                            dispatch(getMissionAction({ is_deleted: true }))
                                            setActiveFilter(true)
                                        }} >Deleted</MenuItem>
                                    <MenuItem selected={!ativeFilter}
                                        onClick={() => {
                                            setCatMenu(false)
                                            dispatch(getMissionAction({ is_deleted: false }))
                                            setActiveFilter(false)
                                        }}>Non Deleted</MenuItem>
                                </Menu>
                            </Stack>
                        </Grid>
                    </Grid>

                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ p: 2 }}>
                            <Typography color='#071D45'>Filter by Date</Typography>
                            <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                                <DatePicker
                                    label="Start Date"
                                    value={date?.start}
                                    onChange={(value) => setDate({ ...date, start: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    maxDate={date?.end}

                                />
                                <DatePicker
                                    label="End Date"
                                    value={date?.end}
                                    onChange={(value) => setDate({ ...date, end: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={date?.start}
                                />
                                <Box>
                                    <IconButton onClick={handleFilterData} color='#071D45'><FilterAltIcon /></IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </LocalizationProvider>


                    <Box sx={{ p: 2 }}>
                        <Button onClick={handleLiveBulkMission} color='warning' variant='contained' endIcon={<ArrowRightAltIcon />}>Live mission</Button>
                    </Box>



                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#071D45',
                            color: '#fff',
                        },

                    }} elevation={0}>
                        <DataGrid
                            className={classes.root}
                            getRowHeight={() => 'auto'}
                            sx={{ border: 0 }}
                            autoHeight
                            rows={missionData}
                            columns={qnsDataGridColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={missionLoading ? <LoadingUI /> : false}
                            onCellClick={(params) => {
                                setmission_id(params?.row?._id)
                            }}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,
                            }}

                        />
                    </Paper>
                </Card>


                <DeleteMission mission_id={mission_id} state={delMission} setState={setDelMission} />
                <LiveMission mission_id={mission_id} state={liveMission} setState={setLiveMission} />
                <UnliveMission mission_id={mission_id} state={unLiveMission} setState={setUnLiveMission} />
                <RestoreMission mission_id={mission_id} state={restoreCat} setState={setRestoreCat} />
                {redirectpopup && <RedirectMission state={redirectpopup} setState={setRedirectpopup} />}


                <SnackBarUI state={snack} setState={setSnack} status={missionTimeStatus} message={missionTimeMsg} />

            </Body >
        </>
    )
}
