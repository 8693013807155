import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, CircularProgress, Modal, TextField, Typography,Avatar,Badge} from '@mui/material';
import { Box, Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { addManegerDetailsAction } from '../../redux/toolkit/publisher/addManegerDetails';
import { getPublisherAction } from '../../redux/toolkit/publisher/getPublisher';
import { uplodImageAction } from '../../redux/toolkit/image/uplodImage';



// schema
const catSchema = yup.object({
    m_name: yup.string().required('Name is required.'),
    m_email: yup.string().email('Invalid email address').required('Email is required.'),
    m_mobile: yup.string().matches(/^[0-9]{10}$/, 'Invalid mobile number').required('Mobile is required.'),

}).required();

export default function ManagerDetails({ publisherDetails, publisher_id, state, setState }) {
    // state
    const { register,setValue,handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(catSchema) });
    const dispatch = useDispatch()

    const addManegerDetails = useSelector(state => state.addManager)
    const { status, message, loading } = addManegerDetails
    const [snack, setSnack] = useState(false)
    const [image,setImage]=useState()



    const handleImage = async (e) => {



        const formData = new FormData();

        const file = e.target.files[0];

        formData.append('file', file);
        
        let FileSize = Number(e.target.files[0].size / 1000);
 
        var _URL = window.URL || window.webkitURL;
         let img = new Image();
         var width, height;
 
 
         img.onload =async function () {
             // console.log(img, file);
             width = this.width;
             height = this.height;
             if (FileSize > 5000) {
                 return alert(`Image should be to under 50kb reupload image, Your file size is ${FileSize}`)
             }
             if (height <= 1000 && width <= 1000 ) {
                 // console.log(formData);
                 let uploadedImg = await dispatch(uplodImageAction(formData))
                 const imgStr=uploadedImg?.payload?.data?.url;
                 setImage(uploadedImg?.payload?.data?.url)
                 setValue('image',imgStr)
                 alert(`Image is Successfully Uploaded, Your file size is ${FileSize}`);
             } else {
                 alert(
                     `Image height must be 300 & width must be 300, Your height=${height} & width=${width}`
                 );
             }
         };
         img.onerror = function () {
             alert("not a valid file: " + file.type);
         };
         img.src = _URL.createObjectURL(file);
 

    }
    const handleManegerDetails = async (data) => {
        data.m_image=image
        setSnack(true)
        const addManegerDetails = await dispatch(addManegerDetailsAction({ publisher_id, ...data }))
        if (addManegerDetails?.payload?.status == 200) {
            dispatch(getPublisherAction())
            setTimeout(() => {
                
                setState(false)
            }, 1000);
            reset()
            
        }

        // setSnack(true)
    }


    const handleClose = function () {
        setState(false)
    }

    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 400, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Add Manager</Typography>
                        <form onSubmit={handleSubmit(handleManegerDetails)}>
                            <Stack spacing={3}>
                                <TextField type='file' accept="image/*" variant="outlined" id='image-upload' placeholder='image' onChange={handleImage} error={errors?.image?.message} helperText={errors?.image?.message} />
                                <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
                                 <Avatar src={image||publisherDetails?.m_image} sx={{ width: 60, height: 60 }} />
                                </Badge>
                                <TextField type='text' defaultValue={publisherDetails?.m_name} label="Manager Name" variant="outlined" placeholder='Title' {...register('m_name')} error={errors?.m_name?.message} helperText={errors?.m_name?.message} />
                                <TextField type='text' defaultValue={publisherDetails?.m_email} label="Manager Email" variant="outlined" placeholder='Title' {...register('m_email')} error={errors?.m_email?.message} helperText={errors?.m_email?.message} />
                                <TextField type='text' defaultValue={publisherDetails?.m_mobile} label="Manager Mobile Number" variant="outlined" placeholder='Title' {...register('m_mobile')} error={errors?.m_mobile?.message} helperText={errors?.m_mobile?.message} />

                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Add Manager'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>
            {console.log("snaack-->",snack)}
            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}



ManagerDetails.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any,
    publisher_id: PropTypes.any,
    publisherDetails: PropTypes.any,
};