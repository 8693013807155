import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [],total:0,error: "", message: "" }


export const getPostbackAction = createAsyncThunk(
    'get-postback',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/admins/api/postback/logs/${data.page}/${data.limit}/${data.startDate}/${data.endDate}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getPostbackSlice = createSlice({
    name: 'get-postback',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getPostbackAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(getPostbackAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
                state.total = action?.payload?.totalData
            })
            builder.addCase(getPostbackAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
                state.total = action?.payload?.totalData

            })
        },

})



export const getPostbackReducer = getPostbackSlice.reducer