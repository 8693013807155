import CachedIcon from '@mui/icons-material/Cached';
import { Box, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import React,{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toPublisherAction } from '../../redux/toolkit/dashbaord/toPublisher';
import PublishIcon from '@mui/icons-material/Publish';

export default function TotalPublisher() {
    // state
    const dispatch = useDispatch()
    const toPublisherSelector = useSelector(state => state.toPublisher)
    const { data, loading } = toPublisherSelector

    console.log("data-->",data);
    let publisher={}
    publisher.data=data

    // fn
    const handleRefeash = () => {
        dispatch(toPublisherAction())
    }

    useEffect(() => {
        dispatch(toPublisherAction())
    }, [dispatch])

    return (
        <Card sx={{ p: 2, color:'#0C6A96' }} >
            <Stack direction='row' justifyContent='space-between' >
                <PublishIcon color='#0C6A96'/>
                <Typography sx={{ color: '#5E6C8A',fontFamily:'Montserrat'}} variant='subtitle2'>Total Publisher</Typography>
                <IconButton sx={{ color: '#5E6C8A' }} onClick={handleRefeash}><CachedIcon /></IconButton>
            </Stack>

            {
                loading
                    ? <CircularProgress />
                    : <Box>
                        <Typography variant='h3' color='primary' sx={{ fontWeight: 600, color: '#071D45' }}>{publisher?.data?publisher?.data:"---"}</Typography>
                    </Box>
            }
        </Card>
    )
}
