// import GroupsIcon from '@mui/icons-material/Groups';
import participants from '../../assets/icon/participants.png'
import { Box, Card, CardActionArea, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingUI from '../../comman/LoadingUI';
import { toPublisherAction } from '../../redux/toolkit/dashbaord/toPublisher';

export default function NoOfPublisher() {
    // state
    const dispatch = useDispatch()
    const toPublisherSelector = useSelector(state => state.toPublisher)
    const { data: toPublisherData, loading: toPublisherLoading } = toPublisherSelector



    // useEffect
    useEffect(() => {
        dispatch(toPublisherAction())
    }, [dispatch])
    return (

        <Card>
            <CardActionArea sx={{ p: 2,bgcolor:"#071D45" }}>
                <Stack spacing={2} alignItems={'center'}>
                    <Box sx={{ width: 70, height: 70, borderRadius: 1, color: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {/* <GroupsIcon sx={{ fontSize: 50 }} /> */}
                        <img src={participants} style={{minWidth:"10px"}}/>

                    </Box>
                    {toPublisherLoading ? <LoadingUI justifyContent='flex-start' /> : null}
                    {toPublisherData ? <Typography color='#fff' variant='h3'>{toPublisherData}</Typography> :
                        <Typography color='primary' variant='h3'>--</Typography>
                    }
                </Stack>
            </CardActionArea>
        </Card>
    )
}

