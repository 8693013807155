import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
// import EditIcon from '@mui/icons-material/Edit';
import { Button, Card, CircularProgress, Modal, TextField, Typography,Avatar,Badge } from '@mui/material';
import { Box, Stack} from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { createCategoryAction } from '../../redux/toolkit/category/createCategory';
import { getCategoryAction } from '../../redux/toolkit/category/getCategory';
import { uplodImageAction } from '../../redux/toolkit/image/uplodImage';



// schema
const catSchema = yup.object().shape({
    cat_name: yup.string().required('Category name is required.'),
    sequence: yup.number().positive().nullable().required('Sequence number is required.').typeError('sequence must be a number'),
    image: yup.string().required('Category image is required.')
}).required();

export default function CreateCategory({ state, setState }) {
    // state
    const { register,setValue,control, handleSubmit, formState: { errors }, reset,setError } = useForm({ resolver: yupResolver(catSchema) });

    const dispatch = useDispatch()
    const createCat = useSelector(state => state.createCategory)
    const { status, message, loading } = createCat

    const [snack, setSnack] = useState(false)
    const [image,setImage]=useState('')
    const imageUpload={...register('imageUpload')}

    // fn

    const handleImage = async (e) => {

        // const formData = new FormData();

        // formData.set("file", e.target.files[0]);

        // let uploadedImg = await dispatch(uplodImageAction(formData))
        // const imgStr=uploadedImg?.payload?.data?.url;
        // setImage(uploadedImg?.payload?.data?.url)
        // setValue('image',imgStr)
        // console.log("image-->",image);



        const formData = new FormData();

        const file = e.target.files[0];

        formData.append('file', file);
        
        let FileSize = Number(e.target.files[0].size / 1000);
 
        var _URL = window.URL || window.webkitURL;
         let img = new Image();
         var width, height;
 
 
         img.onload =async function () {
             // console.log(img, file);
             width = this.width;
             height = this.height;
             if (FileSize > 5000) {
                 return alert(`Image should be to under 50kb reupload image, Your file size is ${FileSize}`)
             }
             if (height <= 300 && width <= 300 ) {
                 // console.log(formData);
                 let uploadedImg = await dispatch(uplodImageAction(formData))
                 const imgStr=uploadedImg?.payload?.data?.url;
                 setImage(uploadedImg?.payload?.data?.url)
                 setValue('image',imgStr)
                 alert(`Image is Successfully Uploaded, Your file size is ${FileSize}`);
             } else {
                 alert(
                     `Image height must be 300 & width must be 300, Your height=${height} & width=${width}`
                 );
             }
         };
         img.onerror = function () {
             alert("not a valid file: " + file.type);
         };
         img.src = _URL.createObjectURL(file);
 

    }

    const handleCategory = async (data) => {
        data.image=image
        console.log("data.image-->",data.image);
        if(data.image===''||data.image===null||data.image===undefined){
            setError('imageUpload',{type:"custom"})
        }
        const createCategoryData = await dispatch(createCategoryAction(data))
        if (createCategoryData?.payload?.status == 201) {
            await dispatch(getCategoryAction({ is_deleted: '' }))
        }
        setSnack(true)
        setState(false)
        reset()
        setImage(null)
    }

    const handleClose = function () {
        setState(false)
    }


    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 400, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Create Category</Typography>
                        <form onSubmit={handleSubmit(handleCategory)}>
                            <Stack spacing={3}>
                                <TextField type='text' label="Category" variant="outlined" placeholder='Category Name' {...register('cat_name')} error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} />
                                <TextField type='number' InputProps={{ inputProps: { min: 1 } }} label="Sequence" variant="outlined" placeholder='Sequence Number' {...register('sequence')} error={errors?.sequence?.message} helperText={errors?.sequence?.message} />
                                <TextField type='file' accept="image/*" variant="outlined" id='image-upload' placeholder='image' onChange={handleImage} error={errors?.image?.message} helperText={errors?.image?.message} />
                                <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
                                 <Avatar src={image} sx={{ width: 60, height: 60 }} />
                                </Badge>
                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Create Category'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>
            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            <DevTool control={control} />
        </>
    )
}



CreateCategory.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any
};