import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const blockAndUnBlockUserAction = createAsyncThunk(
    'block-unblock',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/admins/api/block/unblock/user`, { ...data })
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const blockAndUnBlockUserSlice = createSlice({
    name: 'block-unblock',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(blockAndUnBlockUserAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(blockAndUnBlockUserAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(blockAndUnBlockUserAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const blockAndUnBlockUserReducer = blockAndUnBlockUserSlice.reducer