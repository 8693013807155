import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SnackBarUI from '../../comman/SnackBarUI';
import { bankVerifyAction } from '../../redux/toolkit/bank/Verify';
import { bankDataAction } from '../../redux/toolkit/bank/List';
export default function VerifyBank({ bank_id, state, setState }) {
    // state
    const dispatch = useDispatch()
    const delCat = useSelector(state => state.bankVerify)
    const { status, message } = delCat

    const [snack, setSnack] = useState(false)

    // fn
    const handleClose = () => {
        setState(false)
    }

    const delCatHandle = async () => {
        const publisherStatus = await dispatch(bankVerifyAction({
            bank_id: bank_id
        }))
        window.location.reload();
        setSnack(true)
        setState(false)
    }

    return (
        <>
            <Dialog open={state} onClose={handleClose}>
                <DialogTitle>Verify publisher !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to verify this publisher?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={delCatHandle}> Agree</Button>
                    <Button onClick={handleClose}>Disagree</Button>
                </DialogActions>
            </Dialog>


            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>

    )
}


// VerifyBank.propTypes = {
//     bank_id: PropTypes.string,
//     state: PropTypes.any,
//     setState: PropTypes.any
// };