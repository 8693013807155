// 1==> import external libraries
import React, { useEffect, useState } from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Button, Card, Link, Paper, Typography,TextField } from '@mui/material'
import { Stack } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

//2==> import internal component and modules

import { getPostbackAction } from '../../redux/toolkit/postback/postback';
import { dateFormatStartFun} from '../../helper/dateConverter'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
import Body from '../../layout/Body'

//3==> import styles and css
import { useStyles } from '../../theme/theme'



const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/post-back'>Postback</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Postback Logger</Typography>
    </Stack>
)



export default function Admin() {
    // state
    const dispatch = useDispatch()
    const getPostback = useSelector(state => state.getPostback)
    const { data, loading,total } = getPostback
    const blockAndUnBlockAdminSelector = useSelector(state => state.blockAndUnBlockAdmin)
    const { status, message } = blockAndUnBlockAdminSelector
    const [pageState, setPageState] = useState({page:0,pageSize:25});
    const [snack, setSnack] = useState(false)
    const classes = useStyles();
    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
      })


      


    const adminColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                const { page, pageSize } = pageState;
                const serialNumber = (page * pageSize) +
                    params?.api?.getRowIndex(params.row._id) + 1
                return serialNumber;
            }       
         },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth:220,
        },
        {
            field: 'publisher_id',
            headerName: 'Publisher',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth:220,

        },
        {
            field: 'app_name',
            headerName: 'App',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth:180,
        },
        {
            field: 'user',
            headerName: 'User ID',
            flex: 1,
            minWidth:250,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{JSON.parse(params?.row?.body).user_id}</Typography>
                )
            },
        },
        {
            field: 'amount',
            headerName: 'Reward Amount',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth:120,

            renderCell: (params) => {
                return (
                    <Typography>{JSON.parse(params?.row?.body).amount}</Typography>
                )
            },
        },

        {
            field: 'created_at',
            headerName: 'Created',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth:230,

        },2   
        
    ]



    // useEffect
    useEffect(() => {
        dispatch(getPostbackAction({page:pageState.page,limit:pageState.pageSize,startDate:"undefined",endDate:"undefined"}))
    }, [dispatch,pageState.page,pageState.pageSize])

    const dateWiseUser=()=>{
        console.log("date",dateFormatStartFun(date?.startDate))
        dispatch(getPostbackAction({page:pageState.page,limit:pageState.pageSize,startDate:dateFormatStartFun(date?.startDate),endDate:dateFormatStartFun(date?.endDate)}))
    }


    return (
        <>
            <Helmet>
                <title>Quizwall | Admin</title>
            </Helmet>


            <Appbar breadcrumb={breadcrumb} />
            {/* <DrawerUI /> */}
            <Body>
                
            <Stack direction='row' spacing={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Min Data/Time"
                      value={date?.startDate}
                      maxDate={moment()}
                      onChange={(newValue) => {

                        setDate({ ...date, startDate: newValue });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Max Data/Time"
                      value={date?.endDate}
                      minDate={date?.startDate}
                      maxDate={moment()}
                      onChange={(newValue) => {
                        setDate({ ...date, endDate: newValue });
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  {date.startDate && date.endDate && <Button variant='contained' onClick={dateWiseUser}>find data</Button>}
                </Stack>

                < Card sx={{ my: 2 }}>
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#071D45',
                            color: '#fff',
                        },
                    }} elevation={0}>
                        <DataGrid
                            className={classes.root}
                            getRowHeight={() => 'auto'}
                            sx={{ border: 0 }}
                            rows={data}
                            autoHeight
                            columns={adminColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            // pageSize={pageSize}
                            // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={loading ? <LoadingUI /> : false}
                            // onCellClick={(params) => {
                            //     setadmin_id(params?.row?._id)
                            // }}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,
                            }}
                            rowCount={total}
                    paginationModel={setPageState}
                    page={pageState.page}
                    pageSize={pageState.pageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                        setPageState(old => ({ ...old, page: newPage }))
                    }}
                    onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}

                        />
                    </Paper>
                </Card >



                {/* modals */}
                


            </Body >

            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}
