import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [],total:0,error: "", message: "" }


export const getAdminSupportLogsAction = createAsyncThunk(
    'get-admin-support-logs',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/admins/api/get-admin-support-logs`,{page:data.page,limit:data.limit})
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getAdminSupportLogsSlice = createSlice({
    name: 'get-admin-support-logs',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getAdminSupportLogsAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(getAdminSupportLogsAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
                state.total = action?.payload?.totalData
            })
            builder.addCase(getAdminSupportLogsAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
                state.total = action?.payload?.totalData

            })
        },

})



export const getAdminSupportLogsReducer = getAdminSupportLogsSlice.reducer