

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { loading: false, data: [], error: "", message: "" }


export const bankDetailAction = createAsyncThunk(
    'bank-detail',
    async (data = null, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/admins/api/bank/details/${data}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const bankDetailSlice = createSlice({
    name: 'bank-detail',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(bankDetailAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(bankDetailAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.message = action?.payload?.message
            })
            builder.addCase(bankDetailAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.message = action?.payload?.message
            })
        },

})



export const bankDetailReducer = bankDetailSlice.reducer    






// router.get("/bank/details/:bank_id", new Banks().bankDetails)
// router.put("/bank/verify", new Banks().bankVerify)