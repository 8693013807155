import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"


const initialState = { status: null, loading: false, data: [], error: "", message: '' }


export const registerUserAction = createAsyncThunk(
    'register-admin',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/admins/api/create-admin`, { ...data })
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const registerUserSlice = createSlice({
    name: 'register-admin',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(registerUserAction.pending, (state) => {

                state.loading = true
            })
            builder.addCase(registerUserAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(registerUserAction.rejected, (state, action) => {
                state.loading = false
                state.status = action?.payload?.status
                state.message = action?.payload?.message
                state.error = action?.payload?.message
            })
        },

})



export const registerUserReducer = registerUserSlice.reducer