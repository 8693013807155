import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }



export const getAllQuestionAnswerAction = createAsyncThunk(
    'get-all-question-answer',
    async (data = null, { rejectWithValue }) => {
        try {
            console.log("data--",data)
            const res = await axios.get(`/admins/api/get-all-question-answer?is_deleted=${data?.is_deleted}&answer_type=${data?.answer_type}&cat_id=${data?.sub_cat_id}&difficulty=${data?.difficulty}&s_date=${data?.s_date}&e_date=${data?.e_date}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getAllQuestionAnswerSlice = createSlice({
    name: 'get-all-question-answer',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getAllQuestionAnswerAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(getAllQuestionAnswerAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(getAllQuestionAnswerAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },
})



export const getAllQuestionAnswerReducer = getAllQuestionAnswerSlice.reducer