

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { loading: false, data: [], error: "", message: "" }


export const bankDataAction = createAsyncThunk(
    'bank-data',
    async (data = null, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/admins/api/banks/lists/${data.page}/${data.limit}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const bankDataSlice = createSlice({
    name: 'bank-data',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(bankDataAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(bankDataAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.message = action?.payload?.message
            })
            builder.addCase(bankDataAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.message = action?.payload?.message
            })
        },

})



export const bankDataReducer = bankDataSlice.reducer    






// router.get("/banks/lists/:page?/:limit?", new Banks().bankLists)
// router.get("/bank/details/:bank_id", new Banks().bankDetails)
// router.put("/bank/verify", new Banks().bankVerify)