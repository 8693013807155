import { yupResolver } from '@hookform/resolvers/yup';
import {  Breadcrumbs, Button, Card, CircularProgress, FormControlLabel, Link, TextField, Typography, Avatar, Badge,Paper } from '@mui/material';
import { Stack } from '@mui/system';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import SnackBarUI from '../../../comman/SnackBarUI';
import { getMissionAction } from '../../../redux/toolkit/mission/getMission';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingUI from '../../../comman/LoadingUI';
import NoDataFound from '../../../comman/NoDataFound';
import Appbar from '../../../components/Appbar';
import Body from '../../../layout/Body';
import { getCategoryAction } from '../../../redux/toolkit/category/getCategory';
import { useStyles } from '../../../theme/theme';
import { uplodImageAction } from '../../../redux/toolkit/image/uplodImage';

import { editMissionAction } from '../../../redux/toolkit/mission/editMission';
import { getSingleMissionAction } from '../../../redux/toolkit/mission/getSignleMission';
import { getMissionQnsAction } from '../../../redux/toolkit/mission/getMissionQns';

import JoditEditor from 'jodit-react'


let cat_id = ''
let sub_cat_id = ''

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/mission'>Mission</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/mission/edit-mission'>edit Mission</Link>
        </Breadcrumbs >
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Mission</Typography>
    </Stack >
)

const qns_ans_schema = yup.object().shape({
    // cat_name: yup.string().required('Category is required.'),
    // sub_cat_name: yup.string().required('Sub category is required.'),
    // mission_type: yup.string().nullable().required('mission type is required'),
    // noofquestion: yup.number().positive().nullable().required('No of Question is Required'),
    // time: yup.number().nullable().positive().required('Time is Required'),
    icon: yup.string().required('Icon is Required'),
    rewards: yup.number().positive().required('Time is Required'),
    title: yup.string().required('title is Required'),
    description: yup.string().required('description is Required'),
}).required();

export default function EditMission() {

    // state


    // const mission_type = watch('mission_type')
    const [pageSize, setPageSize] = useState(25);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [linkQnsList, setLinkQnsList] = useState([])
    const createMission = useSelector(state => state.createMission)
    const { status: createMissionStatus, message: createMissionMsg, loading: createMissionLoading } = createMission
    const getCat = useSelector(state => state.getCategory)
    const getSingleCat = useSelector(state => state.getSingleCategory)
    const getQns = useSelector(state => state.getMissionQns)
    const { data: qnsData, loading: qnsLoading } = getQns
    const { data: getCatData } = getCat
    const { data: getSingleCatData } = getSingleCat
    const getSubCat = useSelector(state => state.getSubCategory)
    const { data: getSubCatData } = getSubCat
    const [snack, setSnack] = useState(false)
    const [desc, setDesc] = useState()
    const [img, setImg] = useState()
    const [showTop,setShowTop]=useState()
    const classes = useStyles();


    const [searchParams] = useSearchParams()
    const mission_id = searchParams.get('mission_id')

    const editMission = useSelector(state => state.editMission)
    const { status: editMissionStatus, message: editMissionMsg, loading: editMissionLoading } = editMission
    const singleMission = useSelector(state => state.getSingleMission)
    const { data: missionData, loading } = singleMission


    const { register, setError, watch, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm({
        resolver: yupResolver(qns_ans_schema),
        defaultValues: {
            title: missionData?.title,
        }
    });

    useEffect(() => {
        setDesc(missionData.description)
        setImg(missionData.icon)
        setValue("icon", missionData.icon)
        setShowTop(missionData?.latest==null?false:true)

    }, [loading])


    useEffect(() => {
        dispatch(getSingleMissionAction(mission_id))
        let defaultValues = {};
        setShowTop(missionData?.latest==null?false:true)
        // setDesc(missionData.description)

        reset({ ...defaultValues });
    }, [])

    useEffect(() => {
        dispatch(getCategoryAction())
        setValue("cat_name", missionData?.cat_name)
        // setShowTop(missionData?.latest==null?false:true)
    }, [])

    useEffect(()=>{
        if(missionData?.sub_cat_id?._id){
        dispatch(getMissionQnsAction({sub_cat_id:missionData?.sub_cat_id?._id}))

        let array=[]
        missionData?.link_qns.filter((item)=>array.push(item?._id))
        console.log("array-->",array);

    setLinkQnsList(array)
    console.log("linkQnsList-->",linkQnsList);

    }

    },[missionData])


    const onChangeEditorHandler = (e) => {
        setDesc(e)
        setValue('description', e, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        })

    }

    const handleSwitchChange=()=>{
        setShowTop((prev)=>!prev)
        
    }



    const linkedQnsColumn = [
        {
            field: 's_no',
            headerName: 'S. No.',
            minWidth: 10,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'Id',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'question',
            headerName: 'Questions',
            flex: 1,
            minWidth: 100,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'type',
            headerName: 'Question Type',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'answer_type',
            headerName: 'Answer Type',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
        },
        
    ]





    // // fn


    const handleIconUpload = async (e) => {
        // setFileLoader(true)
        const formData = new FormData();

        formData.set("file", e.target.files[0]);

        let uploadedImg = await dispatch(uplodImageAction(formData))
        const imgStr = uploadedImg?.payload?.data?.url;
        console.log("imgStr-->", imgStr)
        // setFile(uploadedImg?.payload?.data?.url)
        setValue('icon', imgStr, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,

        })
        console.log("getValues-->", getValues('icon'));
        // setFileLoader(false)


    }
    const handleQns = async (data) => {
        
        if (!linkQnsList?.length) {
            return setError('linkQnsError', { type: 'custom', message: 'Atleast single question need to link to create new mission.' });
        }


        const createMissionData = await dispatch(editMissionAction({
            mission_id: mission_id,
            correct_criteria: data.attempt_criteria,
            time_criteria: data.time_criteria,
            reward: data.rewards,
            minutes: data.time,
            icon: data.icon,
            title: data.title,
            description: desc,
            status: missionData.status,
            link_qns: linkQnsList,
            no_of_question: linkQnsList.length,
            show_top:showTop
        }))
        if (createMissionData?.payload?.status == 201) {
            await dispatch(getMissionAction({ is_deleted: false }))
            setSnack(true)

            reset()
            navigate("/mission")
        }

        setSnack(true)
        // setState(false)

    }




    const handleClose = function () {
        navigate("/mission")
    }

    console.log("getCatData", getCatData);





    return (
        <>
            <Helmet><title>Quick mission | Mission edit mission</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            {/* <DrawerUI /> */}
            <Body>
                <Card sx={{ p: 5 }}>
                    <Typography variant='h5' sx={{ mb: 5 }}>Edit Mission</Typography>
                    {loading ? <LoadingUI />
                        : <>
                            <form onSubmit={handleSubmit(handleQns)}>

                                <Stack spacing={3}>


                                    <Stack direction="row" spacing={12}>

                                        <TextField label="Attempt X Questions" defaultValue={missionData?.correct_criteria} error={errors?.attempt_criteria?.message} helperText={errors?.attempt_criteria?.message}  {...register('attempt_criteria')} style={{ width: "450px" }} />



                                        <TextField label="complete Quiz in X Time" defaultValue={missionData?.time_criteria} error={errors?.time_criteria?.message} helperText={errors?.time_criteria?.message}  {...register('time_criteria')} style={{ width: "450px" }} />


                                    </Stack>
{/* {JSON.stringify(linkQnsList)} */}
                                    <Stack direction="row" spacing={13.5}  >

                                        <TextField InputLabelProps={{ shrink: true }} label="Icon Upload" type="file" id='icon' onChange={handleIconUpload} error={errors?.icon?.message} helperText={errors?.icon?.message} style={{ width: "270px" }} />
                                        <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
                                            <Avatar src={getValues('icon') || img} sx={{ width: 60, height: 60 }} />
                                        </Badge>
                                        <TextField label="Reward" defaultValue={missionData?.reward} error={errors?.rewards?.message} helperText={errors?.rewards?.message}  {...register('rewards')} style={{ width: "450px" }} />
                                    </Stack>



                                    <Stack direction="row" spacing={12} >

                                        <TextField minRows={4} multiline={true} defaultValue={missionData?.title} label="Title" {...register('title')} error={errors?.title?.message} helperText={errors?.title?.message} style={{ width: "450px" }} />


                                        {/* <TextField minRows={4} multiline={true} defaultValue={missionData?.description} label="Description" error={errors?.description?.message} helperText={errors?.description?.message}  {...register('description')} style={{ width: "450px" }} /> */}

                                        <JoditEditor
                                            value={desc}
                                            tabIndex={2}
                                            onBlur={onChangeEditorHandler}
                                            onChange={onChangeEditorHandler}
                                        />

                                    </Stack>

                                    <Stack direction="row" spacing={10} >

                                        <FormControlLabel control={<Switch onChange={handleSwitchChange} checked={showTop} />} label="Show on Top" />


                                    </Stack>



                                     <Paper sx={{
                                        bgcolor: '#fff',
                                        '& .super-app-theme--header': {
                                            bgcolor: '#071D45',
                                            color: '#fff',
                                        },
                                    }} elevation={0}>
                                        <Typography sx={{ mt: 2 }} variant='h6'>Link Questions</Typography>
                                        <DataGrid
                                            className={classes.root}
                                            checkboxSelection
                                            getRowHeight={() => 'auto'}
                                            sx={{ border: 0 }}
                                            autoHeight
                                            rows={qnsData}
                                            columns={linkedQnsColumn}
                                            getRowId={(row) => row._id}
                                            // disableSelectionOnClick
                                            experimentalFeatures={{ newEditingApi: true }}
                                            pagination
                                            pageSize={pageSize}
                                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                            rowsPerPageOptions={[25, 50, 100]}
                                            // disableColumnSelector
                                            loading={qnsLoading ? <LoadingUI /> : false}
                                            // rowSelectionModel={linkQnsList}
                                            //  onRowSelectionModelChange={setLinkQnsList}
                                            selectionModel={linkQnsList}
                                            onSelectionModelChange={linkedQns => setLinkQnsList(linkedQns)}
                                            components={{
                                                NoRowsOverlay: () => <NoDataFound />,
                                                Toolbar: GridToolbar,
                                            }}

                                        />
                                    </Paper> 
                                    <Stack direction='row' spacing={2}>
                                        <Button variant='contained' type='submit' disabled={createMissionLoading}>{createMissionLoading ? <CircularProgress /> : 'Update Mission'}</Button>
                                        <Button variant='contained' color='error' onClick={handleClose}>Back</Button>
                                    </Stack>
                                </Stack>
                            </form>

                        </>
                    }
                </Card>
            </Body>
            <SnackBarUI state={snack} setState={setSnack} status={editMissionStatus} message={editMissionMsg} />
        </>
    )
}


EditMission.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any,
    sub_cat_id: PropTypes.any,
    mission_id: PropTypes.string,
    editLinkQns: PropTypes.any,
};