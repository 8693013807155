import CachedIcon from '@mui/icons-material/Cached';
import { Box, Card, Grid, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingUI from '../../comman/LoadingUI';
// import { totalRevenueAction } from '../../redux/toolkit/dashbaord/totalRevenue';
// import { userDayWiseAction } from '../../redux/toolkit/dashbaord/userDayWise';
// import { toPublisherAction } from '../../redux/toolkit/dashbaord/toPublisher';
import { Graph_Dashbaord } from './Graph_Dashbaord';
import { Link as LinkRRD } from "react-router-dom"





export default function Dashboard() {
    // state
    // const dispatch = useDispatch()

    // const totalRevenueSelector = useSelector(state => state.totalRevenue)
    // const { data: totalRevenueData, loading: totalRevenueLoading } = totalRevenueSelector


    // // fn
    // const handleRefresh = () => {
    //     dispatch(totalRevenueAction())
    //     dispatch(toPublisherAction())
    //     dispatch(userDayWiseAction())
    // }


    // // useEffect
    // useEffect(() => {
    //     dispatch(totalRevenueAction())
    //     dispatch(toPublisherAction())
    //     dispatch(userDayWiseAction())
    // }, [dispatch])


    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
                {/* <Grid xs={4} item>
                        <LinkRRD style={{ textDecoration: 'none' }} to='/analytics/user-details'>
                        <Dropped />
                        </LinkRRD>
                        <Graph_Dashbaord/>
                    </Grid> */}

                    <Grid xs={4} item>
                        <LinkRRD style={{ textDecoration: 'none' }} to='/publisher'>
                            {/* <DisqualifiedAttempts /> */}
                        </LinkRRD>
                    </Grid>

                    <Grid xs={4} item>
                        <LinkRRD style={{ textDecoration: 'none' }} to='/publisher'>
                            {/* <AttemptsQuit /> */}
                        </LinkRRD>
                    </Grid>
        </Grid>
        
    )
}
