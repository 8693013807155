import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CircularProgress, Modal, TextField, Typography, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup } from '@mui/material';
import { Stack } from '@mui/system';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { getAllQuestionAnswerAction } from '../../redux/toolkit/question-answer/getAllQuestionAnswer';
import { updateQuestionAnswerAction } from '../../redux/toolkit/question-answer/updateQuestionAnswer';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { uplodImageAction } from '../../redux/toolkit/image/uplodImage';


// schema
const qns_ans_schema = yup.object({
    question: yup.string().required('Question is required.'),
    answer_type: yup.string().required('Answer type is required.'),
    // type: yup.string().required('question type is required'),

}).required();


export default function UpdateQnsAns({ qns_id, state, setState }) {
    // state
    const dispatch = useDispatch()
    const updateQns = useSelector(state => state.updateQuestionAnswer)
    const { status, message, loading } = updateQns
    const getQns = useSelector(state => state.getSingleQuestionAnswer)
    const { data: { question, answer_type: answerType, options, type: qType, file_url, title: Title,difficulty } } = getQns
    const input = {
        option: null,
        correct: null
    }
    const [answerOption, setAnswerOption] = useState(input)
    const [ansArr, setAnsArr] = useState(options)
    const [file, setFile] = useState('')
    // const [title,setTitle]=useState('')
    const [type, setType] = useState('')
    const [diff,setDiff]=useState('')
    const [fileLoader, setFileLoader] = useState(false)




    const { register, setValue, reset, watch, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(qns_ans_schema),
        defaultValues: {
            question: question,
            answer_type: answerType,
            title: Title,

        }
    });
    const answer_type = watch("answer_type");



    const [snack, setSnack] = useState(false)

    // fn
    const handleQns = async (data) => {
        data.options = ansArr
        await dispatch(updateQuestionAnswerAction({ qns_id: qns_id, file_url: file, ...data }))
        await dispatch(getAllQuestionAnswerAction({ is_deleted: false }))
        setSnack(true)
        setState(false)
    }

    const handleClose = function () {
        setState(false)
    }
    const handleAddAnsOpt = () => {
        // setAnswerOption({ ...answerOption, "correct": answerData })

        // setAnswerOption(answerOption=>({...answerOption,"correct": answerData}))

        const foundDuplicate = ansArr.find(element => element === answerOption.option);
        if (foundDuplicate !== undefined) return
        setAnsArr(prev => [...prev, answerOption])
        setAnswerOption({
            option: "",
            correct: false,
        })
    }

    const changeOptInput = (e) => {
        setAnswerOption({ ...answerOption, [e.target.id]: e.target.value })
    }

    const handleFileUpload = async (e) => {
        setFileLoader(true)
        const formData = new FormData();

        formData.set("file", e.target.files[0]);

        let uploadedImg = await dispatch(uplodImageAction(formData))
        const imgStr = uploadedImg?.payload?.data?.url;
        setFile(uploadedImg?.payload?.data?.url)
        setValue('file', imgStr)
        
        if (e.target.files[0].type == 'video/*' || e.target.files[0].type == 'audio/mp3') {
            const fileUrl = URL.createObjectURL(e.target.files[0]);
            const media = new Audio(fileUrl);
            media.onloadedmetadata = function () {
                console.log("Duration:", media.duration);
                // setTimeDuration(media.duration)
            };
        }
        setFileLoader(false)


    }

    const handleAnswerType = (event, value) => {
        if (value === "optional" || value === "MCQ") {
            setAnsArr([])
        }
    }
    const handleQuestionType = (event, value) => {
        // console.log("value",value)
        setType(value)
        setFile('')
    }
    const handleDifficultyType=(even,value)=>{
        console.log("value->",value)
            setDiff(value)
    }
    const handleDelAnsOpt = (i) => {
        const tempArr = JSON.parse(JSON.stringify(ansArr));
        tempArr.splice(i, 1)

        setAnsArr([...tempArr])
        setAnswerOption(input)
    }


    useEffect(() => {
        let defaultValues = {};
        defaultValues.question = question;
        defaultValues.answer_type = answerType;
        defaultValues.title = Title;
        // defaultValues.type = qType;
        setFile(file_url)
        // setTitle(title)
        setType(qType)
        setValue('title', Title)
        setDiff(difficulty)
        // setValue(type,qType)
        reset({ ...defaultValues });
    }, [question, qType, file_url, Title])

    useEffect(() => {
        setAnsArr(options)
    }, [options])





    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 500, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Update Question Answer</Typography>
                        <form onSubmit={handleSubmit(handleQns)}>
                            <Stack spacing={3}>
                                <FormControl>
                                    <FormLabel>Question Type</FormLabel>
                                    <RadioGroup onChange={handleQuestionType}>
                                        <Stack direction='row'>
                                            <FormControlLabel value="text" checked={type === 'text'} {...register('type')} control={<Radio />} label="Text" />
                                            <FormControlLabel value="image" checked={type === 'image'} {...register('type')} control={<Radio />} label="Image" />
                                            <FormControlLabel value="audio" checked={type === 'audio'} {...register('type')} control={<Radio />} label="Audio" />
                                            <FormControlLabel value="video" checked={type === 'video'} {...register('type')} control={<Radio />} label="Video" />
                                        </Stack>
                                    </RadioGroup>
                                    <FormHelperText error={errors?.type?.message}>{errors?.type?.message}</FormHelperText>
                                </FormControl>
                                {
                                    (type === 'image' || type === 'audio' || type === 'video') &&
                                    <>
                                        <TextField label="Title" variant="outlined" placeholder='Title' {...register('title')} /*error={errors?.question?.message} helperText={errors?.question?.message}*/ />
                                        <TextField label="File Upload" InputLabelProps={{ shrink: true }} type='file' accept="*" variant="outlined" id='file' placeholder='file'/* {...register('file')} */ onChange={handleFileUpload} error={errors?.file?.message} helperText={errors?.file?.message} />
                                    </>
                                }
                                {fileLoader ? <CircularProgress /> : <>{console.log("file and type", file, type)}{

                                    (type === 'image' || type === 'audio' || type === 'video') && file && <>
                                        <Stack direction='center' style={{ diplay: 'flex', justifyContent: 'center', alingItems: 'center' }}>
                                            {/* <Button onClick={() => { setFile('') }}>reupload</Button> */}
                                            {type === 'image' && <img src={file} width="100px" alt="preview" />}
                                            {type === 'audio' && <audio controls src={file} width="100px"></audio>}
                                            {type === 'video' && <video autoplay="true" controls src={file} width="200px" alt="preview" />}

                                            {/* <Button onClick={() => { window.open(file, '_blank'); }}>watch</Button> */}
                                        </Stack>
                                    </>
                                }
                                </>
                                }

                            </Stack>

                            <FormControl>
                                    <FormLabel>Question Type</FormLabel>
                                    <RadioGroup onChange={handleDifficultyType}>
                                        <Stack direction='row'>
                                            <FormControlLabel value="easy" checked={diff === 'easy'} {...register('difficulty')} control={<Radio />} label="Easy" />
                                            <FormControlLabel value="medium" checked={diff === 'medium'} {...register('difficulty')} control={<Radio />} label="Medium" />
                                            <FormControlLabel value="hard" checked={diff === 'hard'} {...register('difficulty')} control={<Radio />} label="Hard" />
                                        </Stack>
                                    </RadioGroup>
                                    <FormHelperText error={errors?.type?.message}>{errors?.type?.message}</FormHelperText>
                                </FormControl>
                            <Stack spacing={3} sx={{ marginTop: "20px" }}>
                                {question && <TextField defaultValue={question} type='text' label="Question" variant="outlined" placeholder='Question' {...register('question')} error={errors?.question?.message} helperText={errors?.question?.message} />}

                                <FormControl>
                                    <FormLabel>Answer Type</FormLabel>
                                    <RadioGroup onChange={handleAnswerType}>
                                        <Stack direction='row'>
                                            <FormControlLabel value="optional" checked={answer_type === 'optional'} {...register('answer_type')} control={<Radio />} label="Optional" />
                                            <FormControlLabel value="MCQ" checked={answer_type === 'MCQ'} {...register('answer_type')} control={<Radio />} label="Multiple Choice" />
                                        </Stack>
                                    </RadioGroup>
                                    <FormHelperText error={errors?.answer_type?.message}>{errors?.answer_type?.message}</FormHelperText>
                                </FormControl>

                                {
                                    (answer_type === 'optional' || answer_type === 'MCQ') &&
                                    <>
                                        <Grid container columnSpacing={1}>
                                            <Grid item xs={5}>
                                                <TextField value={answerOption.option} id="option" onChange={(e) => { changeOptInput(e) }} fullWidth label="Answer Options" variant="outlined" placeholder='Answer Options' />
                                            </Grid>
                                            <Grid item xs={1}>

                                            </Grid>
                                            <Grid item xs={4}>
                                                <Stack direction='row'>
                                                    <RadioGroup >
                                                        <FormControlLabel value={false} checked={answerOption?.correct === false} control={<Radio />} onChange={() => { setAnswerOption({ ...answerOption, 'correct': false }) }} label="False" />
                                                        <FormControlLabel value={true} checked={answerOption?.correct === true} control={<Radio />} onChange={() => { setAnswerOption({ ...answerOption, 'correct': true }) }} label="True" />
                                                    </RadioGroup>

                                                </Stack>
                                            </Grid>
                                            <Grid item xs={1}>
                                                {answerOption.option && (answerOption.correct == false || answerOption.correct == true) && <IconButton color='primary' onClick={handleAddAnsOpt} ><AddCircleIcon fontSize='large' /></IconButton>}
                                            </Grid>
                                        </Grid>
                                        <FormHelperText error={errors?.ansOps?.message}>{errors?.ansOps?.message}</FormHelperText>

                                        <Stack spacing={1}>

                                            <TableContainer component={Paper}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ maxWidth: "10px" }} align="left">sno</TableCell>
                                                            <TableCell align="right">option</TableCell>
                                                            <TableCell align="right">correct</TableCell>
                                                            <TableCell align="right">delete</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {
                                                            ansArr && ansArr?.length > 0 ? ansArr?.map((item, index) => {
                                                                return (


                                                                    <TableRow
                                                                        key={index}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >

                                                                        <TableCell align="right">{index + 1}</TableCell>
                                                                        <TableCell align="right">{item.option}</TableCell>
                                                                        <TableCell align="right">{item?.correct === true ? "true" : "false"}</TableCell>
                                                                        <TableCell align="right"><Button onClick={() => handleDelAnsOpt(index)}>delete</Button></TableCell>
                                                                    </TableRow>
                                                                )
                                                            }) : null
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </Stack>
                                    </>
                                }

                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Update Q & A'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>

            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            <DevTool control={control} />
        </>
    )
}


UpdateQnsAns.propTypes = {
    qns_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};
