import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SnackBarUI from '../../comman/SnackBarUI'
import { blockAndUnBlockUserAction } from '../../redux/toolkit/users/blockAndUnBlockAdmin'
import { getUserAction  } from '../../redux/toolkit/users/getAdminSupports'

export default function BlockUnBlockAdmin({ user_id, is_block, state, setState }) {
    // state
    const dispatch = useDispatch()
    const blockAndUnBlockAdminSelector = useSelector(state => state.blockAndUnBlockAdmin)
    const { status, message } = blockAndUnBlockAdminSelector

    const [snack, setSnack] = useState(false)

    // fn
    const handleClose = () => {
        setState(false)
    }

    const handleBlockUnBlockAdmin = async () => {
        let blockUnBlock = await dispatch(blockAndUnBlockUserAction({ user_id: user_id, is_block: is_block }))
        if (blockUnBlock?.payload?.status == 201) {
            await dispatch(getUserAction ({is_block:is_block}))
        

        }
        setSnack(true)
        setState(false)
    }

    return (
        <>
            <Dialog open={state} onClose={handleClose}>
                <DialogTitle>Block / Unblock request !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to perform this action ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleBlockUnBlockAdmin} autoFocus> Agree</Button>
                    <Button onClick={handleClose}>Disagree</Button>
                </DialogActions>
            </Dialog>


            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>

    )
}



BlockUnBlockAdmin.propTypes = {
    user_id: PropTypes.any,
    is_block: PropTypes.any,
    state: PropTypes.any,
    setState: PropTypes.any
};
