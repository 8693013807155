import React from 'react'
import DrawerUI from '../components/Drawer'
import Body from './Body'
import { Outlet } from "react-router-dom";

function index() {
  return (
    <>
      <DrawerUI/>
      <Body/>
      <Outlet/>

    </>
  )
}

export default index