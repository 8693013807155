import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
// import EditIcon from '@mui/icons-material/Edit';
import { Button, Card, CircularProgress, Modal, TextField, Typography,Avatar,Badge } from '@mui/material';
import { Box, Stack} from '@mui/system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { createCategoryAction } from '../../redux/toolkit/category/createCategory';
import { getCategoryAction } from '../../redux/toolkit/category/getCategory';
import { uplodImageAction } from '../../redux/toolkit/image/uplodImage';
import { editMetaAction } from '../../redux/toolkit/config/editmeta';
import { getMetaAction } from '../../redux/toolkit/config/getmeta';



// schema
// const catSchema = yup.object().shape({
//     // redirect_mission_id: yup.string().required('MissionId  is required.'),
    
// }).required();

export default function RedirectMission({ state, setState }) {
    // state
    // const { register,setValue,control, handleSubmit, formState: { errors }, reset,setError } = useForm({ resolver: yupResolver(catSchema),defaultValues: {} });

    const dispatch = useDispatch()
    const editMeta = useSelector(state => state.editMeta)
    const { status, message, loading } = editMeta

    const getMeta = useSelector(state => state.getMeta)
    const { data:metaData } = getMeta

    const [snack, setSnack] = useState(false)
    const [redirectId,setRedirectId]=useState('')
    

    // fn

    useEffect(()=>{
        
        dispatch(getMetaAction())
    },[])

    useEffect(() => {

       setRedirectId(metaData.redirect_mission_id)
       
       
    }, [dispatch, metaData])

 

   
    const submitHandler = async () => {
        // e.prevantDefault()
        let finalData={
            ...metaData,
            redirect_mission_id:redirectId,
            is_maintinance_mode:false,
            config_id:metaData._id
        }
        
        const createCategoryData = await dispatch(editMetaAction(finalData))
        if (createCategoryData?.payload?.status == 200) {
            setSnack(true)
            setTimeout(()=>{
                setState(false)
            },3000)
            // setState(false

        }

        
        // setState(false)
        
        // setSnack(true)
        // reset()
        // setState(false)
        
    }

    const handleClose = function () {
        setState(false)
    }


    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 400, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Redirect Mission</Typography>
                        <form>
                            <Stack spacing={3}>
                                <TextField type='text' label="MissionId" value={redirectId} onChange={(e)=>{setRedirectId(e.target.value)}} variant="outlined" />
                                
                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit'  onClick={submitHandler} disabled={loading}>{loading ? <CircularProgress /> : 'Update'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>
            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            {/* <DevTool control={control} /> */}
        </>
    )
}



RedirectMission.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any
};