import { DevTool } from '@hookform/devtools'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RestoreIcon from '@mui/icons-material/Restore'
import { Breadcrumbs, Button, Card, IconButton, Link, Menu, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
// import Drawer from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { useStyles } from '../../theme/theme'
import { bankDataAction } from '../../redux/toolkit/bank/List';
import { bankDetailAction } from '../../redux/toolkit/bank/Detail';
import DetailPopup from './DetailPopup';
import VerifyBank from './Verify';

// import CreateSupport from './CreateSupport';
// import UpdateSupport from './UpdateSupport';
// import DeleteSupport from './DeleteSupport';



const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} href='/bank'>bank Account</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Bank Accounts</Typography>
    </Stack>
)




export default function Index() {
    // state--->
    const { register, reset, control, handleSubmit } = useForm({});
    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData } = permission
    const singleSupport = useSelector(state => state.singleSupport)
    const { data,loading,message,status } = singleSupport
    const {answers,title}=data

    const dispatch = useDispatch()
    const cat = useSelector(state => state.bankData)
    const { data: catData, loading: catDataLoading } = cat
    const catSeq = useSelector(state => state.changeCatSeq)
    const { status: catSeqStatus, message: catSeqMsg } = catSeq

    let can_delete = JSON.parse(localStorage.getItem("ss_active_usr"))?.settings?.can_delete
    let super_admin = permissionData?.role
    console.log("super_admin-->", super_admin);


    const [snack, setSnack] = useState(false)
    const [catMenu, setCatMenu] = useState(false)
    const [createCat, setCreateCat] = useState(false)
    const [updateCat, setUpdateCat] = useState(false)
    const [delCat, setDelCat] = useState(false)
    const [restoreCat, setRestoreCat] = useState(false)
    const [anchorCatMenu, setAnchorCatMenu] = useState()
    const [ativeFilter, setActiveFilter] = useState(false)
    const [pageSize, setPageSize] = useState(25);
    const [cat_id, seCat_id] = useState('')
    const classes = useStyles();
    const [date, setDate] = useState({
        start: null,
        end: null,
    })
    const [isFilterArea, setIsFilterArea] = useState(false)
    const [state,setState]=useState(false)
    const [detailId,setDetailId]=useState()
    const [verfyAdmin, setVerifyAdmin] = useState(false)
    const [bank_id,setbank_id]=useState()




    const catDataGridColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            headerClassName: 'super-app-theme--header',
            flex: 1,
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 250,
            renderCell:(index)=>{
                return (
                <Button sx={{color:"blue"}} onClick={()=>{
                    setState(true)
                    setDetailId(index?.row?._id)
                }}>
                    {index.row._id}
                </Button>
                )
               
            }
        },
        {
            field: 'Publisher Name',
            headerName: 'Publisher Name',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.publisher_id.name}


                    </>
                )
            },
        },
        {
            field: 'holder_name',
            headerName: 'Holder Name',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'account_no',
            headerName: 'Account No',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
            
        },
        {
            field: 'verify',
            headerName: 'Verify',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <>
                        {params?.row?.is_verified===1
                            ? <Typography color="primary">Verified</Typography>
                            : <IconButton sx={{ borderRadius: 2 }} onClick={() => { setVerifyAdmin(true); setbank_id(params?.row?._id) }}><Typography color="error">Verify</Typography></IconButton>}


                    </>
                )
            },
        },
        // {
        //     field: 'link_qns',
        //     headerName: 'Total Questions',
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     minWidth: 200,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <Typography>
        //                     {params?.row?.link_qns?.length}
        //                 </Typography>
        //             </>
        //         )
        //     },
        // },
        // {
        //     field: 'no_of_survey',
        //     headerName: 'Number Of Survey',
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     minWidth: 200,
        // },
        // {
        //     field: 'sequence_number',
        //     headerName: 'Sequence of Category',
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <TextField
        //                     sx={{ my: 2 }} type='number' label="Category Sequence" size='small'
        //                     defaultValue={params?.row?.sequence}
        //                     variant="outlined"
        //                     placeholder='Category Sequence'
        //                     {...register(params?.row?._id)}
        //                 />
        //             </>
        //         )
        //     },
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     minWidth: 200,
        //     hide: ativeFilter
        // },

        {
            field: 'account_type', headerName: 'Account Type',
            
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 150,
            hide: ativeFilter
        },
        {
            field: 'branch', 
            headerName: 'Branch', 
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 150,
            hide: ativeFilter

        },
        // {
        //     field: 'branch',
        //     headerName: 'Branch',
        //     renderCell: () => {
        //         return (
        //             <IconButton
        //                 onClick={() => {
        //                     setRestoreCat(true);
        //                 }}
        //             ><RestoreIcon color='success' />
        //             </IconButton>
        //         )
        //     },
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     hide: !ativeFilter

        // },
    ]


    // fn


    const handleclearFilter = () => {
        setActiveFilter(false)
        dispatch(bankDataAction({ is_deleted: false }))
    }

    const handleFilterData = () => {
        dispatch(bankDataAction({ is_deleted: false, s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
    }

    // useEffect
    useEffect(() => {
        dispatch(bankDataAction({ page:0,limit:100}))
    }, [dispatch])



    return (
        <>
            <Helmet><title>Quiz Wall | Bank Account</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            {/* <Drawer /> */}
            <Body>

                
                {/* <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    <Button variant="outlined" startIcon={<FilterListIcon />} onClick={() => { setIsFilterArea(!isFilterArea) }}>
                        filter by date
                    </Button>
                    <Button variant="contained" color="btn" startIcon={<AddIcon />} onClick={() => setCreateCat(true)}>
                        create support
                    </Button>
                </Stack> */}

                <Card sx={{ my: 2 }}>

                    {isFilterArea && <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ p: 2 }}>
                            <Typography color='#071D45'>Filter by Date</Typography>
                            <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                                <DatePicker
                                    label="Start Date"
                                    value={date?.start}
                                    onChange={(value) => setDate({ ...date, start: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    maxDate={date?.end}

                                />
                                <DatePicker
                                    label="End Date"
                                    value={date?.end}
                                    onChange={(value) => setDate({ ...date, end: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={date?.start}
                                />
                                <Box>
                                    <IconButton onClick={handleFilterData} color='#071D45'><FilterAltIcon /></IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </LocalizationProvider>}


                    <Stack direction={'row'} justifyContent='space-between' alignItems='center' sx={{ ml: 1 }} >
                        <Typography sx={{ my: 1 }} variant='h6'>Bank Account Request</Typography>
                        <Box sx={{ m: 2 }}>
                            {/* {(can_delete || super_admin == 'super_admin')
                                && */}
                            <>
                                <IconButton color='error' onClick={handleclearFilter}><ClearAllIcon /></IconButton>
                                
                                <Button  startIcon={<FilterListIcon />} onClick={(event) => {
                                        setCatMenu(true);
                                        setAnchorCatMenu(event.currentTarget);
                                    }}>
                                    filter
                                </Button>
                            </>


                            {/* // } */}

                            <Menu
                                open={catMenu}
                                onClose={() => setCatMenu(false)}
                                anchorEl={anchorCatMenu}
                            >
                                <MenuItem selected={ativeFilter}
                                    onClick={() => {
                                        setCatMenu(false)
                                        dispatch(bankDataAction({ is_deleted: true }))
                                        setActiveFilter(true)
                                    }} >Deleted</MenuItem>
                                <MenuItem selected={!ativeFilter}
                                    onClick={() => {
                                        setCatMenu(false)
                                        dispatch(bankDataAction({ is_deleted: false }))
                                        setActiveFilter(false)
                                    }}>Non Deleted</MenuItem>
                            </Menu>
                        </Box>
                    </Stack>

                    {/* table list */}
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#E7F7FF',
                            color: '#000',
                        },
                    }} elevation={0}>


                        <DataGrid
                            className={classes.root}
                            sx={{
                                border: 0,
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: "#E7F7FF"
                                    // color: "red"
                                  }
                            }}
                            getRowHeight={() => 'auto'}
                            autoHeight
                            rows={catData}
                            columns={catDataGridColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={catDataLoading ? <LoadingUI /> : false}
                            onCellClick={(params) => {
                                seCat_id(params?.row?._id)
                            }}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,

                            }}

                        />
                    </Paper>
                </Card>

                {/* {!ativeFilter && catData?.length ? <Button onClick={handleSubmit(handleCatChangeSeq)} sx={{ my: 2 }} variant='contained' color='error'>Change Category Sequence</Button> : null} */}

                {/* crud */}
                {/* <CreateSupport state={createCat} setState={setCreateCat} />
                <DeleteSupport cat_id={cat_id} state={delCat} setState={setDelCat} />
                {!loading?<UpdateSupport  state={updateCat} setState={setUpdateCat} />:<center>loading...</center>} */}
               {/* <ReStoreCategory cat_id={cat_id} state={restoreCat} setState={setRestoreCat} resetCatSeq={reset} /> */}
            </Body >


            {/* <SnackBarUI state={snack} setState={setSnack} status={catSeqStatus} message={catSeqMsg} /> */}
            {state && <DetailPopup state={state} setState={setState} detailId={detailId}/>}
            {verfyAdmin && <VerifyBank bank_id={bank_id} state={verfyAdmin} setState={setVerifyAdmin} />}

            <DevTool control={control} />
        </>
    )
}
