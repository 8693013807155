import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }



export const uploadQnsAnsAction = createAsyncThunk(
    'upload-question-answer',
    async (data = null, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`/admins/api/upload-question-answer`, { qns_ans: data })
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const uploadQnsAnsSlice = createSlice({
    name: 'upload-question-answer',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(uploadQnsAnsAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(uploadQnsAnsAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(uploadQnsAnsAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const uploadQnsAnsReducer = uploadQnsAnsSlice.reducer