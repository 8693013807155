import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const getMissionLinkedQnsAction = createAsyncThunk(
    'mission-linked-qns',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/admins/api/mission-linked-qns?mission_id=${data}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const getMissionLinkedQnsSlice = createSlice({
    name: 'mission-linked-qns',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(getMissionLinkedQnsAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(getMissionLinkedQnsAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(getMissionLinkedQnsAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const getMissionLinkedQnsReducer = getMissionLinkedQnsSlice.reducer    