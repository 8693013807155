import { Box, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached';
import { droppedAction } from '../../redux/toolkit/dashbaord/dropped';
import BarChartIcon from '@mui/icons-material/BarChart';

export default function Dropped() {
    // state
    const dispatch = useDispatch()
    const totalDroppedSelector = useSelector(state => state.totalDropped)
    const { data, loading } = totalDroppedSelector

    // fn
    const handleRefeash = () => {
        dispatch(droppedAction())
    }

    // use effect
    useEffect(() => {
        dispatch(droppedAction())
    }, [dispatch])

    return (
        <Card sx={{ p: 2, color:'#0C6A96' }} >
            <Stack direction='row' justifyContent='space-between' >
                <BarChartIcon/>
                <Typography sx={{ color: '#5E6C8A',fontFamily: 'Montserrat,sans-serif',fontStyle:'normal' }} variant='subtitle2'>Dropped by Users</Typography>
                <IconButton sx={{ color: '#5E6C8A' }} onClick={handleRefeash}><CachedIcon /></IconButton>
            </Stack>

            {
                loading
                    ? <CircularProgress />
                    : <Box>
                        <Typography variant='h3' color='primary' sx={{ fontWeight: 600, color: '#071D45' }}>{data?.no_of_mission_dropped?data?.no_of_mission_dropped:"---"}</Typography>
                    </Box>
            }
        </Card>
    )
}
