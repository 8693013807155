import { Box, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect,useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached';
import TrackChangesSharpIcon from '@mui/icons-material/TrackChangesSharp';
import axios from '../../config/authAxios'

export default function DailyActiveUser() {
    // state
    const [data,setData]=useState()
    const [loading,setLoading]=useState(false)
    // const { data, loading } = totalUsersSelector

    // fn
    const handleRefeash = () => {
        // dispatch(totalUsersAction())
        fetchApi()
    }

    // use effect

    const fetchApi=async()=>{
        setLoading(true)
        const res=await axios.get('admins/api/daily-total-active-users')
        setData(res.data.data)
        setLoading(false)

    }

    useEffect(() => {
      
       fetchApi()
    }, [])

    return (
        <Card sx={{ p: 2, color:'#0C6A96' }} >
            <Stack direction='row' justifyContent='space-between' >
                <TrackChangesSharpIcon color='#0C6A96'/>
                <Typography sx={{ color: '#5E6C8A',fontFamily: 'Montserrat,sans-serif',fontStyle:'normal' }} variant='subtitle2'>Today Active User</Typography>
                <IconButton sx={{ color: '#5E6C8A' }} onClick={handleRefeash}><CachedIcon /></IconButton>
            </Stack>

            {
                loading
                    ? <CircularProgress />
                    : <Box>
                        <Typography variant='h3' color='primary' sx={{ fontWeight: 600, color: '#071D45' }}>{data?data:"---"}</Typography>
                    </Box>
            }
        </Card>
    )
}
