
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { loading: false, data: [], error: "", message: "" }


export const createSupportDataAction = createAsyncThunk(
    'create-support-data',
    async (data = null, { rejectWithValue }) => {
        try {
            const res = await axios.post(`/admins/api/support-knowladge`,data)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const createSupportDataSlice = createSlice({
    name: 'create-support-data',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(createSupportDataAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(createSupportDataAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.message = action?.payload?.message
                state.status = action?.payload?.status
            })
            builder.addCase(createSupportDataAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.message = action?.payload?.message
                state.status = action?.payload?.status

            })
        },

})



export const createSupportDataReducer = createSupportDataSlice.reducer    






// router.get("/support-knowladges/:page/:limit/:is_deleted", new SupportKnowladge().supportKnowladgeList)
// router.get("/support-knowladge/:id", new SupportKnowladge().supportKnowladgeDetail)
// router.post("/support-knowladge", new SupportKnowladge().createSupportKnowladge)
// router.put("/support-knowladge", new SupportKnowladge().updateSupportKnowladge)
// router.delete("/support-knowladge", new SupportKnowladge().deleteAndResoteSupportKnowladge)