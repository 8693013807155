import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Autocomplete, Box, Button, Card, Chip, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { createQuestionAnswerAction } from '../../redux/toolkit/question-answer/createQuestionAnswer';
import { getAllQuestionAnswerAction } from '../../redux/toolkit/question-answer/getAllQuestionAnswer';
import { getSubCategoryAction } from '../../redux/toolkit/sub-category/getSubCategory';
import { getCategoryAction } from '../../redux/toolkit/category/getCategory';
import { uplodImageAction } from '../../redux/toolkit/image/uplodImage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { getValue } from '@testing-library/user-event/dist/utils';

let cat_id = ''
let sub_cat_id = ''


// schema
const qns_ans_schema = yup.object({
  cat_name: yup.string().required('Category is required.'),
  sub_cat_name: yup.string().required('Sub category is required.'),
  type: yup.string().required('question type is required'),
  // file: yup.string().required('file is required'),
  question: yup.string().required('Question is required.'),
  answer_type: yup.string().required('Answer type is required.'),
}).required();


export default function CreateQuestionAnswer({ state, setState }) {

  const input = {
    option: null,
    correct: null
  }
  // state
  const { register, setValue, setError, watch, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(qns_ans_schema) });
  const answer_type = watch("answer_type");
  const type = watch('type');

  const dispatch = useDispatch()
  const createQns = useSelector(state => state.createQuestionAnswer)
  let { status, message, loading } = createQns
  const getCat = useSelector(state => state.getCategory)
  const { data: getCatData } = getCat

  const getSubCat = useSelector(state => state.getSubCategory)
  const { data: getSubCatData } = getSubCat

  const [snack, setSnack] = useState(false)
  const [ansArr, setAnsArr] = useState([])
  const [answerOption, setAnswerOption] = useState(input)
  const [correct_answer, setCorrect_answer] = useState([])
  const [correctAnswerSelect, setCorrectAnswerSelect] = useState({})
  const [file, setFile] = useState('')
  const [fileLoader, setFileLoader] = useState(false)
  const [answerData, setAnswerData] = useState(false)
  const [timeDuration, setTimeDuration] = useState("")
  const [optionalTrue, setOptionalTrue] = useState(false)
  var singleTrue
  // const [questionType,setQuestionType]=useState('')


  // fn
  const changeOptInput = (e) => {
    setAnswerOption({ ...answerOption, [e.target.id]: e.target.value })
  }
  const handleQns = async (data) => {
   
    data.file_url = file
    if (!ansArr.length || ansArr.length === 1) {
      return setError('ansOps', { type: 'custom', message: 'Answer option can not be empty. and length must be atleast 2.' });
    }
  
    const createQnsDataAction = await dispatch(createQuestionAnswerAction({ options: ansArr, cat_id: cat_id?._id, sub_cat_id: sub_cat_id?._id, ...data }))
    if (createQnsDataAction?.payload?.status === 201) {
      await dispatch(getAllQuestionAnswerAction({ is_deleted: false }))
    }
    setAnsArr([])
    setSnack(true)
    setState(false)
    reset()
    setFile()
  }

  const handleFileUpload = async (e) => {
    setFileLoader(true)
    const formData = new FormData();

    formData.set("file", e.target.files[0]);

    let uploadedImg = await dispatch(uplodImageAction(formData))
    const imgStr = uploadedImg?.payload?.data?.url;
    setFile(uploadedImg?.payload?.data?.url)
    setValue('file', imgStr)
    
    if (e.target.files[0].type == 'video/*' || e.target.files[0].type == 'audio/mp3') {
      const fileUrl = URL.createObjectURL(e.target.files[0]);
      const media = new Audio(fileUrl);
      media.onloadedmetadata = function () {
        console.log("Duration:", media.duration);
        setTimeDuration(media.duration)
      };
    }
    setFileLoader(false)


  }


  const handleClose = function () {
    setAnsArr([])
    setState(false)
  }

  const handleAnswerType = (event, value) => {
    if (value === "optional" || value === "MCQ") {
      setAnsArr([])
    }
  }

  const handleAddAnsOpt = () => {
    // setAnswerOption({ ...answerOption, "correct": answerData })

    // setAnswerOption(answerOption=>({...answerOption,"correct": answerData}))

    const foundDuplicate = ansArr.find(element => element === answerOption.option);
    if (foundDuplicate !== undefined) return
    setAnsArr(prev => [...prev, answerOption])

    setAnswerOption({
      option: "",
      correct: false,
    })
  }
  useEffect(() => {
    console.log("ansArr", ansArr)
    singleTrue = ansArr.some(item => item.correct === true) ? setOptionalTrue(false) : setOptionalTrue(true)
    console.log("singleTrue", ansArr, singleTrue)
  }, [ansArr])


  const handleDelAnsOpt = (i) => {
    const tempArr = ansArr
    tempArr.splice(i, 1)
    // setAnsArr((current) => current.filter((index) => { return index !== i; }))
    setAnsArr([...tempArr])
    setAnswerOption(input)
  }

  const handleAnsChange = (event, value) => {
    if (value === 'AgreeDisagree') setAnsArr(['Agree', 'Disagree'])
    else if (value === 'TrueFalse') setAnsArr(['True', 'False'])
    else if (value === 'YesNo') setAnsArr(['Yes', 'No'])
  }

  const handleCorrectAnswer = (item, index) => {
    setCorrectAnswerSelect({ [index]: true })
    setCorrect_answer(preCorrect_answer => [...preCorrect_answer, item]);
    // setCorrect_answer(item)
  }
  useEffect(() => {
    dispatch(getCategoryAction())

  }, [])
  useEffect(() => {
    console.log("useEffect-->", ansArr);
  }, [ansArr])

  return (
    <>
      <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
        <Box className='modal_box'>
          <Card sx={{ width: 500, p: 5 }}>
            <Typography variant='h5' sx={{ mb: 5 }} align='center'>Create Question Answer</Typography>
            <form onSubmit={handleSubmit(handleQns)}>
              <Stack spacing={3}>


                <Autocomplete disablePortal id={getCatData?._id} options={getCatData} getOptionLabel={getCatData => getCatData?.cat_name}
                  onChange={(event, value) => {
                    cat_id = value
                  }}
                  onSelect={() => {
                    dispatch(getSubCategoryAction({ is_deleted: false, cat_id: cat_id?._id }))
                  }}
                  renderInput={(params) => <TextField {...register('cat_name')} {...params} label="Category Name" error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} />} />

                <Autocomplete disablePortal id={getSubCatData?._id} options={getSubCatData} getOptionLabel={getSubCatData => getSubCatData?.sub_cat_name}
                  onChange={(event, value) => {
                    sub_cat_id = value
                  }}
                  renderInput={(params) => <TextField {...register('sub_cat_name')} {...params} label="Sub Category Name" error={errors?.sub_cat_name?.message} helperText={errors?.sub_cat_name?.message} />} />

                <FormControl>
                  <FormLabel>Question Type</FormLabel>
                  <RadioGroup>
                    <Stack direction='row'>
                      <FormControlLabel value="text" {...register('type')} control={<Radio />} label="Text" />
                      <FormControlLabel value="image" {...register('type')} control={<Radio />} label="Image" />
                      <FormControlLabel value="audio"  {...register('type')} control={<Radio />} label="Audio" />
                      <FormControlLabel value="video"  {...register('type')} control={<Radio />} label="Video" />
                    </Stack>
                  </RadioGroup>
                  <FormHelperText error={errors?.type?.message}>{errors?.type?.message}</FormHelperText>
                </FormControl>
                {
                  (type === 'image' || type === 'audio' || type === 'video') &&
                  <>
                    <TextField label="Title" variant="outlined" placeholder='Title' {...register('title')} /*error={errors?.question?.message} helperText={errors?.question?.message}*/ />
                    <TextField label="File Upload" InputLabelProps={{ shrink: true }} type='file' accept="*" variant="outlined" id='file' placeholder='file'/* {...register('file')} */ onChange={handleFileUpload} error={errors?.file?.message} helperText={errors?.file?.message} />
                  </>
                }
                {fileLoader ? <CircularProgress /> : <>{
                  (type === 'image' || type === 'audio' || type === 'video') && file && <>
                    <Stack direction='center' style={{ diplay: 'flex', justifyContent: 'center', alingItems: 'center' }}>
                      {/* <Button onClick={() => { setFile('') }}>reupload</Button> */}
                      {type === 'image' && <img src={file} width="100px" alt="preview" />}
                      {type === 'audio' && <audio controls src={file} width="100px"></audio>}
                      {type === 'video' && <video autoplay="true" controls src={file} width="200px" alt="preview" />}

                      {/* <Button onClick={() => { window.open(file, '_blank'); }}>watch</Button> */}
                    </Stack>
                  </>
                }
                </>
                }


                <FormControl>
                  <FormLabel>Question Difficulty</FormLabel>
                  <RadioGroup>
                    <Stack direction='row'>
                      <FormControlLabel value="easy" {...register('difficulty')} control={<Radio />} label="Easy" />
                      <FormControlLabel value="medium" {...register('difficulty')} control={<Radio />} label="Medium" />
                      <FormControlLabel value="hard"  {...register('difficulty')} control={<Radio />} label="hard" />
                    </Stack>
                  </RadioGroup>
                  <FormHelperText error={errors?.type?.message}>{errors?.type?.message}</FormHelperText>
                </FormControl>


                <TextField label="Question" variant="outlined" placeholder='Question' {...register('question')} error={errors?.question?.message} helperText={errors?.question?.message} />

                <FormControl>
                  <FormLabel>Answer Type</FormLabel>
                  <RadioGroup onChange={handleAnswerType}>
                    <Stack direction='row'>
                      <FormControlLabel value="optional" {...register('answer_type')} control={<Radio />} label="Optional" />
                      <FormControlLabel value="MCQ"  {...register('answer_type')} control={<Radio />} label="Multiple Choice" />
                    </Stack>
                  </RadioGroup>
                  <FormHelperText error={errors?.answer_type?.message}>{errors?.answer_type?.message}</FormHelperText>
                </FormControl>

                {
                  (answer_type === 'optional' || answer_type === 'MCQ') &&
                  <>
                    <Grid container columnSpacing={1}>
                      <Grid item xs={5}>
                        <TextField value={answerOption.option} id="option" onChange={(e) => { changeOptInput(e) }} fullWidth label="Answer Options" variant="outlined" placeholder='Answer Options' />
                      </Grid>
                      <Grid item xs={1}>

                      </Grid>
                      <Grid item xs={4}>

                        <RadioGroup >
                          <Stack direction='row'>
                            <FormControlLabel value={false} checked={answerOption?.correct === false} control={<Radio />} onChange={() => { setAnswerOption({ ...answerOption, 'correct': false }) }} label="False" />
                            {console.log("singleTrue", ansArr, ansArr.some(item => item.correct === true))}
                            {/* {ansArr.some(item=>item.correct===true)?setOptionalTrue(false):setOptionalTrue(true)} */}
                            {answer_type === 'MCQ' && <FormControlLabel value={true} checked={answerOption?.correct === true} control={<Radio />} onChange={() => { setAnswerOption({ ...answerOption, 'correct': true }) }} label="True" />}
                            {answer_type === 'optional' && optionalTrue && <FormControlLabel value={true} checked={answerOption?.correct === true} control={<Radio />} onChange={() => { setAnswerOption({ ...answerOption, 'correct': true }) }} label="True" />}
                          </Stack>
                        </RadioGroup>


                      </Grid>
                      <Grid item xs={1}>
                        {answerOption.option && (answerOption.correct == false || answerOption.correct == true) && <IconButton color='primary' onClick={handleAddAnsOpt} ><AddCircleIcon fontSize='large' /></IconButton>}
                      </Grid>
                    </Grid>
                    <FormHelperText error={errors?.ansOps?.message}>{errors?.ansOps?.message}</FormHelperText>

                    <Stack spacing={1}>

                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ maxWidth: "10px" }} align="left">sno</TableCell>
                              <TableCell align="right">option</TableCell>
                              <TableCell align="right">correct</TableCell>
                              <TableCell align="right">delete</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>

                            {
                              ansArr.length > 0 ? ansArr.map((item, index) => {
                                return (


                                  <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                  >

                                    <TableCell align="right">{index + 1}</TableCell>
                                    <TableCell align="right">{item.option}</TableCell>
                                    <TableCell align="right">{item?.correct === true ? "true" : "false"}</TableCell>
                                    <TableCell align="right"><Button onClick={() => handleDelAnsOpt(index)}>delete</Button></TableCell>
                                  </TableRow>
                                )
                              }) : null
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>

                    </Stack>
                  </>
                }


                {errors?.duplicateAnsOpt?.message && <Typography variant='subtitle2' color='error'>{errors?.duplicateAnsOpt?.message}</Typography>}
                {errors?.singleOptionType?.message && <Typography variant='subtitle2' color='error'>{errors?.singleOptionType?.message}</Typography>}


                <Stack direction='row' spacing={2}>
                  <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Create Q & A'}</Button>
                  <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                </Stack>
              </Stack>
            </form>
          </Card>
        </Box>
      </Modal>

      <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
    </>
  )
}



CreateQuestionAnswer.propTypes = {
  state: PropTypes.any,
  setState: PropTypes.any
};
