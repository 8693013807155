import { DevTool } from '@hookform/devtools'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RestoreIcon from '@mui/icons-material/Restore'
import { Breadcrumbs, Button, Card, IconButton, Link, Menu, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
// import Drawer from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { changeCatSeqAction } from '../../redux/toolkit/category/changeCategorySequence'
import { getBannerAction } from '../../redux/toolkit/banner/getBanner'
import { getSingleBannerAction } from '../../redux/toolkit/banner/singleBanner';
import { useStyles } from '../../theme/theme'
import CreateBanner from './CreateBanner'
import DeleteBanner from './DeleteBanner'
import UpdateBanner from './UpdateBanner'
// import ReStoreCategory from './ReStoreCategory'



const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} href='/category'>Banner</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Banner</Typography>
    </Stack>
)




export default function Banner() {
    // state--->
    const { register, reset, control, handleSubmit } = useForm({});
    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData } = permission

    const dispatch = useDispatch()
    const banner = useSelector(state => state.getBanner)
    const { data: bannerData, loading: bannerDataLoading } = banner
    const catSeq = useSelector(state => state.changeCatSeq)
    const { status: catSeqStatus, message: catSeqMsg } = catSeq

    let can_delete = JSON.parse(localStorage.getItem("ss_active_usr"))?.settings?.can_delete
    let super_admin = permissionData?.role
    console.log("super_admin-->", super_admin);


    const [snack, setSnack] = useState(false)
    const [catMenu, setCatMenu] = useState(false)
    const [createBanner, setCreateBanner] = useState(false)
    const [updateBanner, setUpdateBanner] = useState(false)
    const [delBanner, setDelBanner] = useState(false)
    const [restoreBanner, setRestoreBanner] = useState(false)
    const [anchorCatMenu, setAnchorCatMenu] = useState()
    const [ativeFilter, setActiveFilter] = useState(false)
    const [pageSize, setPageSize] = useState(25);
    const [banner_id, setBanner_id] = useState('')
    const classes = useStyles();
    const [date, setDate] = useState({
        start: null,
        end: null,
    })
    const [isFilterArea, setIsFilterArea] = useState(false)



    const bannerDataGridColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 100,
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'name',
            headerName: 'Name',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
        },
        {
            field: 'image',
            headerName: 'Image',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <>
                        <img src={params?.row?.image} width="100px" alt="img" />

                    </>
                )
            },
        },
        // {
        //     field: 'sub_cat',
        //     headerName: 'Sub Category',
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     minWidth: 200,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <Typography>
        //                     {params?.row?.sub_cat?.length}
        //                 </Typography>
        //             </>
        //         )
        //     },
        // },
        // {
        //     field: 'link_qns',
        //     headerName: 'Total Questions',
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     minWidth: 200,
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <Typography>
        //                     {params?.row?.link_qns?.length}
        //                 </Typography>
        //             </>
        //         )
        //     },
        // },
        // {
        //     field: 'no_of_survey',
        //     headerName: 'Number Of Survey',
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     minWidth: 200,
        // },
        // {
        //     field: 'sequence_number',
        //     headerName: 'Sequence of Category',
        //     renderCell: (params) => {
        //         return (
        //             <>
        //                 <TextField
        //                     sx={{ my: 2 }} type='number' label="Category Sequence" size='small'
        //                     defaultValue={params?.row?.sequence}
        //                     variant="outlined"
        //                     placeholder='Category Sequence'
        //                     {...register(params?.row?._id)}
        //                 />
        //             </>
        //         )
        //     },
        //     headerClassName: 'super-app-theme--header',
        //     flex: 1,
        //     minWidth: 200,
        //     hide: ativeFilter
        // },

        {
            field: 'edit', headerName: 'Edit Banner',
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            setUpdateBanner(true);
                            dispatch(getSingleBannerAction(params?.row?._id))
                        }}><EditIcon color='primary' />
                    </IconButton>
                )
            },
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 150,
            hide: ativeFilter
        },
        {
            field: 'delete', headerName: 'Delete Banner', renderCell: () => {
                return (
                    <IconButton
                        onClick={() => {
                            setDelBanner(true);
                        }}><DeleteIcon color='error' />
                    </IconButton>
                )
            },
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 150,
            hide: ativeFilter

        },
        {
            field: 'revert',
            headerName: 'Revert Category',
            renderCell: () => {
                return (
                    <IconButton
                        onClick={() => {
                            setRestoreBanner(true);
                        }}
                    ><RestoreIcon color='success' />
                    </IconButton>
                )
            },
            headerClassName: 'super-app-theme--header',
            flex: 1,
            hide: !ativeFilter

        },
    ]


    // fn
    const handleCatChangeSeq = async (data) => {

        const changeCat = await dispatch(changeCatSeqAction(data))
        if (changeCat?.payload?.status) {
            await dispatch(getBannerAction({ is_deleted: false }))
            reset()
            setSnack(true)
        }

    }

    const handleclearFilter = () => {
        setActiveFilter(false)
        dispatch(getBannerAction({ is_deleted: false }))
    }

    const handleFilterData = () => {
        dispatch(getBannerAction({ is_deleted: false, s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
    }

    // useEffect
    useEffect(() => {
        dispatch(getBannerAction({ is_deleted: false }))
    }, [dispatch])



    return (
        <>
            <Helmet><title>Quiz Wall | Banner</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            {/* <Drawer /> */}
            <Body>

                
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                    {/* <Button variant="outlined" startIcon={<FilterListIcon />} onClick={() => { setIsFilterArea(!isFilterArea) }}>
                        filter by date
                    </Button> */}
                    <div>
                        
                    </div>
                    <Button variant="contained" color="btn" startIcon={<AddIcon />} onClick={() => setCreateBanner(true)}>
                        create Banner
                    </Button>
                </Stack>

                <Card sx={{ my: 2 }}>

                    {isFilterArea && <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ p: 2 }}>
                            <Typography color='#071D45'>Filter by Date</Typography>
                            <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                                <DatePicker
                                    label="Start Date"
                                    value={date?.start}
                                    onChange={(value) => setDate({ ...date, start: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    maxDate={date?.end}

                                />
                                <DatePicker
                                    label="End Date"
                                    value={date?.end}
                                    onChange={(value) => setDate({ ...date, end: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={date?.start}
                                />
                                <Box>
                                    <IconButton onClick={handleFilterData} color='#071D45'><FilterAltIcon /></IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </LocalizationProvider>}


                    <Stack direction={'row'} justifyContent='space-between' alignItems='center' sx={{ ml: 1 }} >
                        <Typography sx={{ my: 1 }} variant='h6'>Banners</Typography>
                        <Box sx={{ m: 2 }}>
                            {/* {(can_delete || super_admin == 'super_admin')
                                && */}
                            {/* <>
                                <IconButton color='error' onClick={handleclearFilter}><ClearAllIcon /></IconButton>
                                
                                <Button  startIcon={<FilterListIcon />} onClick={(event) => {
                                        setCatMenu(true);
                                        setAnchorCatMenu(event.currentTarget);
                                    }}>
                                    filter
                                </Button>
                            </> */}


                            {/* // } */}

                            <Menu
                                open={catMenu}
                                onClose={() => setCatMenu(false)}
                                anchorEl={anchorCatMenu}
                            >
                                <MenuItem selected={ativeFilter}
                                    onClick={() => {
                                        setCatMenu(false)
                                        dispatch(getBannerAction({ is_deleted: true }))
                                        setActiveFilter(true)
                                    }} >Deleted</MenuItem>
                                <MenuItem selected={!ativeFilter}
                                    onClick={() => {
                                        setCatMenu(false)
                                        dispatch(getBannerAction({ is_deleted: false }))
                                        setActiveFilter(false)
                                    }}>Non Deleted</MenuItem>
                            </Menu>
                        </Box>
                    </Stack>

                    {/* table list */}
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#E7F7FF',
                            color: '#000',
                        },
                    }} elevation={0}>


                        <DataGrid
                            className={classes.root}
                            sx={{
                                border: 0,
                                "& .MuiDataGrid-row:hover": {
                                    backgroundColor: "#E7F7FF"
                                    // color: "red"
                                  }
                            }}
                            getRowHeight={() => 'auto'}
                            autoHeight
                            rows={bannerData}
                            columns={bannerDataGridColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={bannerDataLoading ? <LoadingUI /> : false}
                            onCellClick={(params) => {
                                setBanner_id(params?.row?._id)
                            }}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,

                            }}

                        />
                    </Paper>
                </Card>

                {/* {!ativeFilter && bannerData?.length ? <Button onClick={handleSubmit(handleCatChangeSeq)} sx={{ my: 2 }} variant='contained' color='error'>Change Category Sequence</Button> : null} */}

                {/* crud */}
                 <CreateBanner state={createBanner} setState={setCreateBanner} />
                <UpdateBanner banner_id={banner_id} state={updateBanner} setState={setUpdateBanner} />
                <DeleteBanner banner_id={banner_id} state={delBanner} setState={setDelBanner} />
              {/*  <ReStoreCategory banner_id={banner_id} state={restoreCat} setState={setRestoreBanner} resetCatSeq={reset} /> */}
            </Body >


            <SnackBarUI state={snack} setState={setSnack} status={catSeqStatus} message={catSeqMsg} />
            <DevTool control={control} />
        </>
    )
}
