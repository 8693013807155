import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Card, CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import Style from "../../css/pages/auth/Login.module.scss";
import { loginUserAction } from '../../redux/toolkit/auth/loginUser';


// schema
const loginSchema = yup.object({
    email: yup.string().required('Email is required.'),
    password: yup.string().required('Password is required.'),
}).required();


export default function Login() {
    // state
    const { register, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(loginSchema) });

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const loginSelector = useSelector(state => state.loginUser)
    const { status, message, loading } = loginSelector




    const [snack, setSnack] = useState(false)
    const [showPassword, setShowPassword] = useState(false)

    // fn
    const loginFunc = async (data) => {
        const loginData = await dispatch(loginUserAction(data))
        if (loginData?.payload?.status == 200) {
            localStorage.setItem("ss_active_usr", JSON.stringify(loginData?.payload?.data))
            localStorage.setItem("ss_token", JSON.stringify(loginData?.payload?.token))
            setSnack(true)
            navigate('/');
            window.location.reload()
            setSnack(true)
            console.log("hello world if")

        }else{
            console.log("hello world else")
            setSnack(true)
        }
    }

    // useEffect(() => {
    //     if (localStorage.getItem('token')) {
    //         navigate('/survey')
    //     }
    // }, [])


    return (
        <>
            <Box className={Style.login}>
                <img src='/assets/img/logo.png' />
                <Typography sx={{ mb: 3 }} variant='h6'>Quiz Wall Support Panel</Typography>
                <Card sx={{ width: 600, p: 5 }}>
                    <Typography variant='h5' sx={{ mb: 5 }} align='center'>LOGIN</Typography>
                    <form onSubmit={handleSubmit(loginFunc)}>
                        <Stack spacing={3}>
                            <TextField type='email' label="Email" variant="outlined" placeholder='Email' {...register('email')} error={errors?.email?.message} helperText={errors?.email?.message} />
                            <FormControl variant="outlined">
                                <InputLabel>Password</InputLabel>
                                <OutlinedInput
                                    {...register('password')} error={errors?.password?.message}
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {!showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                                {/* <FormHelperText error="error">{errors?.password?.message}</FormHelperText> */}
                            </FormControl>
                            {/* <Link to='/register' style={{ textDecoration: 'none' }}><Typography variant="subtitl1">Create new account ?</Typography></Link> */}

                            <Button variant='contained' disabled={loading} fullWidth type='submit'>{loading ? <CircularProgress sx={{ color: '#fff' }} /> : 'Login'}</Button>
                        </Stack>
                    </form>
                </Card>
            </Box>

            {snack && <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />}
            {/* <DevTool control={control} /> */}
        </>
    )
}
