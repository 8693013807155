import CachedIcon from '@mui/icons-material/Cached'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Card, IconButton, Link, Paper, Stack, TableContainer, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import Appbar from '../../components/Appbar'
import DrawerUI from '../../components/Drawer'
import Body from '../../layout/Body'
import { getSinglePublisherAction } from '../../redux/toolkit/publisher/publisherDetails'
import { useStyles } from '../../theme/theme'

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#fff' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/publisher'>Publisher</Link>
            <Link underline="hover" sx={{ color: "#fff" }} color='inherit' href='/publisher-detail'>Publisher details</Link>
        </Breadcrumbs >
        <Typography variant='h5'>Publisher Details</Typography>
    </Stack >
)


export default function MissionDetails() {
    // state
    const dispatch = useDispatch()
    const mission_id = useLocation()?.search?.split("?")[1]

    const getMissionLinkedQns = useSelector(state => state.getMissionLinkedQns)
    const { data: getMissionLinkedQnsData, loading: getMissionLinkedQnsLoading } = getMissionLinkedQns
    const getSinglePubliser = useSelector(state => state.getSinglePubliser)
    const { data: getUserMissionDetailsData, loading: getUserMissionDetailsLoading } = getSinglePubliser



    // console.log("getMissionLinkedQnsData", getMissionLinkedQnsData)

    const [pageSize, setPageSize] = useState(25);
    const classes = useStyles();


    const missionDetailsColumn = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            minWidth: 300,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?._id}</Typography>
                )
            },
        },
        {
            field: 'app_name',
            headerName: 'App Name',
            flex: 1,
            minWidth: 170,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.app_name}</Typography>
                )
            },
        },
        {
            field: 'postback_url',
            headerName: 'Postback url',
            flex: 1,
            minWidth: 350,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.postback_url}</Typography>
                )
            },
        },
        {
            field: 'platform',
            headerName: 'Platform',
            flex: 1,
            minWidth: 300,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.platform}</Typography>
                )
            },
        },
        {
            field: 'exchange_rate',
            headerName: 'Exchange Rate',
            flex: 1,
            minWidth: 170,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.exchange_rate}</Typography>
                )
            },
        },
        // {
        //     field: 'publisher_id.publisher_name',
        //     headerName: 'Publisher Name',
        //     flex: 1,
        //     minWidth: 170,
        //     headerClassName: 'super-app-theme--header',
        //     renderCell: (params) => {
        //         return (
        //             <Typography>{params?.row?.publisher_id?.publisher_name}</Typography>
        //         )
        //     },
        // },
    ]


    // fn
    // const handleRefresh = () => {
    //     dispatch(getUserMissionDetailsAction({ mission_id: mission_id }))
    // }

    // useEffect
    useEffect(() => {
        dispatch(getSinglePublisherAction(mission_id))
        // dispatch(getUserMissionDetailsAction(mission_id))
    }, [])

    return (
        <>
            <Helmet><title>Quick mission | Mission-detail</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>

               


                {/* <Card sx={{ mt: 2, p: 2 }}>
                    <Typography variant='h6'>Get Complete Mission By Users</Typography>
                    <Stack direction={'row'} alignItems='center' spacing={2}>
                        {noOfQualifiedMissionLoading ? <LoadingUI /> : <Typography variant='h4' color='primary'>{noOfQualifiedMissionData?.complete_mission_users || "--"}</Typography>}
                    </Stack>

                    <Button sx={{ mt: 1 }} onClick={handlenoOfQualifiedMission} variant='contained'>Get Users Data</Button>
                </Card> */}



                <Card sx={{ mt: 2, }}>
                    <TableContainer sx={{ width: '100%' }}>
                        <Stack direction={'row'} justifyContent='space-between' alignItems={'center'}>
                            <Typography sx={{ ml: 3, my: 2 }} variant='h6'>Publisher Details</Typography>
                            {/* <IconButton sx={{ mr: 3, my: 2 }} onClick={handleRefresh}><CachedIcon /></IconButton> */}
                        </Stack>

                        <Paper sx={{
                            bgcolor: '#fff',
                            '& .super-app-theme--header': {
                                bgcolor: '#071D45',
                                color: '#fff',
                            },
                        }} elevation={0}>
                            <DataGrid
                                className={classes.root}
                                getRowHeight={() => 'auto'}
                                sx={{ border: 0 }}
                                autoHeight
                                rows={getUserMissionDetailsData}
                                columns={missionDetailsColumn}
                                getRowId={(row) => row._id}
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                                pagination
                                pageSize={pageSize}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[25, 50, 100]}
                                disableColumnSelector
                                loading={getUserMissionDetailsLoading ? <LoadingUI /> : false}
                                components={{
                                    NoRowsOverlay: () => <NoDataFound />,
                                    Toolbar: GridToolbar,
                                }}

                            />
                        </Paper>
                    </TableContainer>
                </Card>

            </Body>
        </>
    )
}
