import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CircularProgress, Modal, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import "../../css/ui/Modal.scss";
import { getSubCategoryAction } from '../../redux/toolkit/sub-category/getSubCategory';
import { updateSubCategoryAction } from '../../redux/toolkit/sub-category/updateSubCategory';
import PropTypes from 'prop-types';

// schema
const catSchema = yup.object({
    sub_cat_name: yup.string().required('Sub Category name is required.'),
}).required();

export default function UpdateSubCategory({ sub_cat_id, state, setState }) {
    // state
    const dispatch = useDispatch()

    const updateSubCat = useSelector(state => state.updateSubCategory)
    const { status, message, loading } = updateSubCat
    const snglSubCat = useSelector(state => state.getSingleSubCategory)
    const { data: { sub_cat_name } } = snglSubCat

    const { register, reset, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(catSchema), defaultValues: {
            sub_cat_name: sub_cat_name
        }
    });

    const [snack, setSnack] = useState(false)

    // fn
    const handleCategory = async (data) => {
        const updateSubCatData = await dispatch(updateSubCategoryAction({ ...data, sub_cat_id: sub_cat_id }))
        if (updateSubCatData?.payload?.status == 200) {
            dispatch(getSubCategoryAction({ is_deleted: false }))
        }
        setSnack(true)
        setState(false)
    }

    const handleClose = function () {
        setState(false)
    }

    // useEffect
    useEffect(() => {
        let defaultValues = {};
        defaultValues.sub_cat_name = sub_cat_name;
        reset({ ...defaultValues });
    }, [sub_cat_name])



    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 400, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Update Category</Typography>
                        <form onSubmit={handleSubmit(handleCategory)}>
                            <Stack spacing={3}>
                                {sub_cat_name && <TextField label="Sub Category Name" variant="outlined" placeholder='Sub Category Name'  {...register('sub_cat_name')} error={errors?.sub_cat_name?.message} helperText={errors?.sub_cat_name?.message} />}
                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Update Sub Category'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose} >Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>



            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            <DevTool control={control} />
        </>
    )
}

UpdateSubCategory.propTypes = {
    sub_cat_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};
