import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const deleteQuestionAnswerAction = createAsyncThunk(
    'delete-question-answer',
    async (data, { rejectWithValue }) => {
        console.log("data==>",data)
        try {
            const res = await axios.patch(`/admins/api/delete-restore-question-answer?qns_id=${data.qns_id}&is_delete=${data.is_delete}`)
            
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const deleteQuestionAnswerSlice = createSlice({
    name: 'delete-question-answer',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(deleteQuestionAnswerAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(deleteQuestionAnswerAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(deleteQuestionAnswerAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const deleteQuestionAnswerReducer = deleteQuestionAnswerSlice.reducer