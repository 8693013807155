
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { loading: false, data: [], error: "", message: "" }


export const singleSupportDataAction = createAsyncThunk(
    'single-support-data',
    async (data = null, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/admins/api/support-knowladge/${data}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const singleSupportDataSlice = createSlice({
    name: 'single-support-data',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(singleSupportDataAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(singleSupportDataAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.message = action?.payload?.message
            })
            builder.addCase(singleSupportDataAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.message = action?.payload?.message
            })
        },

})



export const singleSupportDataReducer = singleSupportDataSlice.reducer    






// router.get("/support-knowladges/:page/:limit/:is_deleted", new SupportKnowladge().supportKnowladgeList)
// router.get("/support-knowladge/:id", new SupportKnowladge().supportKnowladgeDetail)
// router.post("/support-knowladge", new SupportKnowladge().createSupportKnowladge)
// router.put("/support-knowladge", new SupportKnowladge().updateSupportKnowladge)
// router.delete("/support-knowladge", new SupportKnowladge().deleteAndResoteSupportKnowladge)