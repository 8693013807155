import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Autocomplete, Box, Button, Card, Chip, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { createDefaultQnsAction } from '../../redux/toolkit/question-answer/createDefaultQns';
import { getAllQuestionAnswerAction } from '../../redux/toolkit/question-answer/getAllQuestionAnswer';


// schema
const qns_ans_schema = yup.object({
    question: yup.string().required('Question is required.'),
    // question_reletive_cat: yup.string().required('Question relative cateogry is required.'),
    answer_type: yup.string(),
}).required();

export default function CreateDefaultQns({ state, setState }) {
    // state
    const dispatch = useDispatch()
    const createQns = useSelector(state => state.createDefaultQns)
    const { status, message } = createQns


    const { register, setError, watch, setValue, handleSubmit, formState: { errors } } = useForm({ resolver: yupResolver(qns_ans_schema) });

    const [snack, setSnack] = useState(false)
    const [qnsType, setQnsType] = useState(null)
    const [ansArr, setAnsArr] = useState([])
    const [correct_answer, setCorrect_answer] = useState([])
    const [correctAnswerSelect, setCorrectAnswerSelect] = useState({})

    const [answerType, setAnswerType] = useState(null)
    const [answerOption, setAnswerOption] = useState('')


    // fn
    const handleClose = function () {
        setState(false)
    }

    // fn
    const handleCreateDefaultQns = async (data) => {


        if (ansArr?.length == 1) {
            return setError('singleOptionType', { type: 'custom', message: 'Answer option can not be empty. and length must be atleast 2.' });
        }

        const createDefaultQns = await dispatch(createDefaultQnsAction({ ...data, ans_options: ansArr, correct_answer: correct_answer }))
        if (createDefaultQns?.payload?.status == 201) {
            await dispatch(getAllQuestionAnswerAction({ is_deleted: false }))
            setState(false)
        }
        setSnack(true)
    }

    const handleAnsChange = (event, value) => {
        setAnswerType(value)
        if (value == 'AgreeDisagree') setAnsArr(['Agree', 'Disagree'])
        else if (value == 'TrueFalse') setAnsArr(['True', 'False'])
        else if (value == 'YesNo') setAnsArr(['Yes', 'No'])
    }

    const handleAnsTypeOnChange = (event, value) => {
        setCorrect_answer([value])
    }

    const handleAddAnsOpt = () => {
        const foundDuplicate = ansArr.find(element => element == answerOption);
        if (foundDuplicate !== undefined) return
        setAnsArr(prev => [...prev, answerOption])
        setAnswerOption('')
    }

    const handleAnswerType = (event, value) => {
        if (value == "optional" || value == "MCQ") {
            setCorrectAnswerSelect({})
            setCorrect_answer([])
            setAnsArr([])
        }
    }

    const handleDelAnsOpt = (i) => {
        setAnsArr((current) => current.filter((chips, index) => { return index !== i; }))
        setAnswerOption('')
    }

    const handleCorrectAnswer = (item, index) => {
        setCorrectAnswerSelect({ [index]: true })
        setCorrect_answer(item)
    }


    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 500, p: 5 }}>
                        <form onSubmit={handleSubmit(handleCreateDefaultQns)}>
                            <Typography variant='h5' sx={{ mb: 5 }} align='center'>Create Default Question Answer</Typography>
                            <Stack spacing={2}>
                                <Autocomplete
                                    disablePortal
                                    options={['Location', 'Universal']}
                                    onChange={(event, item) => {

                                        setValue('question_reletive_cat', item, { shouldValidate: true })
                                        setQnsType(item)
                                        if (item == "Location") {
                                            setValue('question', "What is your location ?", { shouldValidate: true })
                                        } else {
                                            setValue('question', "", { shouldValidate: true })
                                        }
                                    }}
                                    renderInput={(params) => <TextField
                                        {...params} label="Question Relitive Cateogry"
                                        {...register('question_reletive_cat')}
                                        error={errors?.question_reletive_cat?.message}
                                        helperText={errors?.question_reletive_cat?.message}
                                    />}
                                />
                                <TextField
                                    fullWidth
                                    disabled={watch('question_reletive_cat') == 'Location'}
                                    label="Question"
                                    variant="outlined"
                                    placeholder='Question'
                                    {...register('question')}
                                    error={errors?.question?.message}
                                    helperText={errors?.question?.message}
                                />

                                {qnsType == "Universal"
                                    ? <>
                                        <FormControl>
                                            <FormLabel>Answer Type</FormLabel>
                                            <RadioGroup onChange={handleAnswerType}>
                                                <Stack direction='row'>
                                                    <FormControlLabel value="conditional" {...register('answer_type')} control={<Radio />} label="Condtional" />
                                                    <FormControlLabel value="optional" {...register('answer_type')} control={<Radio />} label="Optional" />
                                                    <FormControlLabel value="MCQ"  {...register('answer_type')} control={<Radio />} label="Multiple Choise" />
                                                </Stack>
                                                <Typography variant='subtitle2' color='error'>{errors?.answer_type?.message}</Typography>
                                            </RadioGroup>
                                        </FormControl>

                                        {
                                            (watch('answer_type') == 'optional' || watch('answer_type') == 'MCQ')
                                                ? <>
                                                    <Grid container columnSpacing={1}>
                                                        <Grid item xs={10}>
                                                            <TextField value={answerOption} onChange={(event) => setAnswerOption(event.target.value)} fullWidth label="Answer Options" variant="outlined" placeholder='Answer Options' />
                                                        </Grid>
                                                        <Grid item xs={1}>
                                                            <IconButton color='primary' onClick={handleAddAnsOpt} ><AddCircleIcon fontSize='large' /></IconButton>
                                                        </Grid>
                                                    </Grid>
                                                    <FormHelperText error={errors?.ansOps?.message}>{errors?.ansOps?.message}</FormHelperText>

                                                    <Stack spacing={1}>
                                                        {ansArr.map((items, index) => {
                                                            return (
                                                                <Stack direction='row' key={index} alignItems={'center'}>
                                                                    <Chip variant="outlined" key={index} label={items} onDelete={() => handleDelAnsOpt(index)} />
                                                                    <Button onClick={() => handleCorrectAnswer(items, index)}><Typography sx={{ fontSize: ".8rem" }} color={correctAnswerSelect[index] ? 'success' : 'error'}>Correct Answer</Typography></Button>
                                                                </Stack>
                                                            )
                                                        })}
                                                    </Stack>
                                                </>
                                                : <>
                                                    <FormControl>
                                                        <FormLabel>Answer Options</FormLabel>
                                                        <RadioGroup onChange={handleAnsChange}>
                                                            <Stack direction='row'>
                                                                <FormControlLabel value='AgreeDisagree' control={<Radio />} label="Agree Disagree" />
                                                                <FormControlLabel value='TrueFalse' control={<Radio />} label="True False" />
                                                                <FormControlLabel value='YesNo' control={<Radio />} label="Yes No" />

                                                            </Stack>
                                                            <Typography variant='subtitle2' color='error'>{errors?.ansOptError?.message}</Typography>
                                                        </RadioGroup>
                                                    </FormControl>


                                                    <FormControl>
                                                        <FormLabel>Correct Answer</FormLabel>
                                                        <RadioGroup onChange={handleAnsTypeOnChange}>
                                                            {answerType == "TrueFalse"
                                                                ? <Stack direction='row'>
                                                                    <FormControlLabel value='True' control={<Radio />} label="True" />
                                                                    <FormControlLabel value='False' control={<Radio />} label="False" />
                                                                </Stack>
                                                                : null
                                                            }
                                                            {answerType == "AgreeDisagree"
                                                                ? <Stack direction='row'>
                                                                    <FormControlLabel value='Agree' control={<Radio />} label="Agree" />
                                                                    <FormControlLabel value='Disagree' control={<Radio />} label="Disagree" />
                                                                </Stack>
                                                                : null
                                                            }

                                                            {answerType == "YesNo"
                                                                ? <Stack direction='row'>
                                                                    <FormControlLabel value='Yes' control={<Radio />} label="Yes" />
                                                                    <FormControlLabel value='No' control={<Radio />} label="No" />
                                                                </Stack>
                                                                : null
                                                            }
                                                            {errors?.correct_answer?.message && <Typography variant='subtitle2' color='error'>{errors?.correct_answer?.message}</Typography>}
                                                            {errors?.ansOptError?.message && <Typography variant='subtitle2' color='error'>{errors?.ansOptError?.message}</Typography>}
                                                        </RadioGroup>
                                                    </FormControl>
                                                </>
                                        }

                                    </>
                                    : null
                                }

                                {errors?.duplicateAnsOpt?.message && <Typography variant='subtitle2' color='error'>{errors?.duplicateAnsOpt?.message}</Typography>}
                                {errors?.singleOptionType?.message && <Typography variant='subtitle2' color='error'>{errors?.singleOptionType?.message}</Typography>}


                            </Stack>
                            <Stack direction='row' spacing={2} sx={{ mt: 2 }}>
                                <Button variant='contained' type='submit'>Create Question</Button>
                                <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>

            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}


CreateDefaultQns.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any
};