import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Card, Link, Paper, Stack, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import Appbar from '../../components/Appbar'
import DrawerUI from '../../components/Drawer'
import Body from '../../layout/Body'
import { getSuperAdminLogsAction } from '../../redux/toolkit/admin/getSuperAdminLogs'
import { useStyles } from '../../theme/theme'

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/admin'>Admin</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/super-admin-log'>Super Admin Logs</Link>
        </Breadcrumbs >
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Super Admin</Typography>
    </Stack >
)



export default function SuperAdminLog() {
    // state
    const dispatch = useDispatch()
    const getSuperAdminLogsSelector = useSelector(state => state.getSuperAdminLogs)
    const { data, loading } = getSuperAdminLogsSelector

    const [pageSize, setPageSize] = useState(25);
    const classes = useStyles();

    const superAdminColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            minWidth: 300,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            minWidth: 300,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'method',
            headerName: 'Method',
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{JSON.parse(params?.row?.logs)?.method}</Typography>
                )
            },
        },
        {
            field: 'role',
            headerName: 'Role',
            flex: 1,
            minWidth: 120,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.role}</Typography>
                )
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{JSON.parse(params?.row?.logs)?.status}</Typography>
                )
            },
        },
        {
            field: 'url',
            headerName: 'Url',
            flex: 1,
            minWidth: 500,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{JSON.parse(params?.row?.logs)?.url}</Typography>
                )
            },
        },
        {
            field: 'date',
            headerName: 'Date',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.created_at?.split("T")?.[0]}</Typography>
                )
            },

        },
        {
            field: 'time',
            headerName: 'Time',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.created_at?.split("T")?.[1]?.split(".")?.[0]}</Typography>
                )
            },
        },
    ]


    // use effect
    useEffect(() => {
        dispatch(getSuperAdminLogsAction())
    }, [dispatch])


    return (
        <>
            <Helmet>
                <title>Quizwall | Super admin log</title>
            </Helmet>


            <Appbar breadcrumb={breadcrumb} />
            <DrawerUI />
            <Body>
                {/* table list */}

                <Card sx={{ my: 2 }}>
                    <Stack direction={'row'} alignItems='center' sx={{ ml: 1 }} >
                        <Typography sx={{ my: 1 }} variant='h6'>My Activity Logs List</Typography>
                    </Stack>

                    {/* table list */}
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#071D45',
                            color: '#fff',
                        },
                    }} elevation={0}>


                        <DataGrid
                            className={classes.root}
                            getRowHeight={() => 'auto'}
                            sx={{ border: 0 }}
                            rows={data}
                            autoHeight
                            columns={superAdminColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={loading ? <LoadingUI /> : false}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,
                            }}

                        />
                    </Paper>
                </Card>

            </Body>
        </>
    )
}
