import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SnackBarUI from '../../comman/SnackBarUI'
import { getSubCategoryAction } from '../../redux/toolkit/sub-category/getSubCategory'
import { restoreSubCategoryAction } from '../../redux/toolkit/sub-category/restoreSubCat'
import PropTypes from 'prop-types';


export default function ReStoreSubCategory({ sub_cat_id, state, setState, resetSubCat }) {
    // state
    const dispatch = useDispatch()
    const restoreSubCat = useSelector(state => state.restoreSubCategory)
    const { status, message } = restoreSubCat

    const [snack, setSnack] = useState(false)

    // fn
    const handleClose = () => {
        setState(false)
    }

    const delCatHandle = async () => {
        const restoreSubCat = await dispatch(restoreSubCategoryAction({ sub_cat_id: sub_cat_id }))
        if (restoreSubCat.payload?.status == 200) {
            await dispatch(getSubCategoryAction({ is_deleted: true }))
            resetSubCat()
        }
        setSnack(true)
        setState(false)
    }

    return (
        <>
            <Dialog open={state} onClose={handleClose}>
                <DialogTitle>Restore item request !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to restore the selected item ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={delCatHandle} autoFocus> Agree</Button>
                    <Button onClick={handleClose}>Disagree</Button>
                </DialogActions>
            </Dialog>


            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>

    )
}

ReStoreSubCategory.propTypes = {
    sub_cat_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any,
    resetSubCat: PropTypes.any
};
