import { Box, Card,CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached';
import { attemptAction } from '../../redux/toolkit/dashbaord/attempt';
import TrackChangesSharpIcon from '@mui/icons-material/TrackChangesSharp';
import Grid from '@mui/material/Grid';
import Dropdown from './Dropdown';

export default function MissionAttempts() {
    // state
    const dispatch = useDispatch()
    const totalAttemptSelector = useSelector(state => state.totalAttempt)
    const { data, loading } = totalAttemptSelector

    // fn
    const handleRefeash = () => {
        dispatch(attemptAction())
    }

    // use effect
    useEffect(() => {
        dispatch(attemptAction())
    }, [dispatch])

    return (
        <Card sx={{ p: 2, color: '#0C6A96' }} >
            
            <Stack direction='row' justifyContent='space-between' >
                <TrackChangesSharpIcon color='#0C6A96' />
                <Typography sx={{ color: '#5E6C8A', fontFamily: 'Montserrat,sans-serif', fontStyle: 'normal' }} variant='subtitle2'>Mission Attempts</Typography>
                <IconButton sx={{ color: '#5E6C8A' }} onClick={handleRefeash}><CachedIcon /></IconButton>
            </Stack>
            <Stack direction='row' justifyContent='flex-end'> 
            {/* <Dropdown /> */}
                
            </Stack>

            {/* <Grid container>
                <Grid item xs={2}>
                    <Dropdown />
                </Grid>
            </Grid> */}
            
            

            {
                loading
                    ? <CircularProgress />
                    : <Box>
                        <Typography variant='h3' color='primary' sx={{ fontWeight: 600, color: '#071D45' }}>{data?.no_of_mission_attempted ? data?.no_of_mission_attempted : "---"}</Typography>
                    </Box>
            }
        </Card>
    )
}
