import { DevTool } from '@hookform/devtools'
import ClearAllIcon from '@mui/icons-material/ClearAll'
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RestoreIcon from '@mui/icons-material/Restore'
import { Breadcrumbs, Button, Card, IconButton, Link, Menu, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
// import Drawer from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { changeCatSeqAction } from '../../redux/toolkit/category/changeCategorySequence'
import { getCategoryAction } from '../../redux/toolkit/category/getCategory'
import { getSingleCategoryAction } from '../../redux/toolkit/category/getSingleCategory'
import { useStyles } from '../../theme/theme'
// import CreateCategory from './CreateCategory'
// import DeleteCategory from './DeleteCategory'
// import ReStoreCategory from './ReStoreCategory'
// import UpdateCategory from './UpdateCategory'
import Config from './Config'



const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} href='/config-metas'>Config</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Configmeta</Typography>
    </Stack>
)




export default function Index() {
    // state--->
    const { register, reset, control, handleSubmit } = useForm({});
    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData } = permission

    const dispatch = useDispatch()
    const cat = useSelector(state => state.getCategory)
    const { data: catData, loading: catDataLoading } = cat
    const catSeq = useSelector(state => state.changeCatSeq)
    const { status: catSeqStatus, message: catSeqMsg } = catSeq

    let can_delete = JSON.parse(localStorage.getItem("ss_active_usr"))?.settings?.can_delete
    let super_admin = permissionData?.role
    console.log("super_admin-->", super_admin);


    const [snack, setSnack] = useState(false)
    const [catMenu, setCatMenu] = useState(false)
    const [createCat, setCreateCat] = useState(false)
    const [updateCat, setUpdateCat] = useState(false)
    const [delCat, setDelCat] = useState(false)
    const [restoreCat, setRestoreCat] = useState(false)
    const [anchorCatMenu, setAnchorCatMenu] = useState()
    const [ativeFilter, setActiveFilter] = useState(false)
    const [pageSize, setPageSize] = useState(25);
    const [cat_id, seCat_id] = useState('')
    const classes = useStyles();
    const [date, setDate] = useState({
        start: null,
        end: null,
    })
    const [isFilterArea, setIsFilterArea] = useState(false)



    


    // fn
    const handleCatChangeSeq = async (data) => {

        const changeCat = await dispatch(changeCatSeqAction(data))
        if (changeCat?.payload?.status) {
            await dispatch(getCategoryAction({ is_deleted: false }))
            reset()
            setSnack(true)
        }

    }

    const handleclearFilter = () => {
        setActiveFilter(false)
        dispatch(getCategoryAction({ is_deleted: false }))
    }

    const handleFilterData = () => {
        dispatch(getCategoryAction({ is_deleted: false, s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
    }

    // useEffect
    useEffect(() => {
        dispatch(getCategoryAction({ is_deleted: false }))
    }, [dispatch])



    return (
        <>
            <Helmet><title>Quiz Wall | Config</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            {/* <Drawer /> */}
            <Body>

                
              

                <Card sx={{ my: 2 }}>

                    


                    {/* <Stack direction={'row'} justifyContent='space-between' alignItems='center' sx={{ ml: 1 }} >
                        <Typography sx={{ my: 1 }} variant='h6'>Config Meta</Typography>
                        
                    </Stack> */}

                    {/* table list */}
                    <Config/>
                    
                </Card>


                {/* crud */}
                {/* <CreateCategory state={createCat} setState={setCreateCat} />
                <UpdateCategory cat_id={cat_id} state={updateCat} setState={setUpdateCat} />
                <DeleteCategory cat_id={cat_id} state={delCat} setState={setDelCat} />
                <ReStoreCategory cat_id={cat_id} state={restoreCat} setState={setRestoreCat} resetCatSeq={reset} /> */}
            </Body >


            <SnackBarUI state={snack} setState={setSnack} status={catSeqStatus} message={catSeqMsg} />
            <DevTool control={control} />
        </>
    )
}
