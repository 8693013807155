import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Grid, Link, Stack, Typography } from '@mui/material'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import Appbar from '../../components/Appbar'
// import DrawerUI from '../../components/Drawer'
import Body from '../../layout/Body'
import Activity from './Activity'
import UserInfo from './UserInfo'


const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/profile'>Profile</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Profile</Typography>
    </Stack>
)


export default function Profile() {

    return (
        <>
            <Helmet><title> Quick survey | Profile </title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            {/* <DrawerUI /> */}
            <Body>

                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <UserInfo />
                    </Grid>
                </Grid>


                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                        <Activity />
                    </Grid>
                </Grid>


            </Body>
        </>
    )
}
