
import { addPermissionReducer } from './admin/addPermission'
import { blockAndUnBlockAdminReducer } from './admin/blockAndUnBlockAdmin'
import { getAdminSupportLogsReducer } from './admin/getAdminLogs'
import { getAdminSupportReducer } from './admin/getAdminSupports'
import { getSingleAdminSupportReducer } from './admin/getSingleAdminSupports'
import { getSuperAdminLogsReducer } from './admin/getSuperAdminLogs'
import { analyticsUserDetailsReducer } from './analytics/analyticsUserDetails'
// import { getAnalyticsMissionDataReducer } from './analytics/'
import { noOfDropedMissionReducer } from './analytics/noOfDropedMission'
import { noOfParticipentReducer } from './analytics/noOfParticipent'
import { noOfQualifiedMissionReducer } from './analytics/noOfQualifiedMission'
import { noOfMissionAttemptsReducer } from './analytics/noOfMissionAttempts'
import { noOfMissionImpressionGenratedReducer } from './analytics/noOfMissionImpressionGenrated'
import { loginUserReducer } from './auth/loginUser'
import { registerUserReducer } from './auth/registerUser'
import { changeCatSeqReducer } from './category/changeCategorySequence'
import { createCategoryReducer } from './category/createCategory'
import { deleteCategoryReducer } from './category/deleteCategory'
import { getCategoryReducer } from './category/getCategory'
import { getSingleCategoryReducer } from './category/getSingleCategory'
import { restoreCategoryReducer } from './category/restoreCat'
import { updateCategoryReducer } from './category/updateCategory'
import { diffentPlatformPublisherReducer } from './dashbaord/diffentPlatformPublisher'
import { getPermissionAndRoleReducer } from './dashbaord/getPermissionAndRole'
import { missionStatsReducer } from './dashbaord/missionStats'
import { toPublisherReducer } from './dashbaord/toPublisher'
import { totalRevenueReducer } from './dashbaord/totalRevenue'
import { userDayWiseReducer } from './dashbaord/userDayWise'
import { uplodImageReducer } from './image/uplodImage'
import { noOfDeletedMissionReducer } from './menu/noOfDeletedMission'
import { noOfLiveMissionReducer } from './menu/noOfLiveMission'
import { approvedPaymentReducer } from './payments/approvedPayment'
import { creditPaymentReducer } from './payments/creditPayment'
import { paymentRequestsReducer } from './payments/paymentRequests'
import { getLogsByIdyReducer } from './profile/getLogsById'
import { getPublisherReducer } from './publisher/getPublisher'
import { verifyPublisherReducer } from './publisher/verifyPublisher'
import { createDefaultQnsReducer } from './question-answer/createDefaultQns'
import { createQuestionAnswerReducer } from './question-answer/createQuestionAnswer'
import { deleteQuestionAnswerReducer } from './question-answer/deleteQuestionAnswer'
import { getAllQuestionAnswerReducer } from './question-answer/getAllQuestionAnswer'
import { getSingleQuestionAnswerReducer } from './question-answer/getSingleQuestionAnswer'
import { restoreQnsReducer } from './question-answer/restoreQns'
import { updateQuestionAnswerReducer } from './question-answer/updateQuestionAnswer'
import { uploadQnsAnsReducer } from './question-answer/uploadQnsAns'
import { changeSubCatSeqReducer } from './sub-category/changeSubCategorySequence'
import { createSubCategoryReducer } from './sub-category/createSubCategory'
import { deleteSubCategoryReducer } from './sub-category/deleteSubCategory'
import { getSingleSubCategoryReducer } from './sub-category/getSingleSubCategory'
import { getSubCategoryReducer } from './sub-category/getSubCategory'
import { restoreSubCategoryReducer } from './sub-category/restoreSubCat'
import { updateSubCategoryReducer } from './sub-category/updateSubCategory'
import { createMissionReducer } from './mission/createMission'
import { deleteMissionReducer } from './mission/deleteMission'
import { dropedCompleteMissionUsersReducer } from './mission/dropedCompleteMissionUsers'
import { editMissionReducer } from './mission/editMission'
import { getPasuedMissionReducer } from './mission/getPasuedMission'
import { getSingleMissionReducer } from './mission/getSignleMission'
import { getMissionReducer } from './mission/getMission'
import { getMissionActivationTimeReducer } from './mission/getMissionActivationTime'
import { getMissionDefaultQnsReducer } from './mission/getMissionDefaultQns'
import { getMissionLinkedQnsReducer } from './mission/getMissionLinkedQns'
import { getMissionQnsReducer } from './mission/getMissionQns'
import { getMissionUniversalQnsReducer } from './mission/getMissionUniversalQns'
import { getUserMissionDetailsReducer } from './mission/getUserMissionDetails'
import { linkQnsToMissionReducer } from './mission/linkQnsToMission'
import { liveMissionInBulkReducer } from './mission/liveMissionInBulk'
import { restoreMissionReducer } from './mission/restoreMission'
import { missionStatusReducer } from './mission/missionStatus'
import { missionTimeReducer } from './mission/missionTime'

import { attemptReducer } from './dashbaord/attempt'
import { qualifiedReducer } from './dashbaord/qualified'
import { disqualifiedReducer } from './dashbaord/disqualified'
import { droppedReducer } from './dashbaord/dropped'
import { quitReducer } from './dashbaord/quit'
import { getAllSubCategoryReducer } from './sub-category/getSubAllCategory'

import { getBannerReducer } from './banner/getBanner'
import { createBannerReducer } from './banner/createBanner'
import { deleteBannerReducer } from './banner/deleteBanner'
import { getSingleBannerReducer } from './banner/singleBanner'
import { updateBannerReducer } from './banner/updateBanner'


import { getMetaReducer } from './config/getmeta'
import { editMetaReducer } from './config/editmeta'


import { createSupportDataReducer } from './support/createData'
import { deleteSupportDataReducer } from './support/deleteData'
import { updateSupportDataReducer } from './support/updataData'
import { singleSupportDataReducer } from './support/singleData'
import { supportDataReducer } from './support/list'
import { addManegerDetailsReducer } from './publisher/addManegerDetails'

// bank

import { bankDataReducer } from './bank/List'
import { bankVerifyReducer } from './bank/Verify'
import { bankDetailReducer } from './bank/Detail'

import { getSinglePublisherReducer } from './publisher/publisherDetails'

//postback

import { getPostbackReducer } from './postback/postback'

//user
import { getUserReducer } from './users/getAdminSupports'
import { blockAndUnBlockUserReducer } from './users/blockAndUnBlockAdmin'

import { totalUsersReducer } from './dashbaord/totalUsers'
import { userDetailReducer } from './users/userDetail'

import { deleteMultipleQuestionAnswerReducer } from './question-answer/deleteMultipleQna'







export const toolkitReducers = {
    loginUser: loginUserReducer,
    registerUser: registerUserReducer,
    // question answer section------>
    getSingleQuestionAnswer: getSingleQuestionAnswerReducer,
    getAllQuestionAnswer: getAllQuestionAnswerReducer,
    createQuestionAnswer: createQuestionAnswerReducer,
    createDefaultQns: createDefaultQnsReducer,
    updateQuestionAnswer: updateQuestionAnswerReducer,
    deleteQuestionAnswer: deleteQuestionAnswerReducer,
    deleteMultipleQuestionAnswer:deleteMultipleQuestionAnswerReducer,
    restoreQns: restoreQnsReducer,
    linkQnsToMission: linkQnsToMissionReducer,
    uploadQnsAns: uploadQnsAnsReducer,
    // category---------->
    getCategory: getCategoryReducer,
    getSingleCategory: getSingleCategoryReducer,
    createCategory: createCategoryReducer,
    updateCategory: updateCategoryReducer,
    deleteCategory: deleteCategoryReducer,
    changeCatSeq: changeCatSeqReducer,
    restoreCategory: restoreCategoryReducer,
    // sub-category------->
    getAllSubCategory: getAllSubCategoryReducer,
    getSubCategory: getSubCategoryReducer,
    getSingleSubCategory: getSingleSubCategoryReducer,
    createSubCategory: createSubCategoryReducer,
    updateSubCategory: updateSubCategoryReducer,
    deleteSubCategory: deleteSubCategoryReducer,
    changeSubCatSeq: changeSubCatSeqReducer,
    restoreSubCategory: restoreSubCategoryReducer,
    liveMissionInBulk: liveMissionInBulkReducer,
    getPasuedMission: getPasuedMissionReducer,
    // mission------>
    getMission: getMissionReducer,
    getSingleMission: getSingleMissionReducer,
    createMission: createMissionReducer,
    deleteMission: deleteMissionReducer,
    editMission: editMissionReducer,
    missionStatus: missionStatusReducer,
    restoreMission: restoreMissionReducer,
    getMissionQns: getMissionQnsReducer,
    getMissionDefaultQns: getMissionDefaultQnsReducer,
    getMissionUniversalQns: getMissionUniversalQnsReducer,
    missionTime: missionTimeReducer,
    getMissionLinkedQns: getMissionLinkedQnsReducer,
    getUserMissionDetails: getUserMissionDetailsReducer,
    getMissionActivationTime: getMissionActivationTimeReducer,
    dropedCompleteMissionUsers: dropedCompleteMissionUsersReducer,
    // admin
    getAdminSupport: getAdminSupportReducer,
    addPermission: addPermissionReducer,
    getSuperAdminLogs: getSuperAdminLogsReducer,
    getAdminSupportLogs: getAdminSupportLogsReducer,
    getSingleAdminSupport: getSingleAdminSupportReducer,
    blockAndUnBlockAdmin: blockAndUnBlockAdminReducer,
    // analytics
    noOfDropedMission: noOfDropedMissionReducer,
    noOfParticipent: noOfParticipentReducer,
    noOfQualifiedMission: noOfQualifiedMissionReducer,
    noOfMissionAttempts: noOfMissionAttemptsReducer,
    noOfMissionImpressionGenrated: noOfMissionImpressionGenratedReducer,
    analyticsUserDetails: analyticsUserDetailsReducer,
    // getAnalyticsMissionData: getAnalyticsMissionDataReducer,
    userDayWise: userDayWiseReducer,
    // dashboard
    totalAttempt: attemptReducer,
    totalQualified: qualifiedReducer,
    totalDisqualified: disqualifiedReducer,
    totalQuit: quitReducer,
    totalDropped: droppedReducer,
    toPublisher: toPublisherReducer,
    totalUsers: totalUsersReducer,
    // diffentPlatformPublisher: diffentPlatformPublisherReducer,
    getPermissionAndRole: getPermissionAndRoleReducer,
    // menu
    noOfDeletedMission: noOfDeletedMissionReducer,
    noOfLiveMission: noOfLiveMissionReducer,
    // profile
    getLogsByIdy: getLogsByIdyReducer,
    // publisher
    getPublisher: getPublisherReducer,
    verifyPublisher: verifyPublisherReducer,
    getSinglePubliser: getSinglePublisherReducer,
    // payments
    paymentRequests: paymentRequestsReducer,
    approvedPayment: approvedPaymentReducer,
    creditPayment: creditPaymentReducer,
    // images
    uplodImage: uplodImageReducer,
    //banner
    createBanner: createBannerReducer,
    getBanner: getBannerReducer,
    deleteBanner: deleteBannerReducer,
    singleBanner: getSingleBannerReducer,
    updateBanner: updateBannerReducer,
    //config meta
    getMeta: getMetaReducer,
    editMeta: editMetaReducer,

    // support knowledge

    singleSupport: singleSupportDataReducer,
    supportList: supportDataReducer,
    deleteSupport: deleteSupportDataReducer,
    createSupport: createSupportDataReducer,
    updateSupport: updateSupportDataReducer,

    //

    addManager: addManegerDetailsReducer,

    //bank
    bankData: bankDataReducer,
    bankDetail: bankDetailReducer,
    bankVerify: bankVerifyReducer,

    //postback

    getPostback: getPostbackReducer,

    // user

    getUser: getUserReducer,
    blockAndUnBlockUser: blockAndUnBlockUserReducer,
    userDetail: userDetailReducer


}