import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CreditScoreIcon from '@mui/icons-material/CreditScore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import { Breadcrumbs, Card, IconButton, Link, Paper, Stack, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { createSearchParams, useNavigate } from 'react-router-dom'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import Appbar from '../../components/Appbar'
// import DrawerUI from '../../components/Drawer'
import Body from '../../layout/Body'
import { paymentRequestsAction } from '../../redux/toolkit/payments/paymentRequests'
import { useStyles } from '../../theme/theme'
import ApprovedPayment from './ApprovedPayment'


let payment_id = null,
    publisher_id = null



const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/payments'>Payments</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Payments</Typography>
    </Stack>
)

export default function Payments() {

    // states
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const classes = useStyles();

    const paymentRequests = useSelector(state => state.paymentRequests)
    const { data: paymentRequestsData, loading: paymentRequestsLoading } = paymentRequests

    const pageSize = useState(0)[0];
    const [pageLimit, setPageLimit] = useState(25);
    const [approvedPayments, setApprovedPayments] = useState(false)


    const RequestPaymentColumn = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: 'amount',
            headerName: 'Amount',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'publisher',
            headerName: 'Publisher Name',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                   
                        
                            params?.row?.publisher_id?.name
                       
                   
                )
            }
        },
        {
            field: 'invoice',
            headerName: 'Invoice',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <>
                        <Box sx={{
                            width: '100px',
                        }}>
                            <img width={'100%'} src={params?.row?.invoice} />
                        </Box>
                    </>
                )
            }
        },
        {
            field: 'created_at',
            headerName: 'Date',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'verify',
            headerName: 'Verify Payments',
            headerClassName: 'super-app-theme--header',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params?.row?.status == 'requested' && <IconButton
                                onClick={() => {
                                    publisher_id = params?.row?.publisher_id
                                    payment_id = params?.row?._id
                                    setApprovedPayments(true)
                                }}
                            >
                                <RequestQuoteIcon color='warning' />
                            </IconButton>
                        }
                        {
                            params?.row?.status == 'approved' && <IconButton
                                onClick={() => {
                                    navigate({
                                        pathname: '/credit-payments',
                                        search: createSearchParams({
                                            publisher_id: params?.row?.publisher_id?._id,
                                            payment_id: params?.row?._id
                                        }).toString()
                                    })
                                }}
                            >
                                <CheckBoxIcon color='success' />
                            </IconButton>
                        }
                        {
                            params?.row?.status == 'credited' && <IconButton disabled><CreditScoreIcon color='success' />
                            </IconButton>
                        }
                    </>
                )
            },
        },


    ]


    // useEffect
    useEffect(() => {
        dispatch(paymentRequestsAction({ page: pageSize, limit: pageLimit }))
    }, [dispatch])





    return (
        <>
            <Helmet><title> Quick Survey | Payments </title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            {/* <DrawerUI /> */}
            <Body>

                <Card>
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#071D45',
                            color: '#fff',
                        },
                    }} elevation={0}>


                        <DataGrid
                            className={classes.root}
                            sx={{
                                border: 0,
                            }}
                            getRowHeight={() => 'auto'}
                            autoHeight
                            rows={paymentRequestsData}
                            columns={RequestPaymentColumn}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageLimit}
                            onPageSizeChange={(newPageSize) => setPageLimit(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={paymentRequestsLoading ? <LoadingUI /> : false}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,
                            }}

                        />
                    </Paper>
                </Card>
            </Body>


            <ApprovedPayment state={approvedPayments} setState={setApprovedPayments} payment_id={payment_id} publisher_id={publisher_id} />
        </>
    )
}
