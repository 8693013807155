import { DevTool } from '@hookform/devtools'
// import ClearAllIcon from '@mui/icons-material/ClearAll'
import FilterListIcon from '@mui/icons-material/FilterList';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import RestoreIcon from '@mui/icons-material/Restore'
import { Autocomplete, Box, Breadcrumbs, Button, Card, Grid, IconButton, Link, Menu, MenuItem, Paper, Stack, TextField, Typography } from '@mui/material'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar'
// import Drawer from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { getCategoryAction } from '../../redux/toolkit/category/getCategory'
import { changeSubCatSeqAction } from '../../redux/toolkit/sub-category/changeSubCategorySequence'
import { getSingleSubCategoryAction } from '../../redux/toolkit/sub-category/getSingleSubCategory'
import { getSubCategoryAction } from '../../redux/toolkit/sub-category/getSubCategory'
import { useStyles } from '../../theme/theme'
import CreateSubCatagory from './CreateSubCatagory'
import DeleteSubCategory from './DeleteSubCategory'
import ReStoreSubCategory from './ReStoreSubCategory'
import UpdateSubCategory from './UpdateSubCategory'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'


// let sub_cat_id = ''
let cat_id = ''


const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/sub-category'>Sub Category</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Sub Category</Typography>
    </Stack>
)

export default function SubCategory() {
    // state--->
    const { register, control, handleSubmit, formState: { errors }, reset } = useForm({});

    const dispatch = useDispatch()
    const subcat = useSelector(state => state.getSubCategory)
    const { data: subCatData, loading: subCatLoading } = subcat
    const updateSubCategorySeq = useSelector(state => state.changeSubCatSeq)
    const { status: changeSubCatSeqStatus, message: changeSubCatSeqMsg } = updateSubCategorySeq
    const getCategory = useSelector(state => state.getCategory)
    const { data: getCatData } = getCategory
    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData } = permission

    let can_delete = JSON.parse(localStorage.getItem("ss_active_usr"))?.settings?.can_delete
    let super_admin = permissionData?.role

    const [snack, setSnack] = useState(false)
    const [catMenu, setCatMenu] = useState(false)
    const [createSubCate, setCreateSubCate] = useState(false)
    const [updateSubCat, setUpdateSubCat] = useState(false)
    const [delSubCat, setDelSubCat] = useState(false)
    const [anchorCatMenu, setAnchorCatMenu] = useState()
    const [ativeFilter, setActiveFilter] = useState(false)
    const [restoreSubCat, setRestoreSubCat] = useState(false)
    const [pageSize, setPageSize] = useState(25);
    const [sub_cat_id, setsub_cat_id] = useState('')
    const classes = useStyles();
    const [date, setDate] = useState({
        start: null,
        end: null,
    })
    const [isFilterArea, setIsFilterArea] = useState(false)


    const subCatDataGridColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            minWidth: 220,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'cat_name',
            headerName: 'Category Name',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.cat_id?.cat_name}</Typography>
                )
            },
        },
        {
            field: 'sub_cat_name',
            headerName: 'Sub Category Name',
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header'
        },
        // {
        //     field: 'survey',
        //     headerName: 'Survey',
        //     flex: 1,
        //     minWidth: 200,
        //     headerClassName: 'super-app-theme--header'
        // },
        {
            field: 'sequence',
            headerName: 'Sequence of Sub Cat',
            renderCell: (params) => {
                return (
                    <>
                        <TextField sx={{ my: 2 }} type='number' label="Category Sequence" size='small'
                            defaultValue={params?.row?.sequence}
                            variant="outlined"
                            placeholder='Category Sequence'
                            {...register(params?.row?._id)}
                        />
                    </>
                )
            },
            flex: 1,
            minWidth: 200,
            headerClassName: 'super-app-theme--header',
            hide: ativeFilter
        },

        {
            field: 'edit', headerName: 'Edit Sub Category',
            renderCell: (params) => {
                return (
                    <IconButton
                        onClick={() => {
                            setUpdateSubCat(true); 
                            dispatch(getSingleSubCategoryAction(params?.row?._id))
                        }}><EditIcon color='primary' />
                    </IconButton>
                )
            },
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            hide: ativeFilter
        },
        {
            field: 'delete', headerName: 'Delete Sub Category', renderCell: () => {
                return (
                    <IconButton
                        onClick={() => {
                            setDelSubCat(true);
                        }}><DeleteIcon color='error' />
                    </IconButton>
                )
            },
            flex: 1,
            minWidth: 150,
            headerClassName: 'super-app-theme--header',
            hide: ativeFilter

        },
        {
            field: 'revert',
            headerName: 'Revert Category',
            renderCell: () => {
                return (
                    <IconButton
                        onClick={() => {
                            setRestoreSubCat(true);
                        }}
                    ><RestoreIcon color='success' />
                    </IconButton>
                )
            },
            flex: 1,
            headerClassName: 'super-app-theme--header',
            hide: !ativeFilter

        },
    ]


    // fn
    const handleChangeSubCatSeq = async (data) => {

        delete data?.cat_name;
        const changeSubCat = await dispatch(changeSubCatSeqAction(data))
        if (changeSubCat?.payload?.status == 201) {
            await dispatch(getSubCategoryAction({ cat_id: cat_id, is_deleted: false }))
            window.location.reload()
        }
        setSnack(true)
    }

    // const handleclearFilter = async () => {
    //     await dispatch(getSubCategoryAction({ is_deleted: false }))
    //     reset()
    // }

    const handleFilterData = () => {
        dispatch(getSubCategoryAction({ is_deleted: false, s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
    }

    // use effect
    useEffect(() => {
        dispatch(getCategoryAction({ is_deleted: false }))
    }, [dispatch])

    return (
        <>
            <Helmet><title>Quick survey | Sub Category</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            {/* <Drawer /> */}
            <Body>
                {/* Breadcrumbs */}


                

                <Stack direction="row" justifyContent="flex-end" alignItems="flex-end">
                    {/* <Button variant="outlined" startIcon={<FilterListIcon />} onClick={() => { setIsFilterArea(!isFilterArea) }}>
                        filter by date
                    </Button> */}
                    <Button variant="contained" color="btn" startIcon={<AddIcon />} onClick={() => {
                            setCreateSubCate(true);
                        }}>
                        Create Sub Category
                    </Button>
                </Stack>

                <Card sx={{ my: 2 }}>
                    <Typography color='error' sx={{ ml: 2, my: 2 }}>* Select sub category to see all linked sub category</Typography>
                    <Grid container sx={{ my: 2, ml: 2 }}>
                        <Grid item xs={6}>
                            <Stack direction='row' spacing={2}>
                                <Autocomplete fullWidth disablePortal id={getCatData?._id} options={getCatData} getOptionLabel={getCatData => getCatData?.cat_name}
                                    onChange={(event, value) => {
                                        cat_id = value?._id
                                        dispatch(getSubCategoryAction({ cat_id: cat_id, is_deleted: false }))
                                        setActiveFilter(false)
                                    }}
                                    renderInput={(params) =>
                                        <TextField {...register('cat_name')} {...params} label="Category Name" error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} />}
                                />

                            </Stack>
                        </Grid>

                        <Grid item xs={6}>

                            {/* <IconButton color='error' onClick={handleclearFilter}><ClearAllIcon /></IconButton> */}
                            {/* {(can_delete || super_admin == 'super_admin') && */}
                                <IconButton
                                    onClick={(event) => {
                                        setCatMenu(true);
                                        setAnchorCatMenu(event.currentTarget);
                                    }}>
                                    <FilterAltIcon color='#071D45' />
                                </IconButton>
                            {/* // } */}
                            <Menu
                                open={catMenu}
                                onClose={() => setCatMenu(false)}
                                anchorEl={anchorCatMenu}
                            >
                                <MenuItem selected={ativeFilter}
                                    onClick={() => {
                                        setCatMenu(false)
                                        dispatch(getSubCategoryAction({ cat_id: cat_id, is_deleted: true }))
                                        setActiveFilter(true)
                                    }} >Deleted</MenuItem>
                                <MenuItem selected={!ativeFilter}
                                    onClick={() => {
                                        setCatMenu(false)
                                        dispatch(getSubCategoryAction({ cat_id: cat_id, is_deleted: false }))
                                        setActiveFilter(false)
                                    }}>Non Deleted</MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>

                    {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box sx={{ p: 2 }}>
                            <Typography color='#071D45'>Filter by Date</Typography>
                            <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                                <DatePicker
                                    label="Start Date"
                                    value={date?.start}
                                    onChange={(value) => setDate({ ...date, start: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    maxDate={date?.end}

                                />
                                <DatePicker
                                    label="End Date"
                                    value={date?.end}
                                    onChange={(value) => setDate({ ...date, end: value })}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={date?.start}
                                />
                                <Box>
                                    <IconButton onClick={handleFilterData} color='#071D45'><FilterAltIcon /></IconButton>
                                </Box>
                            </Stack>
                        </Box>
                    </LocalizationProvider> */}

                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#E7F7FF',
                            color: '#000',
                        },
                    }} elevation={0}>
                        <DataGrid
                            className={classes.root}
                            getRowHeight={() => 'auto'}
                            sx={{ border: 0 }}
                            autoHeight
                            rows={subCatData}
                            columns={subCatDataGridColumns}
                            getRowId={(row) => row._id}
                            disableSelectionOnClick
                            experimentalFeatures={{ newEditingApi: true }}
                            pagination
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[25, 50, 100]}
                            disableColumnSelector
                            loading={subCatLoading ? <LoadingUI /> : false}
                            onCellClick={(params) => {
                                setsub_cat_id(params?.row?._id)
                            }}
                            components={{
                                NoRowsOverlay: () => <NoDataFound />,
                                Toolbar: GridToolbar,
                            }}

                        />
                    </Paper>
                </Card>

                {!ativeFilter && subCatData?.length ? <Button onClick={handleSubmit(handleChangeSubCatSeq)} sx={{ my: 2 }} variant='contained' color='error'>Change Sub Category Sequence</Button> : null}




                {/* crud */}
                <CreateSubCatagory state={createSubCate} setState={setCreateSubCate} />
                <UpdateSubCategory sub_cat_id={sub_cat_id} state={updateSubCat} setState={setUpdateSubCat} />
                <DeleteSubCategory sub_cat_id={sub_cat_id} state={delSubCat} setState={setDelSubCat} />
                <ReStoreSubCategory sub_cat_id={sub_cat_id} state={restoreSubCat} setState={setRestoreSubCat} resetSubCat={reset} />
            </Body >

            <SnackBarUI state={snack} setState={setSnack} status={changeSubCatSeqStatus} message={changeSubCatSeqMsg} />
            <DevTool control={control} />
        </>
    )
}
