import React, { useEffect, useState } from 'react';
import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
// import EditIcon from '@mui/icons-material/Edit';
import { Button, Card, CircularProgress, Modal, TextField, Typography,Avatar,Badge } from '@mui/material';
import { Box, Stack} from '@mui/system';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { getBannerAction } from '../../redux/toolkit/banner/getBanner';
import { uplodImageAction } from '../../redux/toolkit/image/uplodImage';
import { updateBannerAction } from '../../redux/toolkit/banner/updateBanner';



// schema
const bannerSchema = yup.object().shape({
    name: yup.string().required('name is required.'),
    // image: yup.string().required('image is required.'),
    link:yup.string().required('link is required')
}).required();

export default function CreateBanner({ state, setState }) {
    // state
    

    const dispatch = useDispatch()
    const createBanner = useSelector(state => state.createBanner)
    const { status, message, loading } = createBanner

    const singleBanner = useSelector(state => state.singleBanner)
    const { data:bannerData,status:bannerStatus, message:bannerMsg, loading:bannerLoading } = singleBanner
    console.log("data-->",bannerData);

    const [snack, setSnack] = useState(false)
    const [image,setImage]=useState(bannerData.image)
    // const imageUpload={...register('imageUpload')}

    console.log('nameeeeeee',bannerData.name)
    const { register,setValue,control, handleSubmit, formState: { errors }, reset,setError } = useForm({ resolver: yupResolver(bannerSchema), });

    // fn
    useEffect(() => {
        let defaultValues = {};
        defaultValues.name =bannerData.name;
        defaultValues.link =bannerData.link;
        defaultValues.image =bannerData.image;
        setImage(bannerData.image)
        reset({ ...defaultValues });
    }, [bannerData])

    const handleImage = async (e) => {

        const formData = new FormData();
        
        const file = e.target.files[0];

       formData.append('file', file);
       
       let FileSize = Number(e.target.files[0].size / 1000);

       var _URL = window.URL || window.webkitURL;
		let img = new Image();
		var width, height;


        img.onload =async function () {
			// console.log(img, file);
			width = this.width;
			height = this.height;
			// if (FileSize > 5000) {
			//     return alert(`Image should be to under 50kb reupload image, Your file size is ${FileSize}`)
			// }
			if (height > 120 && width > 400) {
				// console.log(formData);
				let uploadedImg = await dispatch(uplodImageAction(formData))
                const imgStr=uploadedImg?.payload?.data?.url;
                setImage(uploadedImg?.payload?.data?.url)
                setValue('image',imgStr)
				alert(`Image is Successfully Uploaded, Your file size is ${FileSize}`);
			} else {
				alert(
					`Image height less then 150 & greater then 120 width less then 420 & greater then 400, Your height=${height} & width=${width}`
				);
			}
		};
		img.onerror = function () {
			alert("not a valid file: " + file.type);
		};
		img.src = _URL.createObjectURL(file);

        // formData.set("file", e.target.files[0]);


        
        // console.log("image-->",image);

    }

    const handleBanner = async (data) => {
        setState(false)
        console.log("data-->",data);
        console.log("bannerData-->",bannerData);
        data.image=image
        console.log("data.image-->",data.image);
        if(data.image===''||data.image===null||data.image===undefined){
            setError('imageUpload',{type:"custom"})
        }
        let payload={
            id:bannerData?._id,
            ...data

        }
        const updateCategoryData = await dispatch(updateBannerAction(payload))
        setSnack(true)
        if (updateCategoryData?.payload?.status == 200) {
            await dispatch(getBannerAction({ is_deleted: '' }))
        }
        console.log("------",message)
        reset()
        setImage(null)
    }

    const handleClose = function () {
        setState(false)
    }


    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 400, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Create Banner</Typography>
                        <form onSubmit={handleSubmit(handleBanner)}>
                            <Stack spacing={3}>
                                <TextField type='text' defaultValue={bannerData.name} label="Banner Name" variant="outlined" placeholder='Banner Name' {...register('name')} error={errors?.name?.message} helperText={errors?.name?.message} />
                                <TextField type='link' defaultValue={bannerData.link} label="Link" variant="outlined" placeholder='Link' {...register('link')} error={errors?.link?.message} helperText={errors?.link?.message} />
                                {/* <TextField type='number' InputProps={{ inputProps: { min: 1 } }} label="Sequence" variant="outlined" placeholder='Sequence Number' {...register('sequence')} error={errors?.sequence?.message} helperText={errors?.sequence?.message} /> */}
                                <TextField type='file' accept="image/*" variant="outlined" id='image-upload' placeholder='image' onChange={handleImage} error={errors?.image?.message} helperText={errors?.image?.message} />
                                <Badge  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
                                 <Avatar src={image} sx={{ width: 100}} />
                                </Badge>
                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Update Banner'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>
            <SnackBarUI state={snack} setState={setSnack} status={bannerStatus} message={bannerMsg} />
            <DevTool control={control} />
        </>
    )
}



CreateBanner.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any
};