import { DevTool } from '@hookform/devtools';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, CircularProgress, Modal, TextField, Typography,Badge,Avatar} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import "../../css/ui/Modal.scss";
import { getCategoryAction } from '../../redux/toolkit/category/getCategory';
import { updateCategoryAction } from '../../redux/toolkit/category/updateCategory';
import { uplodImageAction } from '../../redux/toolkit/image/uplodImage';
import PropTypes from 'prop-types';

// schema
const catSchema = yup.object({
    cat_name: yup.string().required('Category name is required.'),
}).required();

export default function UpdateCategory({ cat_id, state, setState }) {
    // state
    const dispatch = useDispatch()

    const updateCat = useSelector(state => state.updateCategory)
    const { status, message, loading } = updateCat
    const snglCat = useSelector(state => state.getSingleCategory)
    const { data: { cat_name,image:img } } = snglCat
    // console.log("image-->",img)
    const [image,setImage]=useState()


    const { register,setValue,reset, control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(catSchema),
        defaultValues: {
            cat_name: cat_name
        }
    });

    const [snack, setSnack] = useState(false)

    // fn

    const handleImage = async (e) => {

        const formData = new FormData();

        formData.set("file", e.target.files[0]);

        let uploadedImg = await dispatch(uplodImageAction(formData))
        const imgStr=uploadedImg?.payload?.data?.url;
        setImage(uploadedImg?.payload?.data?.url)
        setValue('image',imgStr)
        console.log("image-->",image);

    }

    const handleCategory = async (data) => {
        data.image=image;
        console.log("data--->",data);
        await dispatch(updateCategoryAction({ ...data, cat_id: cat_id }))
        await dispatch(getCategoryAction({ is_deleted: false }))
        setSnack(true)
        setState(false)
    }

    const handleClose = function () {
        setState(false)
    }


    // useEffect
    useEffect(() => {
        let defaultValues = {};
        defaultValues.cat_name = cat_name;
        setImage(img)
        reset({ ...defaultValues });
    }, [cat_name])



    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 400, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Update Category</Typography>
                        <form onSubmit={handleSubmit(handleCategory)}>
                            <Stack spacing={3}>

                                <TextField label="Category" variant="outlined"  {...register('cat_name')} error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} />
                                <TextField type='file' accept="image/*" variant="outlined" id='image-upload' placeholder='image' {...register('image')} onChange={handleImage} error={errors?.image?.message} helperText={errors?.image?.message} />
                                <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
                                <Avatar src={image} sx={{ width: 60, height: 60 }} />
                                </Badge>
                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Update Category'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose} >Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>



            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
            <DevTool control={control} />
        </>
    )
}

UpdateCategory.propTypes = {
    cat_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};
