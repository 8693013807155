import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const blockUnblockPublisherAction = createAsyncThunk(
    'block-ublock-publisher',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.patch(`/admins/api/block-unblock-publisher`, {
                publisher_id: data?.publisher_id,
                is_block: data?.block
            })
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const blockUnblockPublisherSlice = createSlice({
    name: 'block-ublock-publisher',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(blockUnblockPublisherAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(blockUnblockPublisherAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(blockUnblockPublisherAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const blockUnblockPublisherReducer = blockUnblockPublisherSlice.reducer