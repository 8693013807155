import React, { useEffect,lazy,Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, } from "react-router-dom";
// import Admin from '../pages/admin/Admin';
import User from '../pages/users/Users';
import AdminLog from '../pages/admin/AdminLog';
import SuperAdminLog from '../pages/admin/SuperAdminLog';
import Analytics from '../pages/analytics/Analytics';
// import UserDetails from '../pages/analytics/user/UserDetails';
import Login from '../pages/auth/Login';
// import Register from '../pages/auth/Register';
import Category from '../pages/category/Category';
import Home from '../pages/home/Home';
import Menu from '../pages/menu/Menu';
import NotFoundPage from '../pages/NotFoundPage';
import CreditPayment from '../pages/payments/CreditPayment';
import Payments from '../pages/payments/Payments';
import Profile from '../pages/profile/Profile';
import Publisher from '../pages/publisher/Publisher';
import QuestionAnswer from '../pages/question-answer/QuestionAnswer';
import SubCategory from '../pages/sub-category/SubCategory';
import CreateMission from '../pages/mission/create-mission/CreateMission';
import EditMission from '../pages/mission/edit-mission/EditMission';
import LiveBulkMission from '../pages/mission/LiveBulkMission';
import Mission from '../pages/mission/Mission';
import MissionDetails from '../pages/mission/MissionDetails';
import Banner from '../pages/banner'
import { getPermissionAndRoleAction } from '../redux/toolkit/dashbaord/getPermissionAndRole';

import SupportKnoweldge from '../pages/support-knowledge'
import Config from '../pages/config'
import ProtectedRoute from './ProtectedRoute';
import Layout from '../layout'
import Postback from '../pages/postback/Postback';
import Bank from '../pages/bank';
const PublisherDetails=lazy(()=>import('../pages/publisher/PublisherDetails'))
const UserDetails=lazy(()=>import('../pages/users/userDetails'))
const Admin = lazy(()=>import('../pages/admin/Admin'));


export default function Index() {

    // states
    const dispatch = useDispatch()

    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData } = permission




    useEffect(() => {
        dispatch(getPermissionAndRoleAction())
    }, [dispatch])


    return (
        <Suspense fallback={<div>Loading...</div>}>

        <BrowserRouter>
            <Routes>
                {/* protected routes */}
                <Route path="/" element={<Layout />}>
                    <Route index element={<ProtectedRoute><Home /></ProtectedRoute>} />
                    {permissionData?.permission?.includes("qns_and_ans") || permissionData?.role == 'super_admin' ? <Route path="/qns-ans" element={<ProtectedRoute><QuestionAnswer /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("category") || permissionData?.role == 'super_admin' ? <Route path="/category" element={<ProtectedRoute><Category /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("sub_category") || permissionData?.role == 'super_admin' ? <Route path="/sub-category" element={<ProtectedRoute><SubCategory /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("mission") || permissionData?.role == 'super_admin' ? <Route path="/mission" element={<ProtectedRoute><Mission /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("banner") || permissionData?.role == 'super_admin' ? <Route path="/banner" element={<ProtectedRoute><Banner /></ProtectedRoute>} /> : null}

                    {permissionData?.permission?.includes("mission") || permissionData?.role == 'super_admin' ? <Route path="/mission/create-mission" element={<ProtectedRoute><CreateMission /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("mission") || permissionData?.role == 'super_admin' ? <Route path="/mission/edit-mission" element={<ProtectedRoute><EditMission /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("mission") || permissionData?.role == 'super_admin' ? <Route path="/mission/live-bulk-mission" element={<ProtectedRoute><LiveBulkMission /></ProtectedRoute>} /> : null}

                    {permissionData?.permission?.includes("mission") || permissionData?.role == 'super_admin' ? <Route path="/mission-detail" element={<ProtectedRoute><MissionDetails /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("mission") || permissionData?.role == 'super_admin' ? <Route path="/publisher" element={<ProtectedRoute><Publisher /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("mission") || permissionData?.role == 'super_admin' ? <Route path="/publisher-detail" element={<ProtectedRoute><PublisherDetails /></ProtectedRoute>} /> : null}

                    {permissionData?.permission?.includes("payments") || permissionData?.role == 'super_admin' ? <Route path="/payments" element={<ProtectedRoute><Payments /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("payments") || permissionData?.role == 'super_admin' ? <Route path="/credit-payments" element={<ProtectedRoute><CreditPayment /></ProtectedRoute>} /> : null}

                    {permissionData?.role == 'super_admin' ? <Route path="/admin" element={<ProtectedRoute><Admin /></ProtectedRoute>} /> : null}
                    {permissionData?.role == 'super_admin' ? <Route path="/user" element={<ProtectedRoute><User /></ProtectedRoute>} /> : null}
                    {permissionData?.role == 'super_admin' ? <Route path="/user-detail" element={<ProtectedRoute><UserDetails /></ProtectedRoute>} /> : null}
                    {permissionData?.role == 'super_admin' ? <Route path="/admin-log" element={<ProtectedRoute><AdminLog /></ProtectedRoute>} /> : null}
                    {permissionData?.role == 'super_admin' ? <Route path="/super-admin-log" element={<ProtectedRoute><SuperAdminLog /></ProtectedRoute>} /> : null}
                    {permissionData?.role == 'super_admin' ? <Route path="/config-meta" element={<ProtectedRoute><Config /></ProtectedRoute>} /> : null}
                    {permissionData?.role == 'super_admin' ? <Route path="/support" element={<ProtectedRoute><SupportKnoweldge /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("bank") || permissionData?.role == 'super_admin' ? <Route path="/bank" element={<ProtectedRoute><Bank /></ProtectedRoute>} /> : null}
                    {permissionData?.permission?.includes("postback") || permissionData?.role == 'super_admin' ? <Route path="/post-back" element={<ProtectedRoute><Postback /></ProtectedRoute>} /> : null}
                    {/* {permissionData?.permission?.includes("postback")  || permissionData?.role == 'super_admin' ? <Route path="/post-back" element={<ProtectedRoute><Postback /></ProtectedRoute>} /> : null} */}

                
                </Route>
                {/* unprotected routes */}
                <Route path="/login" element={<Login />} />
                <Route path="*" element={<NotFoundPage />} />
                {/* <Route path="/register" element={<Register />} /> */}
            </Routes>
        </BrowserRouter>
        </Suspense>
    )
}
