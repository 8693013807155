import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const deleteBannerAction = createAsyncThunk(
    'delete-banner',
    async (data = null, { rejectWithValue }) => {
        try {
            console.log("data-->",data);
            const res = await axios.delete(`/admins/api/delete-banner/${data}`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const deleteBannerSlice = createSlice({
    name: 'delete-banner',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(deleteBannerAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(deleteBannerAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
                console.log("---------- delete banner action",action?.payload?.message)
            })
            builder.addCase(deleteBannerAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const deleteBannerReducer = deleteBannerSlice.reducer    