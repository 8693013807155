import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }



export const userDayWiseAction = createAsyncThunk(
    'user-day-wise',
    async (data, { rejectWithValue }) => {
        try {
            const res = await axios.get(`/admins/api/total-participants-in-last-30days`)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const userDayWiseSlice = createSlice({
    name: 'user-day-wise',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(userDayWiseAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(userDayWiseAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(userDayWiseAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const userDayWiseReducer = userDayWiseSlice.reducer
