import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import FastRewindIcon from '@mui/icons-material/FastRewind';
import BarChartIcon from '@mui/icons-material/BarChart'
import BookmarkIcon from '@mui/icons-material/Bookmark'
import BookmarksIcon from '@mui/icons-material/Bookmarks'
import DashboardIcon from '@mui/icons-material/Dashboard'
import ForumIcon from '@mui/icons-material/Forum'
import HelpIcon from '@mui/icons-material/Help'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import Crop169SharpIcon from '@mui/icons-material/Crop169Sharp';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import PaymentIcon from '@mui/icons-material/Payment'
import User from '@mui/icons-material/Groups2';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount'
import WebStoriesIcon from '@mui/icons-material/WebStories'
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader } from '@mui/material'
import React, { useContext, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import Logo from "../assets/img/logo.png"
import LoadingUI from '../comman/LoadingUI'
import { ThemeContext } from '../context/ThemeProvider'
import Style from '../css/components/Drawer.module.scss'
import { getPermissionAndRoleAction } from '../redux/toolkit/dashbaord/getPermissionAndRole'
import SupportAgentIcon from '@mui/icons-material/SupportAgent';






export default function DrawerUI() {
    // state---->
    const dispatch = useDispatch()
    const permission = useSelector(state => state.getPermissionAndRole)
    const { data: permissionData, loading: permissionLoading } = permission

    console.log(permissionData.role, "permissionData.role");

    const { drawerSilde } = useContext(ThemeContext)
    const location = useLocation()?.pathname




    const handleSubDiv=()=>{
        var div = document.getElementById("myDiv");
        if (div.style.display === "none") {
          div.style.display = "block";
        } else {
          div.style.display = "none";
        }
    }

    const drawerListDashboard = []

    if (permissionData?.permission?.includes("home") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Dashboard", icon: <DashboardIcon fontSize='small' color={location == "/" ? "primary" : ''} />, path: '/' }) }
    if (permissionData?.permission?.includes("banner") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Banner", icon: <Crop169SharpIcon fontSize='small' color={location == "/banner" ? "primary" : ''} />, path: '/banner' }) }
    if (permissionData?.permission?.includes('category') || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Category", icon: <BookmarkIcon fontSize='small' color={location == '/category' ? "primary" : ''} />, path: '/category' }) }
    if (permissionData?.permission?.includes("sub_category") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Sub Category", icon: <BookmarksIcon fontSize='small' color={location == '/sub-category' ? "primary" : ''} />, path: '/sub-category' }) }
    if (permissionData?.permission?.includes("qns_and_ans") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Q & A", icon: <HelpIcon fontSize='small' color={location == '/qns-ans' ? "primary" : ''} />, path: '/qns-ans' }) }
    if (permissionData?.permission?.includes("mission") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Mission", icon: <LocalOfferIcon fontSize='small' color={location == '/mission' ? "primary" : ''} />, path: '/mission' }) }
    // if (permissionData?.permission?.includes("profile") || permissionData?.role == 'super_admin') { drawerListDashboard.push({ name: "Profile", icon: <Person2Icon fontSize='small' color={location == '/profile' ? "primary" : ''} />, path: '/profile' }) }


    const adminList = [
        { name: "Admin", icon: <AdminPanelSettingsIcon fontSize='small' color={location == '/admin' ? "primary" : ''} />, path: '/admin' },
        { name: "User", icon: <User fontSize='small' color={location == '/user' ? "primary" : ''} />, path: '/user' },
        // { name: "Super Admin Logs", icon: <WebStoriesIcon fontSize='small' color={location == '/super-admin-log' ? "primary" : ''} />, path: '/super-admin-log' },
        { name: "Admin Logs", icon: <SupervisorAccountIcon fontSize='small' color={location == '/admin-log' ? "primary" : ''} />, path: '/admin-log' },
        { name: "Config", icon: <PermDataSettingIcon fontSize='small' color={location == '/config-meta' ? "primary" : ''} />, path: '/config-meta' },
        { name: "Support & Knowledge", icon: <SupportAgentIcon fontSize='small' color={location == '/support' ? "primary" : ''} />, path: '/support' }
    ]


    // const statsList = []
    // if (permissionData?.permission?.includes("analytics") || permissionData?.role == 'super_admin') { statsList.push({ name: "Analytics", icon: <BarChartIcon fontSize='small' color={location == '/analytics' ? "primary" : ''} />, path: '/analytics' }) }
    // if (permissionData?.permission?.includes("menu") || permissionData?.role == 'super_admin') { statsList.push({ name: "Menu", icon: <MenuOpenIcon fontSize='small' color={location == '/menu' ? "primary" : ''} />, path: '/menu' }) }




    const publisherList = []
    if (permissionData?.permission?.includes("publisher") || permissionData?.role == 'super_admin') { publisherList.push({ name: "Publisher", icon: <ForumIcon fontSize='small' color={location == '/publisher' ? "primary" : ''} />, path: '/publisher' }) }
    if (permissionData?.permission?.includes("postback")  ||   permissionData?.role == 'super_admin') { publisherList.push({ name: "Postback Logger", icon: <FastRewindIcon fontSize='small' color={location == '/post-back' ? "primary" : ''} />, path: '/post-back' }) }
    if (permissionData?.permission?.includes("payments") || permissionData?.role == 'super_admin') { publisherList.push({ name: "Payments", icon: <PaymentIcon fontSize='small' color={location == '/payments' ? "primary" : ''} />, path: '/payments' }) }
    if (permissionData?.permission?.includes("bank") || permissionData?.role == 'super_admin') { publisherList.push({ name: "Bank Request", icon: <AccountBalanceIcon fontSize='small' color={location == '/bank' ? "primary" : ''} />, path: '/bank' }) }





    useEffect(() => {
        dispatch(getPermissionAndRoleAction())
    }, [dispatch,location])



    return (
        <Box className={drawerSilde ? Style.drawerOpen : Style.drawerClose}>

            <Box className={Style.header}>
                {/* <img style={{minWidth:"10px"}} src={Logo} alt='logo' /> */}
                <p>Quizwall</p>
                
            </Box>

            {/* <Divider /> */}

            {/* dashboard */}
            {/* <List disablePadding subheader={drawerSilde && <ListSubheader sx={{ fontSize: 17, }} component="div">DashBoard</ListSubheader>}> */}
            <div style={{ border: "2px solid #E0E0E0", margin: "2px 15px" }}>
                {drawerListDashboard?.map((items, index) => {
                    return (
                        <div key={index}>
                            <Link to={items?.path} style={{ textDecoration: 'none', }}>
                                <ListItem disablePadding className={location == items?.path ? Style.actvie_link : Style.non_actvie_link}>
                                    <ListItemButton>
                                        <ListItemIcon className={location == items?.path ? Style.actvie_icon : Style.actvie_icon}>
                                            {items?.icon}
                                        </ListItemIcon>
                                        {drawerSilde && <ListItemText primary={items?.name} />}
                                    </ListItemButton>
                                </ListItem>
                            </Link>

                        </div>
                    )

                })}

                {permissionData?.role == 'super_admin' ?
                    <List disablePadding subheader={drawerSilde && <ListSubheader sx={{ fontSize: 17, }} component="div">Admin</ListSubheader>}>
                        {adminList?.map((items, index) => {
                            return (
                                <>
                                    {permissionLoading
                                        ? <LoadingUI />
                                        :
                                        <Link key={index} to={items?.path} style={{ textDecoration: 'none', }}>
                                            <ListItem disablePadding className={location == items?.path ? Style.actvie_link : Style.non_actvie_link}>
                                                <ListItemButton>
                                                    <ListItemIcon>
                                                        {items?.icon}
                                                    </ListItemIcon>
                                                    {drawerSilde && <ListItemText primary={items?.name} />}
                                                </ListItemButton>
                                            </ListItem>
                                        </Link>
                                    }
                                </>
                            )
                        })}
                    </List>
                    : null
                }
                {/* <div>
                    <Link to='/mission' style={{ textDecoration: 'none', }} onClick={handleSubDiv}>
                        <ListItem disablePadding className={location == '/mission' ? Style.actvie_link : Style.non_actvie_link}>
                            <ListItemButton>
                                <ListItemIcon className={location == '/mission' ? Style.actvie_icon : Style.actvie_icon}>
                                    <LocalOfferIcon fontSize='small' color={location == '/mission' ? "primary" : ''} />
                                </ListItemIcon>
                                {drawerSilde && <ListItemText primary="Mission" />}
                            </ListItemButton>
                        </ListItem>
                    </Link>
                </div>
                <div id="myDiv"  style={{display:'none',backgroundColor:"aliceblue",padding:"25px",position:"relative"}}>
                        <div style={{width:"100%"}}>
                        <Link to='/hello' style={{ textDecoration: 'none', }} onClick={handleSubDiv}>
                        <ListItem disablePadding className={location == '/hello' ? Style.actvie_link : Style.non_actvie_link}>
                            <ListItemButton>
                                <ListItemIcon className={location == '/hello' ? Style.actvie_icon : Style.actvie_icon}>
                                    <LocalOfferIcon fontSize='small' color={location == '/hello' ? "primary" : ''} />
                                </ListItemIcon>
                                {drawerSilde && <ListItemText primary="Mission" />}
                            </ListItemButton>
                        </ListItem>
                    </Link>
                    <Link to='/world' style={{ textDecoration: 'none', }} onClick={handleSubDiv}>
                        <ListItem disablePadding className={location == '/world' ? Style.actvie_link : Style.non_actvie_link}>
                            <ListItemButton>
                                <ListItemIcon className={location == '/world' ? Style.actvie_icon : Style.actvie_icon}>
                                    <LocalOfferIcon fontSize='small' color={location == '/world' ? "primary" : ''} />
                                </ListItemIcon>
                                {drawerSilde && <ListItemText primary="Mission" />}
                            </ListItemButton>
                        </ListItem>
                    </Link>
                        </div>
                </div> */}
                {publisherList?.length ?
                <List disablePadding subheader={drawerSilde && <ListSubheader sx={{ fontSize: 17, }} component="div">Publisher</ListSubheader>}>
                    {publisherList?.map((items, index) => {
                        return (
                            <>
                                {permissionLoading
                                    ? <LoadingUI />
                                    : <Link key={index} to={items?.path} style={{ textDecoration: 'none', }}>
                                        <ListItem disablePadding className={location == items?.path ? Style.actvie_link : Style.non_actvie_link}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {items?.icon}
                                                </ListItemIcon>
                                                {drawerSilde && <ListItemText primary={items?.name} />}
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                            </>

                        )
                    })}
                </List>
                : null
            }

            </div>
            {/* </List> */}

            {/* Admins */}


            {/* stats */}
            {/* {statsList?.length ?
                <List disablePadding subheader={drawerSilde && <ListSubheader sx={{ fontSize: 17, }} component="div">Stats</ListSubheader>}>
                    {statsList?.map((items, index) => {
                        return (
                            <>
                                {permissionLoading
                                    ? <LoadingUI />
                                    : <Link key={index} to={items?.path} style={{ textDecoration: 'none', }}>
                                        <ListItem disablePadding className={location == items?.path ? Style.actvie_link : Style.non_actvie_link}>
                                            <ListItemButton>
                                                <ListItemIcon>
                                                    {items?.icon}
                                                </ListItemIcon>
                                                {drawerSilde && <ListItemText primary={items?.name} />}
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>}
                            </>

                        )
                    })}
                </List>
                : null
            } */}


            {/* stats */}
            

        </Box >
    )
}

