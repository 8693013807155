import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Breadcrumbs, Grid, Link, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import Appbar from '../../components/Appbar'
// import DrawerUI from '../../components/Drawer'
import Body from '../../layout/Body'
import Dashboard from './Dashboard'
import PublisherFromDeffPlatfomr from './PublisherFromDeffPlatform'
import missionStats from './MissionStats'
import TotalPublisher from './TotalPublisher'
import MissionAttempts from './MissionAttempts'
import QualifiedAttempts from './QualifiedAttempts'
import DisqualifiedAttempts from './DisqualifiedAttempts'
import Dropped from './Dropped'
import AttemptsQuit from './AttemptsQuit'
import TotalUser from './totalUser'
import DailyActiveUser from './dailyActiveUser'

import { Link as LinkRRD } from "react-router-dom"



let breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Home</Typography>
    </Stack>

)



export default function Home() {
    return (

        <>
            <Helmet>
                <title>Quick mission | home</title>
            </Helmet>


            <Appbar breadcrumb={breadcrumb} />
            {/* <DrawerUI /> */}
            <Body>
                {/* dashbaord */}
                <Grid container spacing={2}>
                    <Grid xs={12} item><Dashboard /></Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid xs={4} item>
                        {/* <LinkRRD style={{ textDecoration: 'none' }} to='/analytics/user-details'> */}
                        <MissionAttempts />
                        {/* </LinkRRD> */}
                    </Grid>

                    <Grid xs={4} item>
                        {/* <LinkRRD style={{ textDecoration: 'none' }} to='/publisher'> */}
                        <QualifiedAttempts />
                        {/* </LinkRRD> */}
                    </Grid>

                    <Grid xs={4} item>
                        {/* <LinkRRD style={{ textDecoration: 'none' }} to='/publisher'> */}
                        <TotalPublisher />
                        {/* </LinkRRD> */}
                    </Grid>

                </Grid>

                {/* user section */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid xs={4} item>
                        {/* <LinkRRD style={{ textDecoration: 'none' }} to='/analytics/user-details'> */}
                        <Dropped />
                        {/* </LinkRRD> */}
                    </Grid>

                    <Grid xs={4} item>
                        {/* <LinkRRD style={{ textDecoration: 'none' }} to='/publisher'> */}
                        <DisqualifiedAttempts />
                        {/* </LinkRRD> */}
                    </Grid>

                    <Grid xs={4} item>
                        {/* <LinkRRD style={{ textDecoration: 'none' }} to='/publisher'> */}
                        <AttemptsQuit />
                        {/* </LinkRRD> */}
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                    <Grid xs={4} item>
                        {/* <LinkRRD style={{ textDecoration: 'none' }} to='/analytics/user-details'> */}
                        <TotalUser />
                        {/* </LinkRRD> */}
                    </Grid>

                    <Grid xs={4} item>
                        {/* <LinkRRD style={{ textDecoration: 'none' }} to='/analytics/user-details'> */}
                        <DailyActiveUser />
                        {/* </LinkRRD> */}
                    </Grid>

                </Grid>
            </Body>
        </>
    )
}
