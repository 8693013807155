
import React, { useEffect, useState } from 'react'
import { Link as LinkRRD } from 'react-router-dom'
import BlockIcon from '@mui/icons-material/Block'
import DoneIcon from '@mui/icons-material/Done'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Breadcrumbs, Button, Card, IconButton, Link, Paper, TextField, Typography, MenuItem, Menu } from '@mui/material'
import { Stack } from '@mui/system'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Helmet } from 'react-helmet-async'
import moment from 'moment'
import InputBase from '@mui/material/InputBase';
import { useDispatch, useSelector } from 'react-redux'

import LoadingUI from '../../comman/LoadingUI'
import NoDataFound from '../../comman/NoDataFound'
import SnackBarUI from '../../comman/SnackBarUI'
import Appbar from '../../components/Appbar';
import { dateFormatStartFun } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { getUserAction } from '../../redux/toolkit/users/getAdminSupports'
import BlockUnBlockAdmin from './BlockUnBlockAdmin'




import { useStyles } from '../../theme/theme'




let user_id = null

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/admin'>User</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>User</Typography>
    </Stack>
)



export default function User() {
    // state
    const dispatch = useDispatch()
    const getUserSelector = useSelector(state => state.getUser)
    let { data, loading, total } = getUserSelector
    const blockAndUnBlockAdminSelector = useSelector(state => state.blockAndUnBlockUser)
    const { status, message } = blockAndUnBlockAdminSelector

    const [blockAdmin, setBlockAdmin] = useState(false);
    const [is_block, setIs_block] = useState(false);
    const [snack, setSnack] = useState(false)
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [date, setDate] = useState({
        startDate: null,
        endDate: null,
    })
    const [pageState, setPageState] = useState({ page: 0, pageSize: 25 })

    const [email, setEmail] = useState('');
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const dateWiseUser = () => {
        console.log("date", dateFormatStartFun(date?.startDate))
        dispatch(getUserAction({ startDate: dateFormatStartFun(date?.startDate), endDate: dateFormatStartFun(date?.endDate), is_block: false }))
    }

    const searchHandler = () => {
        console.log("email", email);
        dispatch(getUserAction({ email, is_block: false }))

    }






    const adminColumns = [
        {
            field: 's_no',
            headerName: 'S. No.',
            filterable: false,
            flex: 1,
            maxWidth: 50,
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                const { page, pageSize } = pageState;
                const serialNumber = (page * pageSize) +
                    params?.api?.getRowIndex(params.row._id) + 1
                return serialNumber;
            }
        },
        {
            field: '_id',
            headerName: 'ID',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 300,
            renderCell: (params) => {
                return (
                    <LinkRRD to={`/user-detail?${params?.row?._id}`}>
                        <Typography color='primary'>{params?.row?._id}</Typography>
                    </LinkRRD>
                )
            },
        },
        {
            field: 'app_id',
            headerName: 'App Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 250,
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.app_name?.app_name}</Typography>
                )
            },
        },

        {
            field: 'email',
            headerName: 'Email',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 300
        },
        {
            field: 'Publisher Name',
            headerName: 'Publisher Name',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.publisher_id?.name}</Typography>
                )
            },
        },
        {
            field: 'mission_attempts',
            headerName: 'Mission Attempts',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 150
        },

        {
            field: 'mission_complete',
            headerName: 'Mission Complete',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 150
        },

        {
            field: 'mission_droped',
            headerName: 'Mission Droped',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 150
        },

        {
            field: 'mission_played',
            headerName: 'Mission Played',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 150,
        },
        {
            field: 'reward',
            headerName: 'Reward',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 150,

        },
        {
            field: 'created_at',
            headerName: 'Created At',
            flex: 1,
            headerClassName: 'super-app-theme--header',
            minWidth: 250,
            renderCell: (params) => {
                return (
                    <Typography>{params?.row?.created_at}</Typography>
                )
            },

        },
        // {
        //     field: 'edit', headerName: 'Edit User',
        //     renderCell: (params) => {
        //         return (
        //             <IconButton onClick={() => {
        //                 setEditPremission(true)
        //                 // dispatch(getSingleAdminSupportAction(params?.row?._id))
        //             }}><EditIcon color='#071D45' />
        //             </IconButton>
        //         )
        //     },
        //     flex: 1,
        //     headerClassName: 'super-app-theme--header',
        // },
        {
            field: 'is_block',
            headerName: 'Block',
            minWidth: 100,
            renderCell: (params) => {
                return (
                    <>
                        {
                            !params?.row?.is_block
                                ?
                                (<IconButton onClick={() => {
                                    setBlockAdmin(true)
                                    setIs_block(true)
                                    user_id = params?.row?._id
                                }} >
                                    <DoneIcon color='primary' />
                                </IconButton>)
                                :
                               ( <IconButton
                                    onClick={() => {
                                        setBlockAdmin(true)
                                        setIs_block(false)
                                        user_id = params?.row?._id
                                    }}>
                                    <BlockIcon color='error' />
                                </IconButton>)
                        }

                    </>

                )
            },
            flex: 1,
            headerClassName: 'super-app-theme--header',
        },
    ]


    // useEffec
    useEffect(() => {
        dispatch(getUserAction({ is_block: is_block, page: pageState.page, limit: pageState.pageSize }))
    }, [dispatch, pageState.page, pageState.pageSize])


    return (
        <>
            <Helmet>
                <title>Quizwall | User</title>
            </Helmet>


            {/* <IconButton color='error' onClick={handleclearFilter}><ClearAllIcon /></IconButton> */}





            <Appbar breadcrumb={breadcrumb} />
            {/* <DrawerUI /> */}
            <Body>

                <Stack direction="row" justifyContent="space-between" alignItems="flex-start">

                    <Stack direction='row' spacing={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Min Data/Time"
                                value={date?.startDate}
                                maxDate={moment()}
                                onChange={(newValue) => {

                                    setDate({ ...date, startDate: newValue });
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                label="Max Data/Time"
                                value={date?.endDate}
                                minDate={date?.startDate}
                                maxDate={moment()}
                                onChange={(newValue) => {
                                    setDate({ ...date, endDate: newValue });
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                        {date?.startDate && date?.endDate && <Button variant='contained' onClick={dateWiseUser}>find data</Button>}
                    </Stack>

                    <div>

                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            variant="contained"
                        >
                            block/non-block
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => { dispatch(getUserAction({ is_block: true })); handleClose() }} >blocked</MenuItem>
                            <MenuItem onClick={() => { dispatch(getUserAction({ is_block: false })); handleClose() }}>not blocked</MenuItem>

                        </Menu>
                    </div>

                </Stack>

                <Stack sx={{ mt: 1 }} direction="row" justifyContent="space-between" alignItems="flex-start">
                    <Stack direction={"row"} justifyContent={"space-between"}>
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400, backgroundColor: "transparent" }}
                        >

                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="search By Emails,App Name,Publiser Name"
                                inputProps={{ 'aria-label': 'search By Emails,App Name,Publiser Name' }}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon onClick={searchHandler} />
                            </IconButton>

                        </Paper>
                    </Stack>

                </Stack>

                < Card sx={{ my: 2 }}>
                    <Paper sx={{
                        bgcolor: '#fff',
                        '& .super-app-theme--header': {
                            bgcolor: '#071D45',
                            color: '#fff',
                        },
                    }} elevation={0}>
                            <DataGrid
                                className={classes.root}
                                getRowHeight={() => 'auto'}
                                sx={{ border: 0 }}
                                rows={data}
                                autoHeight
                                columns={adminColumns}
                                getRowId={(row) => row._id}
                                disableSelectionOnClick
                                experimentalFeatures={{ newEditingApi: true }}
                                pagination
                                // pageSize={pageSize}
                                // onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                rowsPerPageOptions={[25,50,100]}
                                disableColumnSelector
                                loading={loading ? <LoadingUI /> : false}
                                components={{
                                    NoRowsOverlay: () => <NoDataFound />,
                                    Toolbar: GridToolbar,
                                }}
                                rowCount={total}
                                paginationModel={setPageState}
                                page={pageState.page}
                                pageSize={pageState.pageSize}
                                paginationMode="server"
                                onPageChange={(newPage) => {
                                    setPageState(old => ({ ...old, page: newPage }))
                                }}
                                onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}

                            />
                    </Paper>
                </Card >



                {/* modals */}
                {/* < AddPermission admin_id={admin_id} state={editPremission} setState={setEditPremission} /> */}
                {/* <CreateAdmin state={createAdmin} setState={setCreateAdmin} /> */}
                <BlockUnBlockAdmin is_block={is_block} user_id={user_id} state={blockAdmin} setState={setBlockAdmin} />


            </Body >

            <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />
        </>
    )
}
