import FilterAltIcon from '@mui/icons-material/FilterAlt'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Breadcrumbs, Card, Grid, IconButton, Link, Stack, TextField, Typography } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import React, { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch } from 'react-redux'
import Appbar from '../../components/Appbar'
// import DrawerUI from '../../components/Drawer'
import { dateConvert } from '../../helper/dateConverter'
import Body from '../../layout/Body'
import { noOfDeletedMissionAction } from '../../redux/toolkit/menu/noOfDeletedMission'
import { noOfLiveMissionAction } from '../../redux/toolkit/menu/noOfLiveMission'
import { default as DeletedMission } from './DeletedMission'
import LiveMission from './LiveMission'

const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/menu'>Menu</Link>
        </Breadcrumbs>
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Menu</Typography>
    </Stack>
)

export default function Menu() {
    // state
    const dispatch = useDispatch()
    const [date, setDate] = useState({
        start: null,
        end: null,
    })

    // fn
    const handleFilterData = () => {
        dispatch(noOfDeletedMissionAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
        dispatch(noOfLiveMissionAction({ s_date: dateConvert(date?.start), e_date: dateConvert(date?.end) }))
    }


    return (
        <>
            <Helmet>
                <title>Quick Mission | Menu</title>
            </Helmet>


            <Appbar breadcrumb={breadcrumb} />
            {/* <DrawerUI /> */}
            <Body>



                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Card sx={{ p: 2, my: 2 }}>
                        <Typography color='#071D45'>Filter by Date</Typography>
                        <Stack direction={'row'} spacing={2} sx={{ mt: 1 }} alignItems='center'>
                            <DatePicker
                                label="Start Date"
                                value={date?.start}
                                onChange={(value) => setDate({ ...date, start: value })}
                                renderInput={(params) => <TextField {...params} />}
                                maxDate={date?.end}

                            />
                            <DatePicker
                                label="End Date"
                                value={date?.end}
                                onChange={(value) => setDate({ ...date, end: value })}
                                renderInput={(params) => <TextField {...params} />}
                                minDate={date?.start}
                            />
                            <Box>
                                <IconButton disabled={!date?.start || !date?.end} onClick={handleFilterData} color='primary'><FilterAltIcon /></IconButton>
                            </Box>
                        </Stack>
                    </Card>
                </LocalizationProvider>



                <Grid container spacing={2}>
                    <LiveMission />
                    <DeletedMission />
                </Grid>


            </Body>
        </>
    )
}
