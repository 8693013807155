
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { loading: false, data: [], error: "", message: "" }


export const deleteSupportDataAction = createAsyncThunk(
    'delete-support-data',
    async (data = null, { rejectWithValue }) => {
        console.log("data-->",data)
        try {
            const res = await axios.post(`/admins/api/delete-restore-support-knowladge`,data)
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const deleteSupportDataSlice = createSlice({
    name: 'delete-support-data',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(deleteSupportDataAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(deleteSupportDataAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.message = action?.payload?.message
                state.status=action?.payload?.status
            })
            builder.addCase(deleteSupportDataAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.message = action?.payload?.message
                state.status=action?.payload?.status

            })
        },

})



export const deleteSupportDataReducer = deleteSupportDataSlice.reducer    






// router.get("/support-knowladges/:page/:limit/:is_deleted", new SupportKnowladge().supportKnowladgeList)
// router.get("/support-knowladge/:id", new SupportKnowladge().supportKnowladgeDetail)
// router.post("/support-knowladge", new SupportKnowladge().createSupportKnowladge)
// router.put("/support-knowladge", new SupportKnowladge().updateSupportKnowladge)
// router.delete("/support-knowladge", new SupportKnowladge().deleteAndResoteSupportKnowladge)