import { yupResolver } from '@hookform/resolvers/yup';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Autocomplete, Box, Button, Card, Chip, CircularProgress, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Modal, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../comman/SnackBarUI';
import { createQuestionAnswerAction } from '../../redux/toolkit/question-answer/createQuestionAnswer';
import { getAllQuestionAnswerAction } from '../../redux/toolkit/question-answer/getAllQuestionAnswer';
import { getSubCategoryAction } from '../../redux/toolkit/sub-category/getSubCategory';
import { uplodImageAction } from '../../redux/toolkit/image/uplodImage';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import { getValue } from '@testing-library/user-event/dist/utils';
import { createSupportDataAction } from '../../redux/toolkit/support/createData';
import {supportDataAction} from '../../redux/toolkit/support/list'


let cat_id = ''
let sub_cat_id = ''


// schema
const qns_ans_schema = yup.object({
    // cat_name: yup.string().required('Category is required.'),
}).required();


export default function CreateSupport({ state, setState }) {

    // const input = {
    //     option: "",
    // }
    // state
    const { register, setValue, setError, watch, handleSubmit, formState: { errors }, reset } = useForm({ resolver: yupResolver(qns_ans_schema) });
    const answer_type = watch("answer_type");
    const type = watch('type');

    const dispatch = useDispatch()
    // const createQns = useSelector(state => state.createQuestionAnswer)
    // let { status, message, loading } = createQns
    const createSupport = useSelector(state => state.createSupport)
    const { data,loading,message,status } = createSupport

    const getSubCat = useSelector(state => state.getSubCategory)
    const { data: getSubCatData } = getSubCat

    const [snack, setSnack] = useState(false)
    const [ansArr, setAnsArr] = useState([])
    const [answerOption, setAnswerOption] = useState()
    const [correct_answer, setCorrect_answer] = useState([])
    const [correctAnswerSelect, setCorrectAnswerSelect] = useState({})
    const [file, setFile] = useState('')
    const [fileLoader, setFileLoader] = useState(false)
    const [answerData, setAnswerData] = useState(false)
    const [timeDuration, setTimeDuration] = useState("")
    // const [questionType,setQuestionType]=useState('')


    // fn
    const changeOptInput = (e) => {
        console.log("[e.target.id]: e.target.value-->", [e.target.id], e.target.value);
        setAnswerOption(e.target.value)
    }


    const handleDelAnsOpt = (i) => {
        const tempArr=ansArr
        tempArr.splice(i,1)
        // setAnsArr((current) => current.filter((index) => { return index !== i; }))
        setAnsArr([...tempArr])
        console.log("ansArr-->",ansArr)
        setAnswerOption('')
        
      }

    const handleQns = async (data) => {
        console.log('data-->', data);
        
        console.log("ansArray---->", ansArr);
        const createQnsDataAction = await dispatch(createSupportDataAction({ answers: ansArr,...data }))
        if (createQnsDataAction?.payload?.status === 201) {
             dispatch(supportDataAction({ is_deleted: false,page:0,limit:100 }))
        }
        setAnsArr([])
        setSnack(true)
        setState(false)
        reset()
    }

    const addAnswerArray = (e) => {
        console.log(e.target.value)
        ansArr.push(answerOption)
        console.log("ansArr-->", ansArr)
        setAnswerOption("")

    }



    const handleClose = function () {
        setAnsArr([])
        setState(false)
    }

    useEffect(() => {
        console.log("useEffect-->", ansArr);
    }, [ansArr])

    return (
        <>
            <Modal sx={{ overflow: 'scroll' }} open={state} onClose={handleClose}>
                <Box className='modal_box'>
                    <Card sx={{ width: 500, p: 5 }}>
                        <Typography variant='h5' sx={{ mb: 5 }} align='center'>Create Support Knowledge</Typography>
                        <form onSubmit={handleSubmit(handleQns)}>
                            <Stack spacing={3}>

                                <TextField label="Title" variant="outlined" placeholder='Title' {...register('title')} error={errors?.title?.message} helperText={errors?.title?.message} />
                                <Grid container columnSpacing={1}>
                                    <Grid item xs={8}>
                                        <TextField id="option" value={answerOption} onChange={(e) => { changeOptInput(e) }} fullWidth label="Answer" variant="outlined" placeholder='Answer' />
                                    </Grid>
                                    <Grid item xs={4}>
                                        {answerOption && <IconButton color='primary'><AddCircleIcon fontSize='large' onClick={addAnswerArray} /></IconButton>}
                                    </Grid>
                                </Grid>

                                <Stack spacing={1}>
                      
                      <TableContainer component={Paper}>
                              <Table  aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <TableCell style={{maxWidth:"10px"}} align="left">sno</TableCell>
                                    <TableCell align="right">Answers</TableCell>
                                    <TableCell align="right">delete</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>

                      {
                        ansArr.length>0?ansArr.map((item, index) => {
                            {console.log("item-->",item)}
                          return (
                            
                                  
                                    <TableRow
                                      key={index}
                                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                            
                                      <TableCell align="right">{index+1}</TableCell>
                                      <TableCell align="right" style={{maxWidth:"200px"}}>{item}</TableCell>
                                      <TableCell align="right"><Button onClick={() => handleDelAnsOpt(index)}>delete</Button></TableCell>
                                    </TableRow>
                                  )
                                }):null
                              }
                                </TableBody>
                              </Table>
                            </TableContainer>
                          
                    </Stack>


                                <Stack direction='row' spacing={2}>
                                    <Button variant='contained' type='submit' disabled={loading}>{loading ? <CircularProgress /> : 'Create'}</Button>
                                    <Button variant='contained' color='error' onClick={handleClose}>Close</Button>
                                </Stack>
                            </Stack>
                        </form>
                    </Card>
                </Box>
            </Modal>

            {console.log("status-->",status)}

            {snack && <SnackBarUI state={snack} setState={setSnack} status={status} message={message} />}
        </>
    )
}



CreateSupport.propTypes = {
    state: PropTypes.any,
    setState: PropTypes.any
};
