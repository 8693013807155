import { Box, Card, CircularProgress, IconButton, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CachedIcon from '@mui/icons-material/Cached';
import { qualifiedAction } from '../../redux/toolkit/dashbaord/qualified';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


export default function QualifiedAttempts() {
    // state
    const dispatch = useDispatch()
    const totalQualifiedSelector = useSelector(state => state.totalQualified)
    const { data, loading } = totalQualifiedSelector

    // fn
    const handleRefeash = () => {
        dispatch(qualifiedAction())
    }

    // use effect
    useEffect(() => {
        dispatch(qualifiedAction())
    }, [dispatch])

    return (
        <Card sx={{ p: 2, color:'#0C6A96' }} >
            <Stack direction='row' justifyContent='space-between' >
                <CheckCircleOutlineIcon/>
                <Typography sx={{ color: '#5E6C8A',fontFamily: 'Montserrat,sans-serif',fontStyle:'normal' }} variant='subtitle2'>Qualified Attempts</Typography>
                <IconButton sx={{ color: '#5E6C8A' }} onClick={handleRefeash}><CachedIcon /></IconButton>
            </Stack>

            {
                loading
                    ? <CircularProgress />
                    : <Box>
                        <Typography variant='h3' color='primary' sx={{ fontWeight: 600, color: '#071D45' }}>{data?.no_of_mission_qualified?data?.no_of_mission_qualified:"---"}</Typography>
                    </Box>
            }
        </Card>
    )
}
