import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "../../../config/authAxios"



const initialState = { status: null, loading: false, data: [], error: "", message: "" }


export const deleteMultipleQuestionAnswerAction = createAsyncThunk(
    'delete-multiple-question-answer',
    async (data, { rejectWithValue }) => {
        console.log("data==>",data)
        try {
            // const res = await axios.patch(`/admins/api/delete-restore-multiple-question-answer?questionIds=${data.questionIds}&isDelete=${data.isDelete}`)
            const res = await axios.patch('/admins/api/delete-restore-multiple-question-answer', {
                
                  questionIds: data.questionIds,
                  isDelete: data.isDelete
                
              });
            return res?.data
        } catch (error) {
            return rejectWithValue(error.response.data)
        }
    }
)


export const deleteMultipleQuestionAnswerSlice = createSlice({
    name: 'delete-multiple-question-answer',
    initialState,
    reducers: {},
    extraReducers:
        (builder) => {
            builder.addCase(deleteMultipleQuestionAnswerAction.pending, (state) => {
                state.loading = true
            })
            builder.addCase(deleteMultipleQuestionAnswerAction.fulfilled, (state, action) => {
                state.loading = false
                state.data = action?.payload?.data
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
            builder.addCase(deleteMultipleQuestionAnswerAction.rejected, (state, action) => {
                state.loading = false
                state.error = action?.payload?.error
                state.status = action?.payload?.status
                state.message = action?.payload?.message
            })
        },

})



export const deleteMultipleQuestionAnswerReducer = deleteMultipleQuestionAnswerSlice.reducer