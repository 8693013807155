import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SnackBarUI from '../../comman/SnackBarUI'
import { getMissionAction } from '../../redux/toolkit/mission/getMission'
import { missionStatusAction } from '../../redux/toolkit/mission/missionStatus'
import PropTypes from 'prop-types';

export default function UnliveMission({ mission_id, state, setState }) {


    // state
    const dispatch = useDispatch()
    const missionState = useSelector(state => state.missionStatus)
    const { status: missionStatus, message: missionMsg } = missionState

    const [snack, setSnack] = useState(false)

    // fn
    const handleClose = () => {
        setState(false)
    }

    const handleUnlive = async () => {
        const unliveMissionData = await dispatch(missionStatusAction({ mission_id: mission_id, status: 'paused' }))
        if (unliveMissionData?.payload?.status == 200) {
            await dispatch(getMissionAction({ is_deleted: false }))
            setSnack(true)
        }
        setSnack(true)
        setState(false)
    }

    return (
        <>
            <Dialog open={state} onClose={handleClose}>
                <DialogTitle>Mission Deactivation request !</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to Deactivate the selected mission ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUnlive} autoFocus> Agree</Button>
                    <Button onClick={handleClose}>Disagree</Button>
                </DialogActions>
            </Dialog>


            <SnackBarUI state={snack} setState={setSnack} status={missionStatus} message={missionMsg} />
        </>

    )
}


UnliveMission.propTypes = {
    mission_id: PropTypes.string,
    state: PropTypes.any,
    setState: PropTypes.any
};