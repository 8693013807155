import React, { useEffect} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';

import { bankDetailAction } from '../../redux/toolkit/bank/Detail';
import { useDispatch, useSelector } from 'react-redux'



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DetailPopup({state,setState,detailId}) {
    const dispatch=useDispatch()
    const bankDetail = useSelector(state => state.bankDetail)
    console.log("bankDetail-->",bankDetail)
    const { data,loading } = bankDetail

    console.log('data-->',data)
    console.log('loading-->',loading)
//   const [open, setOpen] = React.useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

useEffect(() => {
  dispatch(bankDetailAction(detailId))

  
}, [dispatch])


console.log("detailId",detailId)

  const handleClose = () => {
    setState(false);
  };

  return (
   
      
     
         <Dialog
        // fullScreen
        maxWidth="md" fullWidth={true}
        open={state}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Bank Detail
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>
    {!loading?(<div>
        <Stack direction={"row"}>
          <ListItem>
            <ListItemText primary="Account No" secondary={data?.account_no?data?.account_no:"---"} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Account Type"
              secondary={data?.account_type?data?.account_type:"---"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Holder Name"
              secondary={data?.holder_name?data?.holder_name:"---"}
            />
          </ListItem>
        </Stack>

        <Stack direction={"row"}>
          <ListItem>
            <ListItemText 
            primary="IFSC code" 
            secondary={data?.ifcs_code?data?.ifcs_code:"---"}
             />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Branch"
              secondary={data?.branch?data?.branch:"---"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="City"
              secondary={data?.city?data?.city:"---"}
            />
          </ListItem>
        </Stack>

    
        <Stack direction={"row"}>
          <ListItem>
            <ListItemText primary="Country" 
            secondary={data?.country?data?.country:"---"} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Region"
              secondary={data?.region?data?.region:"---"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Pulisher id"
              secondary={data?.publisher_id?data?.publisher_id:"---"}
            />
          </ListItem>
        </Stack>


        {/* <Stack direction={"row"}>
          <ListItem>
            <ListItemText primary="Phone ringtone"
             secondary={data?.account_no?data?.account_no:"---"} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Default notification ringtone"
              secondary={data?.account_no?data?.account_no:"---"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Default notification ringtone"
              secondary={data?.account_no?data?.account_no:"---"}
            />
          </ListItem>
        </Stack> */}


        {/* <Stack direction={"row"}>
          <ListItem>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Default notification ringtone"
              secondary={data?.account_no?data?.account_no:"---"}
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Default notification ringtone"
              secondary={data?.account_no?data?.account_no:"---"}
            />
          </ListItem>
        </Stack> */}
    </div>):"loading..."}
      </Dialog>
    
  );
}