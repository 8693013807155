import moment from "moment"

export const dateFormatStartFun = (startOfToday) => {
    const startDate=new Date(startOfToday.$d)
    startDate.setHours(startDate.getHours() + 5);
    startDate.setMinutes(startDate.getMinutes() + 30);
    // const startDate = moment(startOfToday.$d).format();
    console.log("startDate===>",startDate.toISOString())
    return startDate.toISOString()
}

export const dateFormatEndFun = (endOfToday) => {
    // console.log("endOfToday",`${moment(endOfToday).format(moment.HTML5_FMT.DATE)}T23:59:59.999Z`)
    // return `${endOfToday.$y}-${endOfToday.$M+1}-${endOfToday.$D}:T${endOfToday.$H}:${endOfToday.$m}.${endOfToday.$ms}T`

    const endDate=new Date(endOfToday.$d).toISOString()
    
    return endDate

    // return `${moment(endOfToday).format(moment.HTML5_FMT.DATE)}T23:59:59.999Z`
}



export const dateConvert = (date) => {

    let Year = date?.$y,
        Month = (date?.$M + 1),
        Day = date?.$D,
        Hour = date?.$H,
        Minutes = date?.$m,
        Seconds = date?.$s,
        finalDate = null

    if (Month < 10) Month = `0${Month}`
    if (Day < 10) Day = `0${Day}`
    if (Hour < 10) Hour = `0${Hour}`
    if (Minutes < 10) Minutes = `0${Minutes}`
    if (Seconds < 10) Seconds = `0${Seconds}`

    finalDate = `${Year}-${Month}-${Day}T${Hour}:${Minutes}:${Seconds}.000Z`


    return finalDate

}