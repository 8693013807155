import axios from "axios";


const authAxios = axios.create({
    // baseURL: "http://localhost:4000", 
     baseURL: "https://prod.quizwall.io", //production
    headers: {
        
        "Authorization": `Bearer ${JSON.parse(localStorage.getItem("ss_token"))}`,
    }
});


authAxios.interceptors.response.use((config) => {

    return config;

}, (error) => {

    let { response: { data: { status } } } = error

    // unauthrised user
    if (status == 401) {
        localStorage.clear()
    }
    return Promise.reject(error);
});





export default authAxios;