import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Box, Breadcrumbs, Button, Card, FormHelperText, CircularProgress, FormControl, FormControlLabel, FormLabel, Grid, Link, Paper, Radio, RadioGroup, Slider, TextField, Typography, Avatar, Badge } from '@mui/material';
import { Stack } from '@mui/system';
import Switch from '@mui/material/Switch';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from "yup";
import SnackBarUI from '../../../comman/SnackBarUI';
import { getSubCategoryAction } from '../../../redux/toolkit/sub-category/getSubCategory';
import { createMissionAction } from '../../../redux/toolkit/mission/createMission';
import { getMissionAction } from '../../../redux/toolkit/mission/getMission';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import LoadingUI from '../../../comman/LoadingUI';
import NoDataFound from '../../../comman/NoDataFound';
import Appbar from '../../../components/Appbar';
// import DrawerUI from '../../../components/Drawer';
import Body from '../../../layout/Body';
import { getCategoryAction } from '../../../redux/toolkit/category/getCategory';
import { getMissionQnsAction } from '../../../redux/toolkit/mission/getMissionQns';
import { useStyles } from '../../../theme/theme';
import { uplodImageAction } from '../../../redux/toolkit/image/uplodImage';
import JoditEditor from 'jodit-react'




let cat_id = ''
let sub_cat_id = ''


const breadcrumb = (
    <Stack>
        <Breadcrumbs separator={<NavigateNextIcon sx={{ color: '#5E6C8A' }} fontSize="small" />} aria-label="breadcrumb">
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/'>Home</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/mission'>Mission</Link>
            <Link underline="hover" sx={{ color: "#5E6C8A" }} color='inherit' href='/mission/create-mission'>Create Mission</Link>
        </Breadcrumbs >
        <Typography sx={{ color: "#5E6C8A" }} variant='h5'>Mission</Typography>
    </Stack >
)

// schema
const qns_ans_schema = yup.object().shape({
    cat_name: yup.string().required('Category is required.'),
    sub_cat_name: yup.string().required('Sub category is required.'),
    // mission_type: yup.string().nullable().required('mission type is required'),
    // noofquestion: yup.number().positive().nullable().required('No of Question is Required'),
    // time: yup.number().nullable().positive().required('Time is Required'),
    icon: yup.string().required('Icon is Required'),
    // rewards: yup.number().positive().required('Time is Required'),
    title: yup.string().required('title is Required'),
    // description: yup.string().required('description is Required'),
}).required();



export default function CreateMission() {
    // state
    const { register, setError, watch, handleSubmit, formState: { errors }, reset, getValues, setValue } = useForm({
        resolver: yupResolver(qns_ans_schema), defaultValues: {}
    });

    const mission_type = watch('mission_type')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const createMission = useSelector(state => state.createMission)
    const { status: createMissionStatus, message: createMissionMsg, loading: createMissionLoading } = createMission
    const getCat = useSelector(state => state.getCategory)
    const getQns = useSelector(state => state.getMissionQns)
    const { data: qnsData, loading: qnsLoading } = getQns
    const { data: getCatData } = getCat
    const getSubCat = useSelector(state => state.getSubCategory)
    const { data: getSubCatData } = getSubCat
    const [selectedValue, setSelectedValue] = useState("");
    const [snack, setSnack] = useState(false)
    const classes = useStyles();
    const [linkQnsList, setLinkQnsList] = useState([])
    const [pageSize, setPageSize] = useState(25);
    const [file, setFile] = useState('')
    const [fileLoader, setFileLoader] = useState(false)
    const [desc, setDesc] = useState()
    const [showTop, setShowTop] = useState(false)



    const onChangeEditorHandler = (e) => {
        setDesc(e)
        setValue('description', e, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true
        })

    }
    const handleSwitchChange = () => {
        setShowTop((prev) => !prev)

    }


    const linkedQnsColumn = [
        {
            field: 's_no',
            headerName: 'S. No.',
            minWidth: 10,
            headerClassName: 'super-app-theme--header',
            renderCell: (index) => index?.api?.getRowIndex(index.row._id) + 1
        },
        {
            field: 'question',
            headerName: 'Questions',
            flex: 1,
            minWidth: 100,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'type',
            headerName: 'Question Type',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'answer_type',
            headerName: 'Answer Type',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
        },
        {
            field: 'question_reletive_cat',
            headerName: 'Relitive Category',
            flex: 1,
            minWidth: 50,
            headerClassName: 'super-app-theme--header',
        },
    ]


    // fn

    const handleFileUpload = async (e) => {
        setFileLoader(true)
        const formData = new FormData();

        formData.set("file", e.target.files[0]);

        let uploadedImg = await dispatch(uplodImageAction(formData))
        const imgStr = uploadedImg?.payload?.data?.url;
        setFile(uploadedImg?.payload?.data?.url)
        setValue('file', imgStr)
        setFileLoader(false)


    }

    const handleIconUpload = async (e) => {
        // setFileLoader(true)
        const formData = new FormData();

        formData.set("file", e.target.files[0]);

        let uploadedImg = await dispatch(uplodImageAction(formData))
        const imgStr = uploadedImg?.payload?.data?.url;
        // setFile(uploadedImg?.payload?.data?.url)
        setValue('icon', imgStr, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,

        })
        // setFileLoader(false)


    }
    const handleQns = async (data) => {

        if (!linkQnsList?.length) {
            return setError('linkQnsError', { type: 'custom', message: 'Atleast single question need to link to create new mission.' });
        }
        const arr = [data.x_time, data.y_question]

        const createMissionData = await dispatch(createMissionAction({
            cat_id: cat_id?._id,
            sub_cat_id: sub_cat_id?._id,
            link_qns: linkQnsList,
            correct_criteria: data.attempt_criteria,
            time_criteria: data.time_criteria,
            reward: data.rewards,
            no_of_question: linkQnsList.length,
            minutes: data.time,
            icon: data.icon,
            title: data.title,
            description: desc,
            criteria_type: selectedValue,
            show_top: showTop
        }))
        setSnack(true)
        if (createMissionData?.payload?.status == 201) {
            await dispatch(getMissionAction({ is_deleted: false }))

            reset()
            setTimeout(() => {
                navigate("/mission")

            }, 1500)
        }

        setSnack(true)
        // setState(false)

    }


    const handleClose = function () {
        // setState(false)
        reset()
        navigate('/mission')
    }




    useEffect(() => {
        dispatch(getCategoryAction({ is_deleted: false }))
    }, [])


    // useEffect(() => {
    //     let defaultValues = {};
    //     defaultValues = {
    //         reward: {
    //             reward_amt: '0.1',
    //             secondary_reward: '0.1'
    //         }
    //     }

    //     reset(defaultValues)

    // }, [])



    return (
        <>
            <Helmet><title>Quick mission | Mission create mission</title></Helmet>
            <Appbar breadcrumb={breadcrumb} />
            {/* <DrawerUI /> */}
            <Body>
                {/* <Card sx={{ width: 500, p: 5 }}> */}
                <Card sx={{ p: 5 }}>
                    <Typography variant='h5' sx={{ mb: 5 }}>Add Mission</Typography>
                    <form onSubmit={handleSubmit(handleQns)}>
                        <Stack direction="row" spacing={10}>


                            <Autocomplete disablePortal id={getCatData?._id} options={getCatData} getOptionLabel={getCatData => getCatData?.cat_name}
                                onChange={(event, value) => {
                                    cat_id = value
                                    dispatch(getSubCategoryAction({ cat_id: cat_id?._id, is_deleted: false }))
                                }}
                                renderInput={(params) =>
                                    <TextField  {...params} label="Category Name" {...register('cat_name')} error={errors?.cat_name?.message} helperText={errors?.cat_name?.message} style={{ width: "450px" }} />}
                            />
                            <Autocomplete disablePortal id={getSubCatData?._id} options={getSubCatData} getOptionLabel={getSubCatData => getSubCatData?.sub_cat_name}
                                onChange={(event, value) => {
                                    sub_cat_id = value
                                    dispatch(getMissionQnsAction({ sub_cat_id: sub_cat_id?._id }))
                                }}
                                renderInput={(params) =>
                                    <TextField  {...params} label="Sub Category Name" error={errors?.sub_cat_name?.message} helperText={errors?.sub_cat_name?.message}  {...register('sub_cat_name')} style={{ width: "450px" }} />}
                            />
                        </Stack>
                        <Stack spacing={3}>
                            {/* <Stack direction="row" spacing={15}>
                                <FormControl sx={{ margin: "12px 0px 0px 20px" }}>

                                    <FormLabel>Mission Type</FormLabel>
                                    <RadioGroup>
                                        <Stack direction='row'>
                                            <FormControlLabel value="text" {...register('mission_type')} control={<Radio />} label="Text" />
                                            <FormControlLabel value="image" {...register('mission_type')} control={<Radio />} label="Image" />
                                            <FormControlLabel value="audio"  {...register('mission_type')} control={<Radio />} label="Audio" />
                                            <FormControlLabel value="video"  {...register('mission_type')} control={<Radio />} label="Video" />
                                        </Stack>
                                    </RadioGroup>
                                    <FormHelperText error={errors?.mission_type?.message}>{errors?.mission_type?.message}</FormHelperText>
                                </FormControl>
                            </Stack> */}
                            {/* {
                                (mission_type === 'image' || mission_type === 'audio' || mission_type === 'video') &&
                                <>
                                    <Stack direction="row" spacing={10} justifyContent="center" alignItems="center">
                                        <TextField label="Title" variant="outlined" placeholder='Title' {...register('mission_title')} style={{ width: "450px" }} />
                                        <TextField label="File Upload" InputLabelProps={{ shrink: true }} type='file' accept="*" variant="outlined" id='file' placeholder='file' onChange={handleFileUpload} error={errors?.file?.message} style={{ width: "450px" }} helperText={errors?.file?.message} />
                                    </Stack>
                                </>
                            }
                            {fileLoader ? <CircularProgress /> : <>{
                                (mission_type === 'image' || mission_type === 'audio' || mission_type === 'video') && file && <>
                                    <Stack direction="row" spacing={10} justifyContent="center" alignItems="center">
                                        <Button onClick={() => { setFile('') }}>reupload</Button>
                                        <Button onClick={() => { window.open(file); }}>watch</Button>
                                    </Stack>
                                </>
                            }
                            </>} */}



                            {/* <Stack direction="row" spacing={10} justifyContent="center" alignItems="center" sx={{ margin: "12px 0px 0px 0px" }}>

                                <TextField label="Number of Question" {...register('noofquestion')} error={errors?.noofquestion?.message} helperText={errors?.noofquestion?.message} style={{ width: "450px" }} />


                                <TextField label="Time in Minute" error={errors?.time?.message} helperText={errors?.time?.message}  {...register('time')} style={{ width: "450px" }} />

                            </Stack>  */}

                            <Stack direction="row" spacing={10}>
                                <FormControl sx={{ margin: "12px 0px 0px 20px" }}>

                                    <FormLabel>Criteria Type</FormLabel>
                                    <RadioGroup>
                                        <Stack direction='row' spacing={10}>
                                            <FormControlLabel value="1" onChange={() => { setSelectedValue('1') }} control={<Radio />} label="Attempt X Correct Questions" />
                                            <FormControlLabel value="2" onChange={() => { setSelectedValue('2') }} control={<Radio />} label="Attempt X Correct Questions in Y time" />
                                            {/* <FormControlLabel value="3"  onChange={()=>{setSelectedValue('3')}} control={<Radio />} label="Complete quiz in X time with Y questions correct" /> */}
                                        </Stack>
                                    </RadioGroup>
                                    <FormHelperText error={errors?.mission_type?.message}>{errors?.mission_type?.message}</FormHelperText>
                                </FormControl>
                            </Stack>

                            <Stack direction="row" spacing={10} >

                                <TextField label="Attempt X Questions" error={errors?.attempt_criteria?.message} helperText={errors?.attempt_criteria?.message}  {...register('attempt_criteria')} style={{ width: "450px" }} />


                                {
                                    selectedValue === '2' && (<>
                                        <TextField label="complete Quiz in X Time" error={errors?.time_criteria?.message} helperText={errors?.time_criteria?.message}  {...register('time_criteria')} style={{ width: "450px" }} />

                                    </>)}
                                {/* {
                                selectedValue==='3' && (<>
                                <TextField label="Attempt X Questions"    {...register('attempt_criteria')} style={{ width: "150px",margin: "0px 0px 0px 20px" }} />
                                <TextField label="complete Quiz in X Time" {...register('time_criteria')} style={{ width: "150px",margin: "0px 0px 0px 20px" }} />
                                </>)} */}
                            </Stack>

                            <Stack direction="row" spacing={12.5}  >

                                <TextField InputLabelProps={{ shrink: true }} label="Icon Upload" type="file" id='icon' onChange={handleIconUpload} error={errors?.icon?.message} helperText={errors?.icon?.message} style={{ width: "270px" }} />
                                <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} >
                                    <Avatar src={getValues('icon')} sx={{ width: 60, height: 60 }} />
                                </Badge>

                                <TextField label="Reward in rupees" error={errors?.rewards?.message} helperText={errors?.rewards?.message}  {...register('rewards')} style={{ width: "450px" }} />

                            </Stack>

                            <Stack direction="row" spacing={10} >

                                <TextField minRows={7} multiline={true} label="Title" {...register('title')} error={errors?.title?.message} helperText={errors?.title?.message} style={{ width: "450px" }} />


                                {/* <TextField minRows={4} multiline={true} label="Description" error={errors?.description?.message} helperText={errors?.description?.message}  {...register('description')} style={{ width: "450px" }} /> */}
                                <JoditEditor
                                    tabIndex={2}
                                    onBlur={onChangeEditorHandler}
                                    onChange={onChangeEditorHandler}
                                />

                            </Stack>

                            <Stack direction="row" spacing={10} >


                                <FormControlLabel control={<Switch onChange={handleSwitchChange} checked={showTop} />} label="Show on Top" />


                            </Stack>



                            <Paper sx={{
                                bgcolor: '#fff',
                                '& .super-app-theme--header': {
                                    bgcolor: '#071D45',
                                    color: '#fff',
                                },
                            }} elevation={0}>
                                <Typography sx={{ mt: 2 }} variant='h6'>Link Questions</Typography>
                                <DataGrid
                                    className={classes.root}
                                    checkboxSelection
                                    getRowHeight={() => 'auto'}
                                    sx={{ border: 0 }}
                                    autoHeight
                                    rows={qnsData}
                                    columns={linkedQnsColumn}
                                    getRowId={(row) => row._id}
                                    disableSelectionOnClick
                                    experimentalFeatures={{ newEditingApi: true }}
                                    pagination
                                    pageSize={pageSize}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                    rowsPerPageOptions={[25, 50, 100]}
                                    disableColumnSelector
                                    loading={qnsLoading ? <LoadingUI /> : false}
                                    onSelectionModelChange={linkedQns => setLinkQnsList(linkedQns)}
                                    components={{
                                        NoRowsOverlay: () => <NoDataFound />,
                                        Toolbar: GridToolbar,
                                    }}

                                />
                            </Paper>
                            <Stack direction='row' spacing={2}>
                                <Button variant='contained' type='submit' disabled={createMissionLoading}>{createMissionLoading ? <CircularProgress /> : 'Create Mission'}</Button>
                                <Button variant='contained' color='error' onClick={handleClose}>Back</Button>
                            </Stack>
                        </Stack>
                    </form>
                </Card>
            </Body>

            <SnackBarUI state={snack} setState={setSnack} status={createMissionStatus} message={createMissionMsg} />

        </>
    )
}

CreateMission.propTypes = {
    state: PropTypes.any,
};